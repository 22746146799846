import React from "react"
import PropTypes from "prop-types"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import clsx from "clsx"

import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  confirmButton: {
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  cancelButton: {
    marginLeft: "16px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  dialogTitle: {
    textAlign: "center",
  },
})

const BaseDialog = ({
  onClose,
  open,
  contentComponent,
  dialogTitle,
  confirmButtonTitle,
  cancelButtonTitle,
  confirmButtonProps: parentConfirmProps,
  cancelButtonProps: parentCancelProps,
  hideConfirmButton,
  hideCancelButton,
  disableBackdropClick,
  children,
  maxWidth,
  scroll = "paper",
}) => {
  const handleClose = (confirmed) => {
    return onClose({ confirmed })
  }

  const classes = useStyles()

  const confirmProps = {
    disableElevation: true,
    variant: "contained",
    color: "primary",
    ...parentConfirmProps,
    className: clsx(
      (parentConfirmProps ?? {}).className,
      classes.confirmButton
    ),
    onClick: () => handleClose(true),
  }

  const cancelProps = {
    disableElevation: true,
    variant: "outlined",
    ...parentCancelProps,
    className: clsx((parentCancelProps ?? {}).className, classes.cancelButton),
    onClick: () => handleClose(false),
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown
      scroll={scroll}
      maxWidth={maxWidth}
    >
      <DialogTitle className={classes.dialogTitle}>{dialogTitle}</DialogTitle>
      <DialogContent>{contentComponent || children}</DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        {hideCancelButton ? null : (
          <Button {...cancelProps}>{cancelButtonTitle}</Button>
        )}
        {hideConfirmButton ? null : (
          <Button {...confirmProps}>{confirmButtonTitle}</Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

BaseDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  contentComponent: PropTypes.node,
  children: PropTypes.node,
  dialogTitle: PropTypes.node,
  confirmButtonTitle: PropTypes.node,
  cancelButtonTitle: PropTypes.node,
  confirmButtonProps: PropTypes.shape({}),
  cancelButtonProps: PropTypes.shape({}),
  hideConfirmButton: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  maxWidth: PropTypes.string,
}

BaseDialog.defaultProps = {
  onClose: () => {},
  open: false,
  contentComponent: null,
  children: null,
  dialogTitle: null,
  confirmButtonTitle: "OK",
  cancelButtonTitle: "Cancel",
  confirmButtonProps: {},
  cancelButtonProps: {},
  hideConfirmButton: false,
  hideCancelButton: false,
  disableBackdropClick: true,
  maxWidth: "sm",
}

export default BaseDialog
