import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Route } from "react-router-dom"
import BreadcrumbItem from "./BreadcrumbItem"

const RouteBreadcrumb = ({
  name: defaultName,
  component: Component,
  children,
  ...matchArgs
}) => {
  const [name, setName] = useState(defaultName || "")
  useEffect(() => {
    setName(defaultName || "")
  }, [defaultName])

  const wrapRender = (child) => (
    <BreadcrumbItem {...matchArgs} name={name}>
      {child}
    </BreadcrumbItem>
  )

  return (
    <Route {...matchArgs}>
      {(routeProps) => {
        const propsWithSetter = { ...routeProps, setRouteName: setName }
        if (typeof children === "function") {
          return wrapRender(children(propsWithSetter))
        }
        if (children) {
          return wrapRender(children)
        }
        if (Component) {
          return wrapRender(<Component {...propsWithSetter} />)
        }
        return wrapRender()
      }}
    </Route>
  )
}

RouteBreadcrumb.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType,
  name: PropTypes.string,
}

RouteBreadcrumb.defaultProps = {
  children: null,
  component: null,
  name: null,
}

export default RouteBreadcrumb
