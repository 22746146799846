import React, { useState } from "react"
import {
  bool as tBool,
  func as tFunc,
  instanceOf as tInstanceOf,
  string as tString,
} from "prop-types"

import { ReactComponent as IconReceive } from "assets/status-icons/icon-receive.svg"
import { ReactComponent as IconProceed } from "assets/status-icons/icon-proceed.svg"
import { ReactComponent as IconPost } from "assets/status-icons/icon-post.svg"
import { ReactComponent as IconCancel } from "assets/status-icons/icon-cancel.svg"
import { makeStyles } from "@material-ui/core"

import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ChangeStatusDialog from "./ChangeStatusDialog"

const useStyles = makeStyles({
  menuItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  buttonIcon: {
    marginLeft: "-0.5rem",
    marginRight: "0.3rem",
    color: "#001b95",
    fill: "currentColor",
  },
})

const ChangeStatusMenu = ({
  open,
  onClose,
  anchorEl,
  itemType,
  onConfirmStatusChange,
  showPost,
  showApprove,
  showExecute,
  showProceed,
  showReceived,
  showCancel,
  showClosed,
  renderDialog,
  showEnter,
  renderPrepend,
  showOngoing,
  enterStatusProps = {},
}) => {
  const classes = useStyles()
  const [showDialog, toggleShowDialog] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState("")

  const showAndSetStatus = (status) => {
    setSelectedStatus(status)
    toggleShowDialog(true)
  }

  const handleConfirm = ({ confirmed }) => {
    if (confirmed) {
      onConfirmStatusChange(selectedStatus)
    }
    toggleShowDialog(false)
    onClose()
  }

  return (
    <div>
      {typeof renderDialog === "function" ? (
        renderDialog({ open: showDialog, onClose: handleConfirm })
      ) : (
        <ChangeStatusDialog
          open={showDialog}
          onClose={handleConfirm}
          contentComponent={
            <p style={{ margin: 0 }}>
              Change this {itemType}&apos;s status to{" "}
              <strong>{selectedStatus}</strong>?
            </p>
          }
        />
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        {typeof renderPrepend === "function" &&
          renderPrepend({
            showDialog,
            onClose,
          })}
        {showEnter && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => showAndSetStatus("enter")}
            {...enterStatusProps}
          >
            Set status to ‘Enter’
          </MenuItem>
        )}
        {showPost && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => showAndSetStatus("post")}
          >
            <IconPost className={classes.buttonIcon} />
            Set status to ‘Post’
          </MenuItem>
        )}
        {showOngoing && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => showAndSetStatus("ongoing")}
          >
            <IconPost className={classes.buttonIcon} />
            Set status to ‘Ongoing’
          </MenuItem>
        )}
        {showApprove && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => showAndSetStatus("approve")}
          >
            <IconPost className={classes.buttonIcon} />
            Set status to ‘Approved’
          </MenuItem>
        )}
        {showExecute && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => showAndSetStatus("execute")}
          >
            <IconProceed className={classes.buttonIcon} />
            Set status to ‘Execute’
          </MenuItem>
        )}
        {showProceed && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => showAndSetStatus("proceed")}
          >
            <IconProceed className={classes.buttonIcon} />
            Set status to ‘Proceed’
          </MenuItem>
        )}
        {showReceived && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => showAndSetStatus("received")}
          >
            <IconReceive className={classes.buttonIcon} />
            Set status to ‘Received’
          </MenuItem>
        )}
        {showCancel && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => showAndSetStatus("cancel")}
          >
            <IconCancel className={classes.buttonIcon} />
            Set status to ‘Cancel’
          </MenuItem>
        )}
        {showClosed && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => showAndSetStatus("closed")}
          >
            <IconCancel className={classes.buttonIcon} />
            Set status to ‘Closed’
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

ChangeStatusMenu.propTypes = {
  open: tBool,
  onClose: tFunc,
  anchorEl: tInstanceOf(Element),
  itemType: tString,
  onConfirmStatusChange: tFunc,
  showPost: tBool,
  showExecute: tBool,
  showProceed: tBool,
  showReceived: tBool,
  showCancel: tBool,
  showClosed: tBool,
  showOngoing: tBool,
  renderDialog: tFunc,
  renderPrepend: tFunc,
}

ChangeStatusMenu.defaultProps = {
  open: true,
  anchorEl: null,
  itemType: "",
  showPost: false,
  showExecute: false,
  showProceed: false,
  showReceived: false,
  showCancel: false,
  showClosed: false,
  renderDialog: null,
  showOngoing: false,
  onClose: () => {},
  onConfirmStatusChange: () => {},
  renderPrepend: () => {},
}

export default ChangeStatusMenu
