import { useState, useCallback } from "react"

const allowedActions = [
  "changePage",
  "filterChange",
  "sort",
  "search",
  "changeRowsPerPage",
  "tableInitialized",
]

/**
 * Hooks to react for table state changes, especially these properties:
 * page, filter, sort, search, rows per page.
 *
 * Usage: This hooks returns tableState, getDefaultFetchParams, and onTableChange.
 * - Use tableState for useEffect to listen for tableState changes.
 * - Use onTableChange for onTableChange properties in Mui data tables options prop.
 * - Use getDefaultFetchParams to get default params needed for API get all items.
 *   The params are: start, limit, searchText, fromColumns, sortBy, and sortDirection.
 */

const useMuiDatatablesHelper = () => {
  const [tableState, setTableState] = useState()

  const getDefaultFetchParams = useCallback(() => {
    // Table state at init is undefined
    if (tableState === undefined) {
      return { start: 1, limit: 10 }
    }

    const { searchText, columns, page, rowsPerPage, sortOrder } = tableState
    const { name: sortBy, direction: sortDirection } = sortOrder

    const fromColumns = columns
      .filter((column) => `${column.display}` === "true" && column.searchable)
      .map((column) => column.name)

    return {
      start: 1 + page * rowsPerPage,
      limit: rowsPerPage,
      searchText,
      fromColumns,
      sortBy,
      sortDirection,
    }
  }, [tableState])

  const onTableChange = (action, newTableState) => {
    if (action === "propsUpdate") return
    if (allowedActions.includes(action)) {
      if (
        action === "search" &&
        (tableState?.searchText || "") === (newTableState?.searchText || "")
      ) {
        return
      }
      setTableState(newTableState)
    }
  }

  return {
    tableState,
    getDefaultFetchParams,
    onTableChange,
    onTableInit: onTableChange,
  }
}

export default useMuiDatatablesHelper
