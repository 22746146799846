/* eslint-disable react/prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import AsyncDropdown from "components/common/input/AsyncDropdown"
import { numberFormat } from "utils/thousand-separator"
import { useFieldArray, useForm } from "react-hook-form"
import { useBaseFormStyles } from "./constants"

const EstimateItemInput = ({
  estimateItem,
  watch,
  index,
  register,
  disableFields,
  errors,
  onDeleteItem,
  // updatePostedWorkOrder = false,
  fetchMouldCodeData,
  canDeleteWorkOrderEstimate,
}) => {
  const classes = useBaseFormStyles()

  const { control } = useForm({
    defaultValues: { schedules: estimateItem.schedules },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "schedules",
  })

  const [mouldCodeInput, setMouldCodeInput] = useState(
    estimateItem.mouldCodeRef
  )

  const settings = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    disabled: disableFields,
    FormHelperTextProps: { className: classes.errorLabel },
    inputRef: register(),
  }

  const productName = watch(`estimateList[${index}].productName`)
  const mouldCode = watch(`estimateList[${index}].mouldCode`)

  const handleAddClick = () => {
    append({})
  }

  return (
    <TableRow>
      <TableCell size="small" className={classes.rowNonInput}>
        <span style={{ fontSize: "0.8rem" }}>{estimateItem.slsQuotNumber}</span>
        <input
          ref={register()}
          type="hidden"
          name={`estimateList[${index}].slsQuotId`}
          defaultValue={estimateItem.idSalesQuotation}
        />
        <input
          ref={register()}
          type="hidden"
          name={`estimateList[${index}].idWorkOrderEstimate`}
          defaultValue={estimateItem?.idWorkOrderEstimate}
        />
      </TableCell>
      <TableCell size="small">
        {/* <span>{estimateItem.productName}</span> */}
        <TextField
          {...settings}
          style={{ width: "10rem" }}
          name={`estimateList[${index}].productName`}
          defaultValue={estimateItem?.productName}
          // onChange={(e) => setProductName(e.target.value)}
          error={!!(errors.estimateList ?? [])[index]?.productName}
          helperText={(errors.estimateList ?? [])[index]?.productName?.message}
          InputProps={{
            style: { fontSize: "0.8rem" },
          }}
        />
        <small style={{ display: "block" }}>{productName}</small>
        <input
          ref={register()}
          type="hidden"
          name={`estimateList[${index}].slsEstimateId`}
          defaultValue={estimateItem.slsEstimateId}
        />
      </TableCell>
      <TableCell size="small">
        <TextField
          {...settings}
          style={{ width: "10rem" }}
          name={`estimateList[${index}].mouldCode`}
          defaultValue={estimateItem?.mouldCode}
          error={!!(errors.estimateList ?? {})[index]?.mouldCode}
          helperText={(errors.estimateList ?? {})[index]?.mouldCode?.message}
          InputProps={{
            style: { fontSize: "0.8rem" },
          }}
        />
        <small>{mouldCode}</small>

        {estimateItem.hasWorkOrder && (
          <span style={{ color: "red", fontSize: "9pt", display: "block" }}>
            WO has been created, recreate WO?
          </span>
        )}
      </TableCell>
      <TableCell size="small" className={classes.rowNonInput}>
        {numberFormat(estimateItem?.totalCost || 0)}
      </TableCell>
      <TableCell size="small">
        <TextField
          {...settings}
          multiline
          name={`estimateList[${index}].remark`}
          defaultValue={estimateItem.remark}
          error={!!(errors?.estimateList ?? {})[index]?.remark}
          helperText={(errors?.estimateList ?? {})[index]?.remark?.message}
          inputProps={{ style: { fontSize: "0.8rem" } }}
          style={{ width: "350px" }}
        />
      </TableCell>
      <TableCell size="small">
        {fields.map((field, i) => (
          <div
            style={{ display: "flex", gap: "1rem", marginBottom: "8px" }}
            key={field.id}
          >
            <TextField
              {...settings}
              name={`estimateList[${index}].schedules[${i}].name`}
              defaultValue={field.name}
              style={{ width: "200px" }}
              variant="outlined"
              margin="dense"
              label="Schedule Name"
            />
            <TextField
              {...settings}
              style={{ width: "200px" }}
              name={`estimateList[${index}].schedules[${i}].date`}
              defaultValue={field.date}
              type="date"
              variant="outlined"
              margin="dense"
              label="Schedule Date"
              InputLabelProps={{ shrink: true }}
            />
            <IconButton onClick={() => remove(i)}>
              <Delete />
            </IconButton>
          </div>
        ))}
        <div
          style={{ display: "flex", justifyContent: "end", paddingTop: "8px" }}
        >
          <Button variant="contained" color="primary" onClick={handleAddClick}>
            Add
          </Button>
        </div>
      </TableCell>
      <TableCell size="small">
        <TextField
          {...settings}
          type="date"
          name={`estimateList[${index}].dueDateDelivery`}
          error={!!(errors?.estimateList ?? {})[index]?.dueDateDelivery}
          defaultValue={estimateItem.dueDateDelivery.substring(0, 10) ?? ""}
          helperText={
            (errors.estimateList ?? {})[index]?.dueDateDelivery?.message
          }
          inputProps={{ style: { fontSize: "0.8rem" } }}
        />
      </TableCell>
      <TableCell size="small">
        <TextField
          {...settings}
          type="number"
          name={`estimateList[${index}].sequence`}
          error={!!(errors?.estimateList ?? {})[index]?.sequence}
          defaultValue={estimateItem.sequence ?? index + 1}
          helperText={(errors?.estimateList ?? {})[index]?.sequence?.message}
          InputProps={{
            style: { fontSize: "0.8rem" },
          }}
        />
      </TableCell>
      <TableCell size="small">
        <AsyncDropdown
          disabled={disableFields}
          fetchData={fetchMouldCodeData}
          getOptionLabel={(opt) => opt?.mouldCode || opt}
          value={mouldCodeInput}
          style={{ width: "8rem" }}
          variant="outlined"
          getOptionSelected={(opt, val) => opt.mouldCode === val?.mouldCode}
          freeSolo
          inputProps={{ margin: "dense" }}
          onInputChange={(val) => {
            setMouldCodeInput(val)
          }}
        />
        <input
          hidden
          ref={register()}
          name={`estimateList[${index}].mouldCodeRef`}
          value={mouldCodeInput}
        />
      </TableCell>
      <TableCell>
        {canDeleteWorkOrderEstimate ? (
          <IconButton onClick={onDeleteItem}>
            <Delete />
          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  )
}

EstimateItemInput.propTypes = {
  estimateItem: PropTypes.shape({
    productName: PropTypes.string,
    mouldCode: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  updatePostedWorkOrder: PropTypes.bool.isRequired,
}

export default EstimateItemInput
