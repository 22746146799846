const initialState = {
  open: false,
}

export default function dialogLogoutReducer(state, action) {
  if (typeof state === "undefined") {
    return initialState
  }

  const { type } = action
  switch (type) {
    case "DIALOG_LOGOUT_SHOW":
      return { ...state, open: true }
    case "DIALOG_LOGOUT_HIDE":
      return { ...state, open: false }
    default:
      return state
  }
}
