import React from "react"
import Typography from "@material-ui/core/Typography"

export default function CustomerDisplay({
  customerData,
  show = true,
  flat = false,
  propName,
}) {
  const data = show ? customerData || {} : {}
  const name = (data.name || "").trim()

  let propData = null
  if (propName === "custCode") {
    propData = (data.custCode || "").toString().trim()
  } else if (propName === "address") {
    propData = (data.address || "").trim()
  }

  const dataExists = name && propData
  const flatDisplay =
    show && dataExists ? (
      <span>
        {propData} ({name})
      </span>
    ) : null
  const normalDisplay = (
    <div>
      <Typography
        variant="body2"
        style={{ marginBottom: "-4px", fontWeight: "bold" }}
      >
        {propData}
      </Typography>
      <Typography variant="caption">{name}</Typography>
    </div>
  )
  return flat ? flatDisplay : normalDisplay
}
