/* eslint-disable react/prop-types */
import MUIDataTable from "mui-datatables"
import React, { useMemo } from "react"
import { numberFormat } from "utils/thousand-separator"

function AdditionalCostDisplay({ productDesctiptions = [] }) {
  const additionalCosts = useMemo(() => {
    return productDesctiptions
      .map((pd) => {
        return pd.additionalCosts.flat()
      })
      .flat()
  }, [productDesctiptions])

  const columnSettings = useMemo(() => {
    return [
      { label: "PO. Number", name: "poNumber" },
      { label: "Description", name: "description" },
      { label: "Quantity", name: "quantity" },
      { label: "Unit", name: "unit" },
      {
        label: "Unit Price",
        name: "unitPrice",
        options: {
          customBodyRender: (val) => numberFormat(val),
        },
      },
      {
        label: "Total",
        name: "unitPrice",
        options: {
          customBodyRenderLite: (index) => {
            const item = additionalCosts[index]

            return numberFormat(item.quantity * item.unitPrice)
          },
        },
      },
      {
        label: "Remark",
        name: "remark",
      },
    ]
  }, [additionalCosts])

  const tableOptions = {
    elevation: 0,
    download: false,
    print: false,
    responsive: "standard",
    selectableRows: false, // <===== will turn off checkboxes in rows
  }

  return (
    <>
      <MUIDataTable
        title={<strong>Additional Cost</strong>}
        data={additionalCosts}
        columns={columnSettings}
        options={tableOptions}
      />
    </>
  )
}

export default AdditionalCostDisplay
