export function showSnackbar(snackbarMessage) {
  return {
    type: "SHOW_SNACKBAR",
    payload: { message: snackbarMessage, open: true, status: "" },
  }
}

export function showSnackbarLoading(snackbarMessage) {
  return {
    type: "SHOW_SNACKBAR",
    payload: { message: snackbarMessage, open: true, status: "loading" },
  }
}

export function showSnackbarAutohide(snackbarMessage) {
  return {
    type: "SHOW_SNACKBAR_AUTOHIDE",
    payload: {
      message: snackbarMessage,
      open: true,
      status: "",
      duration: 10000,
    },
  }
}

export function hideSnackbar() {
  return { type: "HIDE_SNACKBAR", payload: { open: false } }
}
