import omitBy from "lodash/omitBy"
import isNil from "lodash/isNil"

// // Old dict to string param.
// export const newDictToStringParam = (queries) => {
//   const quotedJson = JSON.stringify(queries)
//   const unquoted = quotedJson.replace(/"([^"]+)":/g, "$1:")
//   const newStr = unquoted.substring(1, unquoted.length - 1)
//   return newStr
// }

const stringifyItem = (item) => {
  // Convert string, number, bool, and null to its JSON format
  if (
    typeof item === "string" ||
    typeof item === "number" ||
    typeof item === "boolean" ||
    item === null
  ) {
    return JSON.stringify(item)
  }
  // Transform each item in array using stringifyItem
  if (Array.isArray(item)) {
    const transformedChildrens = item
      .map(stringifyItem)
      // Remove undefined elements.
      .filter((i) => i)
      .join(", ")
    return `[${transformedChildrens}]`
  }

  // Transform each item in the object using { propName: proValue } notation
  if (typeof item === "object") {
    const transformedChildrens = Object.keys(item)
      .map((propName) => {
        // Discard non-string keys.
        if (typeof propName !== "string") {
          return undefined
        }
        const propVal = stringifyItem(item[propName])
        // Discard undefined attributes.
        if (!propVal) {
          return undefined
        }
        return `${propName}: ${propVal}`
      })
      .filter((i) => i)
    if (transformedChildrens.length <= 0) {
      return undefined
    }
    return `{ ${transformedChildrens.join(", ")} }`
  }

  return undefined
}

export const newDictToStringParam = (queries) => {
  const stringified = stringifyItem(queries)
  if (!stringified) {
    return ""
  }
  return stringified.substring(2, stringified.length - 2)
}

/** Throw an error if the response contains any error */

export const handleSilentApiError = (response) => {
  const { data } = response
  const { errors } = data

  if (errors) {
    const error = new Error()
    error.message = errors.map((err) => err.message).join(", ")
    error.errors = errors
    throw error
  }
}

/**
 * Adjust default params for fetching list query
 * by removing searchable columns
 * @param {*} params
 * @param {*} options
 */

export const adjustDefaultParams = (params = {}, options = {}) => {
  const newParams = { ...params }
  const { searchableColumns, mapColumnNameToServer: mapColumnName } = options
  if (Array.isArray(params?.fromColumns)) {
    let { fromColumns } = params
    if (Array.isArray(searchableColumns)) {
      fromColumns = fromColumns.filter((param) =>
        searchableColumns.includes(param)
      )
    }
    if (mapColumnName) {
      fromColumns = fromColumns.map((columnName) => {
        if (mapColumnName[columnName]) {
          return mapColumnName[columnName]
        }
        return columnName
      })
    }
    if (fromColumns.length < 1) {
      delete newParams.fromColumns
    } else {
      newParams.fromColumns = fromColumns
    }
  }
  return newParams
}

const removeNil = (obj) => omitBy(obj, isNil)

/**
 * Get query and an alias for count query.
 * @param {*} query
 */

export const getQueryForAllAndCount = (query, params = {}) => {
  const matches = query.match(
    /^[\n\s]*query *{[\n\s]+([\w]+)\s*{([\w{}\s\n,]*)}[\n\s]*}$/
  )
  if (!matches) return query
  const [, endpointName, content] = matches

  const { start, limit, ...paramsWithoutPagination } = params
  let paramsWithoutPaginationAsString = newDictToStringParam(
    removeNil(paramsWithoutPagination)
  )
  let paramsAsString = newDictToStringParam(removeNil(params))

  // Add brackets if params string is not empty

  if (paramsWithoutPaginationAsString) {
    paramsWithoutPaginationAsString = `(${paramsWithoutPaginationAsString})`
  }
  if (paramsAsString) {
    paramsAsString = `(${paramsAsString})`
  }

  const [, firstProperty] = content.match(/([\w]+)/)

  return `
  query {
    ${endpointName} ${paramsAsString} {
      ${content}
    }
    allItems: ${endpointName} ${paramsWithoutPaginationAsString} {
      ${firstProperty}
    }
  }
  `
}

export const getDataAndCountFromResponse = (response) => {
  const responseData = response.data.data
  const { allItems } = responseData
  const count = allItems.length
  const dataKey = Object.keys(responseData).filter(
    (key) => key !== "allItems"
  )[0]
  const data = responseData[dataKey]
  return { data, count }
}
