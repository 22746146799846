import PropTypes from "prop-types"
import React, { useEffect, useMemo } from "react"
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom"
import { Breadcrumbs, Link, makeStyles } from "@material-ui/core"
import { useSelector } from "react-redux"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  root: {
    "&": `
      margin-bottom: ${theme.spacing(1)}px;
      user-select: none;
    `,
  },
  linkActive: {
    "&": `
      font-weight: bold;
    `,
  },
}))

const BreadcrumbDisplay = ({ className, ...props }) => {
  const classes = useStyles()
  const items = useSelector((state) => state.breadcrumbReducer.items)
  const location = useLocation()
  const pathname = location.pathname + location.search

  const itemsWithoutUndef = useMemo(() => {
    const undefinedIndex = items.findIndex((item) => !item)
    if (undefinedIndex < 0) return items
    const newItems = [...items].splice(undefinedIndex)
    return newItems
  }, [items])

  // Update document title
  useEffect(() => {
    const lastRoute = itemsWithoutUndef[itemsWithoutUndef.length - 1]
    let title = "MANUFLEX | Manufacturing Flexible"
    if (typeof lastRoute?.name === "string") {
      title = `${lastRoute.name} - MANUFLEX`
    }
    document.title = title
  }, [itemsWithoutUndef])

  return (
    <Breadcrumbs className={clsx(className, classes.root)} {...props}>
      {itemsWithoutUndef.map((item) => {
        const routeMatch = matchPath(pathname, item.match)
        if (!routeMatch || routeMatch.isExact) {
          return (
            <span
              key={item.id}
              title={item.match && "You are here."}
              className={clsx(
                classes.noLink,
                routeMatch?.isExact && classes.linkActive
              )}
            >
              {item.name}
            </span>
          )
        }
        return (
          <Link
            key={item.id}
            color="primary"
            component={RouterLink}
            to={routeMatch.url || ""}
          >
            {item.name}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

BreadcrumbDisplay.propTypes = {
  className: PropTypes.string,
}

BreadcrumbDisplay.defaultProps = {
  className: null,
}

export default BreadcrumbDisplay
