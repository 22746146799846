import { axios, ENDPOINT } from "./constants"
import {
  handleSilentApiError,
  newDictToStringParam,
  adjustDefaultParams,
  getQueryForAllAndCount,
  getDataAndCountFromResponse,
} from "./utils"

const ITEM_DETAIL_QUERY = `
  idCust
  pic
  name
  email
  address
  address2
  status
  currency {
    idCurr
    code
    description
  }
  paymentMethod {
    idPayment
    name
    description
  }
  destinationList {
    destination
    idCustomerDestination
  }
  phoneNumber
  faxNumber
  custCode
  vat
  initial`

export default class ApiCustomer {
  static async get(inpParams = {}) {
    const inpParamsCopy = { ...inpParams }
    // inpParamsCopy.ignoreCase = true
    const params = adjustDefaultParams(inpParamsCopy)
    let query = `
    query { 
      allCustomerImpl{
        idCust
        custCode
        name
        email
        address
        address2
        phoneNumber
        faxNumber
        status
        initial
        pic
        destinationList {
          idCustomerDestination
          destination
        }
      }
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)

    return getDataAndCountFromResponse(response)
  }

  static async getAllForQuotation() {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query { 
        allCustomerImpl{
          idCust
          custCode
          name
          address
          currId
          paymentMethodId
          destinationList {
            idCustomerDestination
            destination
          }
        }
      }`,
    })
    handleSilentApiError(response)
    return { data: response.data.data.allCustomerImpl }
  }

  static async getItem(id) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query { 
        getCustomerImpl (id: ${id}) {
          ${ITEM_DETAIL_QUERY}
        }
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.getCustomerImpl }
  }

  static async create(payload) {
    const payloadAsParam = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        saveCustomerImpl(${payloadAsParam}) {
          customerImpl {
            idCust
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.saveCustomerImpl.customerImpl }
  }

  static async update(idCust, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idCust,
    })

    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateCustomerImpl (${payloadAsParam}) {
          customerImpl {
            ${ITEM_DETAIL_QUERY}
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.updateCustomerImpl.customerImpl }
  }

  static async exportExcel() {
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
        mutation {
          exportCustomerList {
            customerListExcelTemplate
          }
        }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.exportCustomerList.customerListExcelTemplate,
    }
  }
}
