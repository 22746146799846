/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */

import { IconButton, TableCell, TableRow, TextField } from "@material-ui/core"
import React, { useContext, useEffect, useMemo, useState } from "react"
import * as t from "prop-types"
import { Add } from "@material-ui/icons"
import InvoiceFormContext from "./InvoiceFormContex"
import { currencyOperation } from "./utils"
import InvoiceDescriptionFormContext from "./InvoiceDescriptionFormContext"

function ProductDescriptionRow({
  product,
  index,
  register,
  showAdditionalCostButton,
}) {
  const {
    selectedCurrency,
    paymentPercentage,
    currencyRate,
    rateIn,
    setProductDescriptions,
    productDescriptions,
  } = useContext(InvoiceFormContext)

  const [rowPercentage, setRowPercentage] = useState(
    product?.paymentPercentage ?? ""
  )
  const { onConfirmAddCost } = useContext(InvoiceDescriptionFormContext)

  const totalCostWithCurrency = useMemo(() => {
    return currencyOperation(product.totalCost, rateIn, currencyRate)
  }, [currencyRate, product.totalCost, rateIn])

  const totalCostWithPercentage = useMemo(() => {
    if (rowPercentage) {
      return totalCostWithCurrency * (0 + rowPercentage / 100)
    }
    if (!paymentPercentage) return totalCostWithCurrency
    return totalCostWithCurrency * (0 + paymentPercentage / 100)
  }, [paymentPercentage, totalCostWithCurrency, rowPercentage])

  useEffect(() => {
    const x = setTimeout(() => {
      const copy = [...productDescriptions]
      copy[index] = {
        ...copy[index],
        paymentPercentage: rowPercentage,
      }
      setProductDescriptions(copy)
    }, 500)

    return () => {
      clearTimeout(x)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowPercentage])
  return (
    <TableRow>
      <TableCell>
        <p>{product.dnNumber}</p>
      </TableCell>
      <TableCell>
        <p>{product.poNumber}</p>
      </TableCell>
      <TableCell>
        <p style={{ whiteSpace: "pre-line" }}>{product.description}</p>
        <input
          type="hidden"
          name={`productDescriptions[${index}].description`}
          value={product.description}
          ref={register}
        />
        <input
          type="text"
          style={{ display: "none" }}
          name={`productDescriptions[${index}].itemKey`}
          defaultValue={product.itemKey}
          ref={register}
        />

        <input
          type="hidden"
          name={`productDescriptions[${index}].dnDetailId`}
          ref={register}
          value={product.idDnDetail}
        />
      </TableCell>
      <TableCell>
        <p>{product.quantity}</p>
        <input
          type="hidden"
          name={`productDescriptions[${index}].quantity`}
          value={product.quantity}
          ref={register()}
        />
      </TableCell>
      <TableCell>
        <p>{product.unit}</p>
        <input
          type="hidden"
          name={`productDescriptions[${index}].unit`}
          value={product.unit}
          ref={register()}
        />
      </TableCell>
      <TableCell>
        {selectedCurrency?.code}{" "}
        {new Intl.NumberFormat().format(
          Math.ceil(totalCostWithCurrency / +product.quantity)
        )}
        <input
          type="hidden"
          name={`productDescriptions[${index}].unitPrice`}
          ref={register()}
          value={product.totalCost}
        />
      </TableCell>
      <TableCell>
        <TextField
          name={`productDescriptions[${index}].paymentPercentage`}
          defaultValue={rowPercentage}
          inputRef={register}
          margin="dense"
          fullWidth
          variant="outlined"
          type="number"
          onChange={(e) => setRowPercentage(e.target.value)}
        />
      </TableCell>
      <TableCell>
        {selectedCurrency?.code}{" "}
        {new Intl.NumberFormat().format(Math.ceil(totalCostWithPercentage))}
      </TableCell>
      <TableCell>
        <TextField
          name={`productDescriptions[${index}].sequence`}
          defaultValue={product.sequence || index + 1}
          inputRef={register}
          margin="dense"
          fullWidth
          variant="outlined"
          type="number"
        />
        <TextField
          name={`productDescriptions[${index}].itemKey`}
          defaultValue={product.itemKey}
          inputRef={register}
          margin="dense"
          fullWidth
          variant="outlined"
          type="number"
          style={{ display: "none" }}
        />
      </TableCell>
      <TableCell>
        {showAdditionalCostButton ? (
          <IconButton
            onClick={() => {
              onConfirmAddCost(product)
            }}
          >
            <Add />
          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  )
}

ProductDescriptionRow.propTypes = {
  product: t.shape({
    description: t.string,
    unit: t.string,
    quantity: t.number,
    totalCost: t.number,
    idDnDetail: t.number,
    dnNumber: t.string,
    poNumber: t.string,
    sequence: t.number,
    paymentPercentage: t.number,
  }),
  index: t.number,
  register: t.any.isRequired,
}

ProductDescriptionRow.defaultProps = {
  product: {
    description: "",
    unit: "",
    quantity: 0,
    totalCost: 0,
    idDnDetail: "",
    dnNumber: "",
    poNumber: "",
    sequence: 0,
  },
  index: 0,
}
export default ProductDescriptionRow
