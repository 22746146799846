import { useCallback } from "react"
import { useDispatch } from "react-redux"
import sendAndHandleInvalidToken from "./sendAndHandleInvalidToken"

/**
 * A hook to perform `sendAndHandleInvalidToken`. The `dispatch`
 * will be handled inside this hook so that the
 * second `dispatch` argument is not needed.
 *
 * This hook returns callback that can be used like
 * `sendAndHandleInvalidToken`.
 */

const useSendAndHandleInvalidToken = () => {
  const dispatch = useDispatch()
  const wrappedCallback = useCallback(
    (callback) => {
      return sendAndHandleInvalidToken(callback, dispatch)
    },
    [dispatch]
  )
  return wrappedCallback
}

export default useSendAndHandleInvalidToken
