import React from "react"
import Typography from "@material-ui/core/Typography"

export function CustomerAddressDisplay({
  customerData,
  show = true,
  flat = false,
}) {
  const data = show ? customerData || {} : {}
  const name = (data.name || "").trim()
  const address = (data.address || "").trim()
  const dataExists = name && address
  const flatDisplay =
    show && dataExists ? (
      <span>
        {address} ({name})
      </span>
    ) : null
  const normalDisplay = (
    <div>
      <Typography
        variant="body2"
        style={{ marginBottom: "-4px", fontWeight: "bold" }}
      >
        {address}{" "}
      </Typography>
      <Typography variant="caption">{name} </Typography>
    </div>
  )
  return flat ? flatDisplay : normalDisplay
}

export function CustomerCodeDisplay({
  customerData,
  show = true,
  flat = false,
}) {
  const data = show ? customerData || {} : {}
  const name = (data.name || "").trim()
  const custCode = (data.custCode || "").toString().trim()
  const dataExists = name && custCode
  const flatDisplay =
    show && dataExists ? (
      <span>
        {custCode} ({name})
      </span>
    ) : null
  const normalDisplay = (
    <div>
      <Typography
        variant="body2"
        style={{ marginBottom: "-4px", fontWeight: "bold" }}
      >
        {custCode}{" "}
      </Typography>
      <Typography variant="caption">{name} </Typography>
    </div>
  )
  return flat ? flatDisplay : normalDisplay
}

export default CustomerCodeDisplay
