import React, { forwardRef, useImperativeHandle, useRef, useState } from "react"
import PropTypes from "prop-types"
import { openOnlyTopLevel, useOpenState } from "utils/side-nav-open-state"

import { makeStyles } from "@material-ui/core/styles"

import ButtonBase from "@material-ui/core/ButtonBase"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"

import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import ChevronRight from "@material-ui/icons/ChevronRight"

import { Link as RouterLink } from "react-router-dom"
import defaultTheme from "app-theme"

const ListItemLink = (props) => {
  const { to, location, open, level, text, onClick, onExpand } = props

  const hasLink = !!to
  const hasList = open != null
  const active = hasLink && location === to
  const primary = text
  const topLevel = level === 0

  const topLevelIcons = open ? <ExpandLess /> : <ExpandMore />
  const otherLevelIcons = open ? <ExpandMore /> : <ChevronRight />

  const toggleWrapper = (
    <IconButton
      color="inherit"
      size="small"
      style={{ borderRadius: 0 }}
      onClick={onExpand}
    >
      {topLevel ? topLevelIcons : otherLevelIcons}
    </IconButton>
  )
  const toggle = hasList ? toggleWrapper : null

  const spacing = level === 0 ? null : (level - 1) * 10
  const levelPanStyle = spacing != null ? { width: spacing } : {}
  const levelPanElement = <div style={levelPanStyle} />

  const itemDisplay = hasLink ? (
    <ButtonBase
      className="text"
      component={RouterLink}
      to={to}
      onClick={onClick}
    >
      <span>{primary}</span>
    </ButtonBase>
  ) : (
    <ButtonBase className="text" disabled component="div">
      <span>{primary}</span>
    </ButtonBase>
  )

  let listClass = "list-item-link"
  listClass += hasList ? " has-list" : ""
  listClass += topLevel ? " top-level" : ""
  listClass += active ? " active" : ""
  listClass += hasLink ? " has-link" : ""

  return (
    <li className={listClass}>
      {levelPanElement}
      {level === 0 ? (
        <>
          {itemDisplay}
          {toggle}
        </>
      ) : (
        <>
          {toggle}
          {itemDisplay}
        </>
      )}
    </li>
  )
}

ListItemLink.propTypes = {
  to: PropTypes.string,
  location: PropTypes.string,
  open: PropTypes.bool,
  level: PropTypes.number.isRequired,
  text: PropTypes.node,
  onClick: PropTypes.func,
  onExpand: PropTypes.func,
}

ListItemLink.defaultProps = {
  to: null,
  location: null,
  open: null,
  text: null,
  onClick: () => {},
  onExpand: () => {},
}

const ListItemContainer = forwardRef(
  (
    {
      itemRoute: currentItemRoute,
      location,
      itemId: currItemId,
      level,
      previousItemIndices,
      onClick,
      onExpand,
      initOpenRule,
    },
    ref
  ) => {
    const { name, link, routes } = currentItemRoute
    const hasRoutes = Array.isArray(routes) && routes.length > 0

    const itemIndices = [...previousItemIndices, currItemId]
    const [open, setOpen] = useState(() =>
      initOpenRule({
        level,
        name,
        route: currentItemRoute,
        indices: itemIndices,
      })
    )

    const handleExpand = () => {
      onExpand(!open, itemIndices)
      setOpen((prevOpen) => !prevOpen)
    }

    const handleClick = (event) => {
      onClick(itemIndices, event)
    }

    const childrenRefs = useOpenState(open, routes, ref)

    if (level > 4) {
      return <div>Base case</div>
    }
    if (!hasRoutes) {
      return (
        <ListItemLink
          location={location}
          text={name}
          to={link}
          level={level}
          onClick={handleClick}
        />
      )
    }

    return (
      <>
        <ListItemLink
          location={location}
          text={name}
          to={link}
          open={open}
          level={level}
          onClick={handleClick}
          onExpand={handleExpand}
        />
        <Collapse component="li" in={open} timeout="auto">
          <List disablePadding>
            {routes.map((itemRoute, itemId) => (
              <ListItemContainer
                // eslint-disable-next-line react/no-array-index-key
                key={itemId}
                itemRoute={itemRoute}
                itemId={itemId}
                location={location}
                level={level + 1}
                onClick={onClick}
                onExpand={onExpand}
                previousItemIndices={itemIndices}
                ref={childrenRefs[itemId]}
                initOpenRule={initOpenRule}
              />
            ))}
          </List>
        </Collapse>
      </>
    )
  }
)

ListItemContainer.propTypes = {
  itemRoute: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  location: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  level: PropTypes.number,
  previousItemIndices: PropTypes.arrayOf(PropTypes.number),
  onClick: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  initOpenRule: PropTypes.func,
}

ListItemContainer.defaultProps = {
  level: 0,
  previousItemIndices: [],
  initOpenRule: () => false,
}

// Styling used in this component

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    "& .list-item-link": {
      display: "flex",
      flexFlow: "row",
      color: "#033278",
      "&.top-level": {
        backgroundColor: "#033278",
        color: "white",
        fontSize: defaultTheme.typography.body1.fontSize,
        fontWeight: "bold",
        marginBottom: "2px",
        "&.active": {
          color: "cyan",
        },
        "& .text": {
          paddingLeft: "6px",
          "& *": {
            padding: "6px",
          },
        },
      },
      "& button": {
        width: "32px",
        padding: "0",
      },
      "& .text": {
        flexGrow: 1,
        textAlign: "left",
        "& *": {
          width: "100%",
          padding: "4px",
          paddingLeft: "6px",
          paddingRight: "0",
        },
      },
      "&:not(.top-level):not(.has-list) .text": {
        paddingLeft: "32px",
      },
      "&.has-link.top-level:hover": {
        backgroundColor: "#135e9a",
        color: "white",
      },
      "&.has-link:hover": {
        backgroundColor: "#dedede",
      },
      "&:not(.top-level):not(.has-link)": {
        color: "black",
      },
      "&:not(.top-level).active": {
        color: "#003686",
        fontWeight: "bold",
        backgroundColor: "#dedede",
      },
    },
  },
}))

const SideNavigation = forwardRef(
  ({ routes, location, onClick, onExpand, initOpenRule }, ref) => {
    const classes = useStyles()

    const rootRef = useRef({})
    // Since root has no open state, let's use the dummy boolean false.
    const childrenRefs = useOpenState(false, routes, rootRef)
    useImperativeHandle(
      ref,
      () => ({
        getOpenStates: () => {
          // This contains array with two elements:
          // root open state and the routes state.
          // root open state is not needed, so take the second element
          const openStates = rootRef.current.getDescendantsOpenState()
          return openStates[1]
        },
      }),
      []
    )

    return (
      <nav className={classes.root} aria-label="main-navigation">
        <List>
          {routes.map((itemRoute, itemId) => (
            <ListItemContainer
              location={location}
              // eslint-disable-next-line react/no-array-index-key
              key={itemId}
              itemRoute={itemRoute}
              itemId={itemId}
              onClick={onClick}
              onExpand={onExpand}
              initOpenRule={initOpenRule}
              ref={childrenRefs[itemId]}
            />
          ))}
        </List>
      </nav>
    )
  }
)

SideNavigation.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({})),
  location: PropTypes.string,
  onClick: PropTypes.func,
  onExpand: PropTypes.func,
  initOpenRule: PropTypes.func,
}

SideNavigation.defaultProps = {
  routes: [],
  location: "",
  onClick: () => {},
  onExpand: () => {},
  initOpenRule: openOnlyTopLevel,
}

export default SideNavigation
