const initialState = {
  snackbarMessage: "",
  snackbarOpen: false,
  status: "",
  autoHideDuration: null,
}

export default function snackbarReducer(state, action) {
  if (typeof state === "undefined") {
    return initialState
  }

  const { type } = action
  switch (type) {
    case "SHOW_SNACKBAR":
      return {
        ...state,
        snackbarMessage: action.payload.message,
        snackbarOpen: action.payload.open,
        status: action.payload.status,
      }
    case "SHOW_SNACKBAR_AUTOHIDE":
      return {
        status: action.payload.status,
        snackbarMessage: action.payload.message,
        snackbarOpen: action.payload.open,
        autoHideDuration: action.payload.duration,
      }
    case "HIDE_SNACKBAR":
      return { ...state, snackbarOpen: action.payload.open }
    default:
      return state
  }
}
