/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import { yupResolver } from "@hookform/resolvers"
import {
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  Paper,
  TableFooter,
  Button,
} from "@material-ui/core"
import { useFieldArray, useForm } from "react-hook-form"
import * as yup from "yup"
import clsx from "clsx"
import { isNaN } from "lodash"
import { transformProperty } from "utils/objects"
// import { Autocomplete } from "@material-ui/lab"
import DeleteDialog from "components/common/DeleteDialog"
import { Add } from "@material-ui/icons"
import AsyncDropdown from "components/common/input/AsyncDropdown"
import { simplifyDNProductDetails } from "components/invoice/utils"
import EstimateItemInput from "./EstimateItemInput"
import { useBaseFormStyles } from "./constants"
// import EstimateItemInput from "./EstimateItemInput"
import WOFormContext from "./WOFormContext"

const estimateItemsSchema = {
  remark: yup.string().when("mouldCode", {
    is: (value) => !!value,
    then: yup.string().required("This field is required"),
  }),
  slsEstimateId: yup.string().when("mouldCode", {
    is: (value) => !!value,
    then: yup.string().required("This field is required"),
  }),
  productName: yup.string().required("This field is required"),
  slsQuotId: yup.number().required("This field is required"),
  sequence: yup.number().typeError("Must be a number").required(),
}

const formSchema = yup.object().shape({
  estimateList: yup.array().of(yup.object().shape(estimateItemsSchema)),
})

const formSchemaResolver = yupResolver(formSchema)

const InputTableEstimate = forwardRef(
  (
    {
      defaultValue = [],
      fetchQuotationData,
      fetchEstimateData,
      updatePostedWorkOrder = false,
      canDeleteWorkOrderEstimate,
      fetchMouldCodeData,
    },
    ref
  ) => {
    const [estimateData, setEstimateData] = useState([])
    const [loading, setLoading] = useState(false)
    const classes = useBaseFormStyles()
    const [initialValue] = useState(defaultValue)
    const [joinedValue] = useState(() =>
      // eslint-disable-next-line no-use-before-define
      joinEstimateAndDefaultValue(estimateData, initialValue)
    )
    const [open, setOpen] = useState(false)

    const { customerId } = useContext(WOFormContext)
    const [selectedQuotationList, setSelectedQuotationList] = useState([])

    useEffect(() => {
      const arr = defaultValue.map((item) => {
        const { totalCost } = simplifyDNProductDetails([
          { salesEstimate: item.salesEstimate },
        ])[0]
        const cost = item.totalAmount || totalCost
        return {
          slsEstimateId: item.salesEstimateId,
          productName:
            item.description ?? item?.salesEstimate?.description ?? "-",
          slsQuotNumber:
            item.salesEstimate?.slsQuot?.slsQuotNumber ||
            item.salesEstimate?.slsQuot,
          idSalesQuotation: item.salesQuotation?.idSalesQuotation,
          totalCost: cost - item.discount,
          mouldCode: item.mouldCode || "",
        }
      })

      setEstimateData(arr)
    }, [defaultValue])

    const fetchSQList = React.useCallback(
      async (searchText) => {
        if (!customerId) return []
        const response = await fetchQuotationData({ searchText, customerId })
        return response
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [customerId]
    )

    const [selectedQuotation, setSelectedQuotation] = useState(null)

    const {
      register,
      control,
      handleSubmit,
      setValue,
      getValues,
      errors,
      watch,
    } = useForm({
      defaultValues: { estimateList: joinedValue },
      resolver: formSchemaResolver,
    })

    const { fields } = useFieldArray({
      control,
      name: "estimateList",
      keyName: "__id",
    })

    useImperativeHandle(ref, () => ({
      onSubmit: () =>
        new Promise((res, rej) => {
          handleSubmit(res, rej)()
        }),
      getFormData: () => {
        const formData = getValues()
        const estimations = formData.estimateList || []
        const cleanedEstimations = estimations
          .map((estimation) => {
            const estCopy = { ...estimation }
            const { schedules } = estCopy

            transformProperty(
              estCopy,
              ["slsEstimateId", "slsQuotId", "sequence", "idWorkOrderEstimate"],
              parseInt
            )

            if (estCopy.mouldCode === "") {
              return null
            }
            if (!estCopy.idWorkOrderEstimate) delete estCopy.idWorkOrderEstimate
            return {
              ...estCopy,
              schedules: (schedules || []).filter((s) => s.name && s.date),
            }
          })
          .filter((estimation) => estimation !== null)

        // eslint-disable-next-line no-unreachable
        return cleanedEstimations
      },
    }))

    useEffect(() => {
      setValue(
        "estimateList",
        joinEstimateAndDefaultValue(estimateData, initialValue)
      )
    }, [initialValue, setValue, estimateData])

    const refCounter = useRef(0)
    const deletedIndexRef = useRef(null)

    useEffect(() => {
      if (refCounter.current !== 0) {
        setValue("estimateList", [])
      }
      refCounter.current += 1
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId])

    const fieldsEmpty = fields.length === 0

    const fetchEstimationData = async () => {
      setLoading(true)
      const formValue = getValues()

      const response = await fetchEstimateData(
        selectedQuotation.idSalesQuotation
      )
      const prevValues = estimateData.map((item) => {
        const defValue = (formValue.estimateList ?? []).filter(
          (val) => parseInt(val.slsEstimateId, 10) === item.slsEstimateId
        )[0]

        return {
          ...item,
          remark: defValue?.remark,
          dueDateDelivery: defValue?.dueDateDelivery,
          mouldCode: defValue?.mouldCode,
          productName: defValue?.productName || item.description,
          sequence: defValue?.sequence,
        }
      })

      const arr = response
        .map((item) => {
          const {
            estQuot: { rfq },
          } = item
          const { totalCost } = simplifyDNProductDetails([
            { salesEstimate: item },
          ])[0]
          const cost = item.totalAmount || totalCost
          return {
            slsEstimateId: item.idSalesEstimate,
            productName: item.description || "-",
            slsQuotNumber: selectedQuotation.slsQuotNumber,
            idSalesQuotation: selectedQuotation.idSalesQuotation,
            hasWorkOrder:
              (item?.relatedWorkOrderEstimate?.edges ?? []).filter(
                (edge) => edge.node.workOrder.progress !== "deleted"
              ).length > 0,
            dueDateDelivery: item?.dueDateDelivery ?? "",
            totalCost: cost - item.discount,
            mouldCode: rfq?.productNo || "",
          }
        })
        .filter(
          (item) =>
            prevValues.findIndex(
              (val) => val.slsEstimateId === item.slsEstimateId
            ) === -1
        )

      setEstimateData(() => [...prevValues, ...arr])
      setLoading(false)
      setSelectedQuotation(null)
    }

    const onAddSalesQuotation = () => {
      if (selectedQuotation) {
        const selectedQuotationListCopy = [...selectedQuotationList]
        selectedQuotationListCopy.push(selectedQuotation.idSalesQuotation)
        setSelectedQuotationList(selectedQuotationListCopy)
        fetchEstimationData()
      }
    }

    const onDeleteItem = (index) => {
      const current = estimateData[index]

      const values = getValues()

      if (+values.estimateList[index].idWorkOrderEstimate) {
        deletedIndexRef.current = index
        setOpen(true)
        return
      }
      const copyArray = [...estimateData].map((item) => {
        const currentVal = values.estimateList?.filter(
          (est) => +est.slsEstimateId === item.slsEstimateId
        )[0]

        return { ...item, dueDateDelivery: currentVal?.dueDateDelivery || "" }
      })

      setEstimateData(
        copyArray.filter((c) => c.slsEstimateId !== current.slsEstimateId)
      )
    }

    const handleDialogClose = ({ confirmed }) => {
      setOpen(false)
      if (confirmed) {
        const current = estimateData[deletedIndexRef.current]
        const values = getValues()
        const copyArray = [...estimateData].map((item) => {
          const currentVal = values.estimateList?.filter(
            (est) => +est.slsEstimateId === item.slsEstimateId
          )[0]

          return { ...item, dueDateDelivery: currentVal?.dueDateDelivery || "" }
        })

        setEstimateData(
          copyArray.filter((c) => c.slsEstimateId !== current.slsEstimateId)
        )
        deletedIndexRef.current = null
      }
    }

    const colspan = 7

    return (
      <>
        <TableContainer
          className={clsx(classes.tableContainer, classes.root)}
          component={Paper}
        >
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Sales Quotation Number</TableCell>
                <TableCell style={{ width: "20%" }}>Product Name</TableCell>
                <TableCell>Mould Code</TableCell>
                <TableCell>Price</TableCell>
                <TableCell style={{ width: "450px" }}>Remark</TableCell>
                <TableCell style={{ width: "500px" }}>Schedule</TableCell>
                <TableCell>Due Date Delivery</TableCell>
                <TableCell>Sequence</TableCell>
                <TableCell>Mould Code Ref</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldsEmpty || loading ? (
                <TableRow>
                  <TableCell
                    style={{ textAlign: "center" }}
                    colSpan={colspan + 1}
                  >
                    {loading ? "Loading..." : "No data"}
                  </TableCell>
                </TableRow>
              ) : (
                fields.map((estimateItem, index) => (
                  <EstimateItemInput
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    register={register}
                    errors={errors}
                    estimateItem={estimateItem}
                    index={index}
                    watch={watch}
                    onDeleteItem={() => onDeleteItem(index)}
                    updatePostedWorkOrder={updatePostedWorkOrder}
                    fetchMouldCodeData={fetchMouldCodeData}
                    canDeleteWorkOrderEstimate={canDeleteWorkOrderEstimate}
                  />
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={4}>
                  <AsyncDropdown
                    fetchData={fetchSQList}
                    inputLabel="Sales Quotation"
                    getOptionLabel={(option) => {
                      const products = option.estimateList
                        .map((item) => item.description)
                        .join(", ")
                      return `${option.slsQuotNumber} [${products}]`
                    }}
                    getOptionSelected={(opt, val) =>
                      opt.idSalesQuotation === val?.idSalesQuotation
                    }
                    value={selectedQuotation}
                    onChange={(val) => setSelectedQuotation(val)}
                    getOptionDisabled={(opt) =>
                      selectedQuotationList.indexOf(opt.idSalesQuotation) !== -1
                    }
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "10px" }}
                    onClick={onAddSalesQuotation}
                  >
                    <Add />
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <DeleteDialog open={open} onClose={handleDialogClose} />
      </>
    )
  }
)

const joinEstimateAndDefaultValue = (estimate, defaultValue) => {
  const validEstimate = defaultValue.filter(
    (item) => !isNaN(+item.idWorkOrderEstimate)
  )

  const val = estimate.map((estimateItem) => {
    const currentItem = validEstimate.find(
      (item) => +item.salesEstimateId === +estimateItem.slsEstimateId
    )
    const dueDateFromEstimate = estimateItem?.dueDateDelivery
      ? estimateItem.dueDateDelivery?.slice(0, 10)
      : null

    return {
      idWorkOrderEstimate: currentItem?.idWorkOrderEstimate || 0,
      slsEstimateId: estimateItem?.slsEstimateId,
      mouldCode: currentItem?.mouldCode ?? estimateItem.mouldCode ?? "",
      remark: currentItem?.remark ?? estimateItem?.remark ?? "",
      dueDateFinish: currentItem?.dueDateFinish ?? "",
      dueDateDelivery:
        currentItem?.dueDateDelivery?.slice(0, 10) ?? dueDateFromEstimate ?? "",
      productName: currentItem?.description ?? estimateItem?.productName ?? "",
      slsQuotNumber: estimateItem?.slsQuotNumber,
      idSalesQuotation: estimateItem?.idSalesQuotation,
      hasWorkOrder: estimateItem?.hasWorkOrder,
      sequence: currentItem?.sequence ?? estimateItem?.sequence,
      mouldCodeRef: currentItem?.mouldCodeRef || estimateItem?.mouldCodeRef,
      totalCost: currentItem?.totalCost || estimateItem?.totalCost,
      schedules: currentItem?.schedules || [{ name: "", date: "" }],
    }
  })
  console.log(val)
  return val
}

InputTableEstimate.propTypes = {
  defaultValue: PropTypes.shape({}).isRequired,
  fetchQuotationData: PropTypes.func.isRequired,
  fetchEstimateData: PropTypes.func.isRequired,
  updatePostedWorkOrder: PropTypes.bool.isRequired,
}

export default InputTableEstimate
