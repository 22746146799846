import React, { useEffect, useLayoutEffect, useState, useMemo } from "react"
import NotAllowedNotice from "components/NotAllowedNotice"
// import WOForm from "components/work-order/WOForm"
import PERMISSION_CODES from "constants/permission-codes"
import { ROUTE_WORK_ORDER } from "constants/routeConstants"
import { useHistory } from "react-router-dom"
// import WOForm from "components/work-order/WOForm"
import ApiSalesQuotation from "services/api-sales-quotation"
import useCheckPermission from "utils/auth/useCheckPermission"
import WOForm from "components/work-order/WOForm"
import {
  sendAndHandleInvalidToken,
  useWrapHandleInvalidToken as useWrap,
} from "utils/invalid-token"
import useShowSnackbar from "utils/snackbar-hooks"
import ApiCustomer from "services/api-customer"
import ApiWorkOrder from "services/api-work-order"

const PAGE_PERMISSIONS = [PERMISSION_CODES.WRITE_WORK_ORDER]
const fetchSalesQuotation = async ({ searchText = "", customerId } = {}) =>
  (
    (await ApiSalesQuotation.getAllExecutedItem({
      searchText,
      fromColumns: ["slsQuotNumber"],
      customerId,
      limit: 10,
    })) || []
  ).data
const fetchCustomerData = async () => ((await ApiCustomer.get()) || []).data
const fetchEstimateData = async (id) =>
  ((await ApiSalesQuotation.getEstimateItem(id)) || []).data
const fetchWoTypeData = async () =>
  ((await ApiWorkOrder.getWorkOrderType()) || []).data
const fetchMouldCode = async (val) =>
  (
    (await ApiWorkOrder.getAllMoldCode({
      limit: 10,
      fromColumns: ["mouldCode"],
      searchText: val,
    })) || []
  ).data

export default function WorkOrderCreatePage() {
  const wrappedFetchQuotationData = useWrap(fetchSalesQuotation, () => [])
  const wrappedFetchCustomerData = useWrap(fetchCustomerData, () => [])
  const wrappedFetchEstimateData = useWrap(fetchEstimateData, () => [])
  const wrappedFetchWoTypeData = useWrap(fetchWoTypeData, () => [])
  const wrappedFetchMouldCodeData = useWrap(fetchMouldCode, () => [])
  const [initialFormData, setInitialFormData] = useState(null)
  const { checkWithoutUpdateState } = useCheckPermission()

  const canDeleteWorkOrderEstimate = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.DELETE_WORK_ORDER_ESTIMATE],
      }),
    [checkWithoutUpdateState]
  )

  const [showSnackbar, showSnackbarLoading, hideSnackbar] = useShowSnackbar()
  const history = useHistory()
  const { allowed, check } = useCheckPermission()

  useEffect(() => {
    const current = JSON.parse(localStorage.getItem("quotation-data"))
    if (current) {
      setInitialFormData({
        customerId: current.customerId,
        estimateList: current.estimateList.map((est) => ({
          ...est,
          mouldCode: est.estQuot?.rfq?.productNo,
          salesEstimateId: est.idSalesEstimate,
          salesEstimate: {
            slsQuot: current.slsQuotNumber,
          },
          ...{
            salesQuotation: {
              ...current,
              estimateList: [
                {
                  slsQuot: {
                    slsQuotNumber: current.slsQuotNumber,
                    idSalesQuotation: current.idSalesQuotation,
                  },
                },
              ],
            },
          },
        })),
      })
      localStorage.removeItem("quotation-data")
    }
  }, [])

  useLayoutEffect(() => {
    check({ permissions: PAGE_PERMISSIONS })
  }, [check])

  const handleCancel = () => {
    history.push(ROUTE_WORK_ORDER)
  }

  const handleSubmit = async (formData) => {
    const newFormData = Object.keys(formData).reduce((object, key) => {
      if (key !== "") {
        // eslint-disable-next-line no-param-reassign
        object[key] = formData[key]
      }
      return object
    }, {})
    showSnackbarLoading("Creating work order...")
    let fetchState = {}

    try {
      fetchState = await sendAndHandleInvalidToken(() =>
        ApiWorkOrder.create(newFormData)
      )
    } catch (e) {
      showSnackbar(e.message || "Cannot create work order")
      return
    }

    if (fetchState.success) {
      showSnackbar("Work order data successfully created.")
      history.push(
        `${ROUTE_WORK_ORDER}/${fetchState.response.data.idWorkOrder}`
      )
    } else {
      hideSnackbar()
    }
  }

  if (!allowed) {
    return <NotAllowedNotice />
  }

  return (
    <WOForm
      fetchQuotationData={wrappedFetchQuotationData}
      fetchCustomerData={wrappedFetchCustomerData}
      fetchEstimateData={wrappedFetchEstimateData}
      fetchWoTypeData={wrappedFetchWoTypeData}
      fetchMouldCodeData={wrappedFetchMouldCodeData}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      initialFormData={initialFormData}
      canDeleteWorkOrderEstimate={canDeleteWorkOrderEstimate}
    />
  )
}
