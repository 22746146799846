/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { forwardRef } from "react"
import "../../assets/print/invoice-page.css"
import HeaderPrint from "components/common/print/HeaderPrint"
import { useAlignStyles } from "components/bill-of-material/BOMItemPrintStyles"
import GeneralTablePrint from "components/common/print/GeneralTablePrint"
import clsx from "clsx"
import { formatDate } from "utils/form-data"
import { makeStyles } from "@material-ui/core"
import InvoiceTypography from "./InvoiceTypography"

const useStyles = makeStyles(() => ({
  generalTable: {
    fontSize: "10pt",
    padding: "4px",
  },
  generalInformationTable: {
    marginTop: "1em",
    marginBottom: "1em",
    border: "2px solid black",
    padding: "4px",
  },
  descriptionTable: {
    borderCollapse: "collapse",
  },
  page: {
    descriptionTable: {
      "& tbody > *:last-child > *": {
        borderBottom: "0.3mm solid black",
      },
    },
  },
}))

const InvoiceItemPrint = forwardRef(
  (
    {
      companyInformation,
      data,
      productDescription,
      dnNumberUnique = [],
      poNumberUnique = [],
      // poDateUnique = [],
      withRefQuot = false,
      withPaymentPercentage = false,
    },
    ref
  ) => {
    const alignClasses = useAlignStyles()
    const classes = useStyles()
    const theadColumn = [
      { attr: "no", label: "No" },
      { attr: "description", label: "Description" },
      { attr: "quantity", label: "Quantity" },
      {
        attr: "unitPrice",
        label: "Unit Price",
        style: { width: "9rem", textAlign: "right" },
      },
      {
        attr: "total",
        label: "Total",
        style: { width: "9rem", textAlign: "right" },
      },
    ]

    const subTotal = Math.ceil(
      productDescription.reduce((a, b) => a + b.totalForCalculation, 0)
    )
    const totalStyle =
      data.discount || data.vat
        ? {}
        : {
            borderTop: "2px solid black",
          }

    const generalFields = [
      {
        label: <span>DN No</span>,
        value: (
          <strong>
            {dnNumberUnique.length > 0 ? (
              <ul style={{ listStyle: "none", marginInlineStart: 0 }}>
                {dnNumberUnique.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            ) : (
              "-"
            )}
          </strong>
        ),
      },
    ]
    if (withRefQuot) {
      generalFields.push({
        label: <span>Ref Quot</span>,
        value: (
          <strong>
            {poNumberUnique.length > 0 ? (
              <ul style={{ listStyle: "none", marginInlineStart: 0 }}>
                {poNumberUnique.map((item) => (
                  <li key={item.poNumber}>{item.slsQuotNumber}</li>
                ))}
              </ul>
            ) : (
              "-"
            )}
          </strong>
        ),
      })
    }

    const headerElement = (
      <>
        <HeaderPrint
          showAddress
          companyInformation={companyInformation}
          itemDetail={
            <>
              <table style={{ zIndex: 9999, marginBottom: "0.5em" }}>
                <tbody>
                  <tr>
                    <td>
                      <strong
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ marginRight: "4px" }}>
                          {[
                            "INVOICE",
                            "DOWN PAYMENT",
                            "INVOICE EXPORT",
                          ].includes(data?.invoiceType)
                            ? "Invoice"
                            : "Debit Note"}{" "}
                          No{" "}
                        </span>
                        :
                      </strong>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <strong>{data?.invoiceNumber}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Date</span>:
                      </strong>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <strong>{formatDate(data?.date)}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          }
        />
      </>
    )

    const bodyElement = (
      <>
        <InvoiceTypography
          type={
            ["INVOICE", "DOWN PAYMENT", "INVOICE EXPORT"].includes(
              data?.invoiceType
            )
              ? "INVOICE"
              : data?.invoiceType
          }
          // type="INVOICE"
        />

        <div
          style={{
            border: "1px solid black",
            marginTop: "1em",
            width: "72px",
            textAlign: "center",
          }}
        >
          <strong>Bill to :</strong>
        </div>

        <div
          className={alignClasses.row}
          style={{ justifyContent: "space-between" }}
        >
          <div
            style={{
              marginRight: "2em",
            }}
          >
            <div
              style={{
                flex: 3,
              }}
              className={classes.generalInformationTable}
            >
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    fontSize: "10pt",
                    top: "-12px",
                    backgroundColor: "white",
                    textAlign: "center",
                    width: "96px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Customer</span>
                </div>
                <table className={classes.generalTable}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Name</strong>
                      </td>
                      <td>
                        <strong>: {data?.customer?.name}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Address</strong>
                      </td>
                      <td>: {data?.address}</td>
                    </tr>
                    <tr>
                      <td>
                        {/* <strong>Phone</strong> */}
                        <strong>Attn</strong>
                      </td>
                      <td>
                        :{" "}
                        {data.invoiceType === "INVOICE EXPORT" ||
                        data.invoiceType === "DEBIT NOTE"
                          ? "Manager Accounting"
                          : "Manager Akuntansi & Keuangan"}
                        {/* : {data.phone}{" "} */}
                        {/* <span style={{ marginLeft: "1em" }}>
                          <strong>Attn</strong> : {data?.attn}
                        </span> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={classes.generalInformationTable}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <strong style={{ fontSize: "9pt" }}>PO No:</strong>
                  {poNumberUnique.length > 0
                    ? poNumberUnique.map((item) => (
                        <p
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                            fontSize: "9pt",
                          }}
                          key={item.poNumber}
                        >
                          {item.poNumber}
                        </p>
                      ))
                    : "-"}
                </div>
                <div>
                  <strong style={{ fontSize: "9pt" }}>PO Date:</strong>
                  {poNumberUnique.length > 0
                    ? poNumberUnique.map((item) => (
                        <p
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                            fontSize: "9pt",
                          }}
                          key={item.poNumber}
                        >
                          {formatDate(item.poDate)}
                        </p>
                      ))
                    : "-"}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ flex: 2, height: "100%" }}
            className={classes.generalInformationTable}
          >
            <GeneralTablePrint fields={generalFields} textAlign="right" />
          </div>
        </div>

        <table
          style={{ width: "100%", border: "2px solid black" }}
          className={clsx(
            classes.descriptionTable,
            "table-product-description"
          )}
        >
          <thead>
            <tr>
              {theadColumn.map((column) => {
                return (
                  <th style={{ fontSize: "10pt" }} key={column.label}>
                    {column.label}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {data?.payment && (
              <tr>
                {theadColumn.map((_, index) => {
                  let val = ""
                  if (index === 1) {
                    const percentage = data.paymentPercentage
                      ? `${data.paymentPercentage}%`
                      : ""
                    val = (
                      <strong style={{ textDecoration: "underline" }}>
                        {data.payment} {withPaymentPercentage && percentage}
                      </strong>
                    )
                  }
                  return <td key={index}>{val}</td>
                })}
              </tr>
            )}
            {data?.notes && (
              <tr>
                {theadColumn.map((_, index) => {
                  let val = ""
                  if (index === 1) {
                    val = (
                      <strong
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {data.notes}
                      </strong>
                    )
                  }
                  return <td key={index}>{val}</td>
                })}
              </tr>
            )}
            {productDescription.map((product) => {
              return (
                <React.Fragment key={product.no}>
                  {withRefQuot && (
                    <tr>
                      {theadColumn.map((_, index) => {
                        let val = ""
                        if (index === 1) {
                          val = <strong>{product.projectName}</strong>
                        }
                        return <td key={index}>{val}</td>
                      })}
                    </tr>
                  )}
                  <tr>
                    {theadColumn.map((column) => {
                      const addedStyles = column.style ? column.style : {}

                      return (
                        <td
                          style={{
                            fontSize: "10pt",
                            padding: "4px",
                            ...addedStyles,
                            verticalAlign: "top",
                          }}
                          key={column.attr}
                        >
                          {product[column.attr]}
                        </td>
                      )
                    })}
                  </tr>
                </React.Fragment>
              )
            })}
            <tr>
              <td />
              <td>
                {data.remarks && (
                  <>
                    <p style={{ marginTop: "1em" }}>Remarks:</p>
                    <p style={{ whiteSpace: "pre-line" }}>{data.remarks}</p>
                  </>
                )}
              </td>
              {theadColumn.map((column) => {
                if (column.attr !== "no" && column.attr !== "description")
                  return <td />
                return null
              })}
            </tr>
            {(data.discount || data.vat) && (
              <tr>
                <td
                  colSpan={theadColumn.length - 1}
                  style={{
                    padding: "4px",
                    textAlign: "right",
                    borderTop: "2px solid black",
                  }}
                  className="border-left-white"
                >
                  Sub Total
                </td>
                <td style={{ padding: "4px", borderTop: "2px solid black" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <strong>{data.currency.code}</strong>
                    <strong>
                      {new Intl.NumberFormat().format(Math.ceil(subTotal))}
                    </strong>
                  </div>
                </td>
              </tr>
            )}
            {data.discount && (
              <tr>
                <td
                  colSpan={theadColumn.length - 1}
                  style={{ padding: "4px", textAlign: "right" }}
                  className="border-left-white"
                >
                  Discount
                </td>
                <td style={{ padding: "4px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <strong>{data.currency.code}</strong>
                    <strong>
                      {new Intl.NumberFormat().format(data.discount)}
                    </strong>
                  </div>
                </td>
              </tr>
            )}
            {data.vat && (
              <tr>
                <td
                  colSpan={theadColumn.length - 1}
                  style={{ padding: "4px", textAlign: "right" }}
                  className="border-left-white"
                >
                  VAT {data.vat}%
                </td>
                <td style={{ padding: "4px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <strong>{data.currency.code}</strong>
                    <strong>
                      {new Intl.NumberFormat().format(
                        Math.floor(
                          (subTotal - data.discount) * (0 + data.vat / 100)
                        )
                      )}
                    </strong>
                  </div>
                </td>
              </tr>
            )}
            <tr>
              <td
                colSpan={theadColumn.length - 1}
                style={{ padding: "4px", textAlign: "right", ...totalStyle }}
                className="border-left-white border-bottom-white"
              >
                Total
              </td>
              <td style={{ padding: "4px", ...totalStyle }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>{data.currency.code}</strong>
                  <strong>
                    {new Intl.NumberFormat().format(
                      Math.floor(
                        (subTotal - (data?.discount ?? 0)) *
                          (1 + data.vat / 100)
                      )
                    )}
                  </strong>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          style={{
            width: "50%",
            border: "2px solid black",
            position: "relative",
            marginTop: "1.5em",
            paddingTop: "1em",
            paddingBottom: "4px",
            breakInside: "avoid",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              position: "absolute",
              left: "24px",
              top: "-16px",
              padding: "4px",
            }}
          >
            <strong>
              <p style={{ fontSize: "9pt", marginBottom: "0", marginTop: "0" }}>
                Payment Terms
              </p>
            </strong>
          </div>
          <strong style={{ marginLeft: "24px", fontSize: "9pt" }}>
            {data?.paymentTerms ?? ""}
          </strong>
        </div>

        <div
          className={alignClasses.row}
          style={{ marginTop: "1em", breakInside: "avoid" }}
        >
          <div
            className={clsx(
              alignClasses.columnLeftAlign,
              alignClasses.rowChildBasisMedium
            )}
          >
            <strong style={{ fontSize: "9pt" }}>Bank Transfers :</strong>
            <strong style={{ fontSize: "9pt" }}>
              A/N : PT. KYORAKU KANTO MOULD INDONESIA
            </strong>
            <strong style={{ fontSize: "9pt" }}>MUFG Bank, Ltd</strong>
            <strong style={{ fontSize: "9pt" }}>A/C : IDR 510-0150061</strong>
            <strong style={{ fontSize: "9pt" }}>
              <span style={{ color: "white" }}>A/C : </span>
              JPY 520-0882318
            </strong>
            <strong style={{ fontSize: "9pt" }}>
              <span style={{ color: "white" }}>A/C : </span>
              USD 530-0882304
            </strong>
          </div>
          <div
            className={clsx(alignClasses.rowChildBasisMedium)}
            style={{ textAlign: "center" }}
          >
            <p style={{ textAlign: "center", fontSize: "10pt" }}>
              PT. KYORAKU KANTO MOULD INDONESIA
            </p>
            <br />
            <br />
            <br />
            <br />
            <strong>
              <p
                style={{
                  textAlign: "center",
                  borderBottom: "2px solid black",
                  marginBottom: "0px",
                  fontSize: "9pt",
                }}
              >
                {data.authorizedSignature.name}
              </p>
              <p style={{ marginTop: 0, fontSize: "9pt" }}>
                Authorized Signature
              </p>
            </strong>
          </div>
        </div>
      </>
    )

    return (
      <div className={clsx("print-container")} ref={ref}>
        <table>
          {/* <thead> */}
          <tr>
            <th>{headerElement}</th>
          </tr>
          {/* </thead> */}
          <tbody>
            <tr>
              <td>{bodyElement}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
)

export default InvoiceItemPrint
