import React, { forwardRef, useMemo } from "react"
import TextField from "@material-ui/core/TextField"
import clsx from "clsx"
import { useTextFieldStyles } from "./InputLabel"

const StandardTextField = forwardRef((props, ref) => {
  const classes = useTextFieldStyles()

  const inpLabelProps = useMemo(
    () => ({
      ...props.InputLabelProps,
      classes: {
        shrink: classes.labelShrinked,
      },
    }),
    [props.InputLabelProps, classes.labelShrinked]
  )

  return (
    <TextField
      ref={ref}
      {...props}
      fullWidth={props.fullWidth === null ? true : props.fullWidth}
      className={clsx(props?.InputProps?.readOnly && "Mui-disabled")}
      InputLabelProps={inpLabelProps}
    />
  )
})

StandardTextField.displayName = "StandardTextField"

export default StandardTextField
