/* eslint-disable react/forbid-prop-types */

import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import React from "react"
import * as t from "prop-types"
import { Delete } from "@material-ui/icons"
import { numberFormat } from "utils/thousand-separator"
import { getDefaultSettings } from "./utils"

function ProductDNRow({
  product,
  index,
  register,
  errors,
  onConfirmDelete,
  // eslint-disable-next-line react/prop-types
  onChange,
  setValue,
  // eslint-disable-next-line react/prop-types
  watch,
}) {
  const quantity = +watch(`productDescriptions[${index}].quantity`)
  const unitPrice = +watch(`productDescriptions[${index}].unitPrice`)

  const amount = React.useMemo(() => {
    return quantity * unitPrice
  }, [quantity, unitPrice])

  return (
    <TableRow>
      <TableCell>
        <TextField
          defaultValue={product.description}
          variant="outlined"
          {...getDefaultSettings(
            `productDescriptions[${index}].description`,
            "",
            register,
            errors
          )}
          multiline
        />
      </TableCell>
      <TableCell>
        <TextField
          defaultValue={product.quantity}
          variant="outlined"
          {...getDefaultSettings(
            `productDescriptions[${index}].quantity`,
            "",
            register,
            errors
          )}
          onChange={(e) => {
            onChange(index, { quantity: e.target.value })
            setValue(`productDescriptions[${index}].quantity`, e.target.value)
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          defaultValue={product.unit}
          variant="outlined"
          {...getDefaultSettings(
            `productDescriptions[${index}].unit`,
            "",
            register,
            errors
          )}
        />
      </TableCell>
      <TableCell>
        <TextField
          defaultValue={product.unitPrice}
          variant="outlined"
          {...getDefaultSettings(
            `productDescriptions[${index}].unitPrice`,
            "",
            register,
            errors
          )}
          onChange={(e) => {
            onChange(index, { unitPrice: e.target.value })
            setValue(`productDescriptions[${index}].unitPrice`, e.target.value)
          }}
        />
      </TableCell>
      <TableCell>
        <Typography style={{ color: "black" }}>
          {numberFormat(amount)}
        </Typography>
      </TableCell>
      <TableCell>
        <TextField
          name={`productDescriptions[${index}].sequence`}
          defaultValue={product.sequence || index + 1}
          inputRef={register}
          margin="dense"
          fullWidth
          variant="outlined"
          type="number"
        />
      </TableCell>
      <TableCell>
        <IconButton size="small" onClick={() => onConfirmDelete(index)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

ProductDNRow.propTypes = {
  product: t.shape({
    description: t.string,
    unit: t.string,
    quantity: t.number,
    sequence: t.number,
    unitPrice: t.number,
    uom: t.string,
  }),
  index: t.number,
  register: t.any.isRequired,
  errors: t.object.isRequired,
  onConfirmDelete: t.func.isRequired,
  onPriceChange: t.func.isRequired,
  setValue: t.func.isRequired,
}

ProductDNRow.defaultProps = {
  product: {
    description: "",
    unit: "",
    quantity: 0,
    unitPrice: 0,
    uom: "",
  },
  index: 0,
}
export default ProductDNRow
