import { sortBy } from "lodash"
import { axios, ENDPOINT } from "./constants"
import {
  adjustDefaultParams,
  getQueryForAllAndCount,
  handleSilentApiError,
  newDictToStringParam,
} from "./utils"

const CUSTOMER_DETAIL_QUERY = `
slsTargetCustomer {
  custCode
  name
  address
}
`

const WO_TYPE_DETAIL_QUERY = `
idWorkOrderType
workOrderTypeName
workOrderTypeCode
`

const ESTIMATE_LIST_DETAIL_QUERY = `
  idWorkOrderEstimate
  mouldCode
  remark
  description
  dueDateFinish
  dueDateDelivery
  sequence
  salesEstimateId
  mouldCodeRef
  schedules {
    name
    date
  }
  salesEstimate {
    description
    discount
    slsPurchaseOrder {
      poNumber
    }
    reductionHistory
    quantity
    uom {
      name
    }
    totalAmount
    status
    discount
    estQuot {
      estimationNumber
      processCostHandling
      materialCostHandling
      additionalCostHandling
      outsourcingCostHandling
      processCosts {
        id
        time
        cost
        processId
        process {
          cost
        }
      }
      materialCosts {
        materialId
        dimension
        quantity      
        sequence
        unitPrice
        material {
          materialCode
          unitPrice
          dimension
          idMaterial
          materialType {
            density
            name
          }
        }
      }
      additionalCosts {
        quantity
        process
        cost
        handling
      }
      outsourcingCosts {
        quantity
        process
        cost
        handling
      }
      rfq {
        productName
        projectName
      }
      estimationNumber
    }
    slsQuot{
      slsQuotNumber
      poNumber
      currencyOut {
        idCurr
        code
        description
      }
    }
  }
  relatedBom {
    edges {
      node {
        idBom
      }
    }
  }
  salesQuotation {
    idSalesQuotation
    slsQuotNumber
    poNumber
    estimateList {
      slsQuot {
        slsQuotNumber
        idSalesQuotation
      }
      idSalesEstimate
      estQuotId
      description
      loc
      quantity
      unitOfMeasurement
      reductionHistory
      handling
      uom {
        idUom
        name
      }
      uomId
      estQuot {
        idEstimate
        estimationNumber
        processCostHandling
        materialCostHandling
        additionalCostHandling  
        rfq {
          rfqNumber
          productName
        }  
        materialCosts {
          materialId
          dimension
          quantity      
          sequence
          unitPrice
        }
        processCosts {
          processId
          time
        }
        additionalCosts {
          cost
          quantity
        }
      }
    }
  }
`

const ITEM_DETAIL_QUERY = `
  idWorkOrder
  woNumber
  madeBy
  approvedBy
  acknowledgeBy
  status
  salesQuotationId
  woDate
  workOrderTypeId
  projectName
  workOrderType {
    ${WO_TYPE_DETAIL_QUERY}
  }
  customer {
    name
    address 
    pic
    phoneNumber
    faxNumber
    destinationList {
      idCustomerDestination
      destination
    }
  }
  progress
  customerId
  salesQuotation {
    ${CUSTOMER_DETAIL_QUERY}
    slsQuotNumber
    slsQuotName
    poNumber
    slsQuotDate
    customerId
  }
  estimateList {
    ${ESTIMATE_LIST_DETAIL_QUERY}
    warrantyStatus {
      status
      remark
    }
  }
  customer {
    custCode
    name
    address
  }
`

export default class ApiWorkOrder {
  static async getWorkOrderType(inpParams = {}) {
    const params = adjustDefaultParams(inpParams)
    try {
      let query = `
        query {
          allWorkOrderTypeImpl {
            ${WO_TYPE_DETAIL_QUERY}
          }
        }
      `
      query = getQueryForAllAndCount(query, params)
      const response = await axios.post(ENDPOINT.QUERY, { query })
      handleSilentApiError(response)
      // const val = getDataAndCountFromResponse(response)
      const val = { data: response.data.data.allWorkOrderTypeImpl }
      return val
    } catch (e) {
      console.log("[API-WORK-ORDER]")
      console.log(e)
      return null
    }
  }

  static async export(inpParams = {}) {
    const payload = newDictToStringParam(inpParams)
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        exportWorkOrderImpl(${payload}) {
          exportWorkOrder
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.exportWorkOrderImpl.exportWorkOrder,
    }
  }

  static async getAllMoldCode(inpParams = {}) {
    const params = newDictToStringParam(inpParams)
    const query = `
      query {
        allWorkOrderEstimateImpl (${params}) {
          mouldCode
        }
      }`
    const response = await axios.post(ENDPOINT.QUERY, { query })
    handleSilentApiError(response)
    return {
      data: response.data.data.allWorkOrderEstimateImpl,
    }
  }

  static async get(inpParams = {}) {
    try {
      const params = adjustDefaultParams(inpParams)
      let query = `
        query {
          allWorkOrderImpl {
            ${ITEM_DETAIL_QUERY}
            isWarrantyClosed
          }
        }`
      query = getQueryForAllAndCount(query, params)
      const response = await axios.post(ENDPOINT.QUERY, { query })
      handleSilentApiError(response)
      // const val = getDataAndCountFromResponse(response)
      const val = { data: response.data.data.allWorkOrderImpl }
      return val
    } catch (e) {
      return null
    }
  }

  static async getList(inpParams = {}) {
    try {
      const params = adjustDefaultParams(inpParams)
      let query = `
        query {
          allWorkOrderImpl {
            idWorkOrder
            woNumber
            workOrderType {
              workOrderTypeName
            }
            customer {
              name
            }
            estimateList {
              description
              mouldCode
              salesEstimate {
                slsQuot {
                  slsQuotNumber
                }
                estQuot {
                  estimationNumber
                }
                slsPurchaseOrder {
                  poNumber
                }
              }
            }
          }
        }`
      query = getQueryForAllAndCount(query, params)
      const response = await axios.post(ENDPOINT.QUERY, { query })
      handleSilentApiError(response)
      // const val = getDataAndCountFromResponse(response)
      const val = { data: response.data.data.allWorkOrderImpl }
      return val
    } catch (e) {
      return null
    }
  }

  static async updateStatus(idWorkOrder, payload) {
    const payloadAsParam = newDictToStringParam({
      status: payload,
      idWorkOrder: parseInt(idWorkOrder, 10),
    })

    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateStatusWorkOrderImpl (${payloadAsParam}) {
          workOrderImpl {
            progress
          }
        }
      }`,
    })

    handleSilentApiError(response)
    return {
      data: response.data.data.updateStatusWorkOrderImpl.workOrderImpl.progress,
    }
  }

  static async getWorkOrderApprover() {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allWorkOrderApproverImpl {
          idApprover
          name
          departmentName
        }
      }
      `,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.allWorkOrderApproverImpl,
    }
  }

  static async getForIssue(inpParams = {}) {
    try {
      const params = adjustDefaultParams(inpParams)
      let query = `
      query {
        allWorkOrderImpl {
          ${ITEM_DETAIL_QUERY}
          bomList {
            projectCode
          }
        }
      }
    `
      query = getQueryForAllAndCount(query, params)
      const response = await axios.post(ENDPOINT.QUERY, { query })
      handleSilentApiError(response)
      // const val = getDataAndCountFromResponse(response)
      const val = {
        data: response.data.data.allWorkOrderImpl.filter(
          (item) => item.bomList.length > 0
        ),
      }
      return val
    } catch (e) {
      console.log("[API-WORK-ORDER]")
      console.log(e)
      return null
    }
  }

  static async create(payload) {
    const payloadAsParams = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
        mutation {
          saveWorkOrderImpl(${payloadAsParams}) {
            workOrderImpl {
              idWorkOrder
            }
          }
        }
      `,
    })
    handleSilentApiError(response)
    return {
      data: response.data.data.saveWorkOrderImpl.workOrderImpl,
    }
  }

  static async getItem(id) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
        query {
          getWorkOrderImpl (id: ${id}){
            ${ITEM_DETAIL_QUERY}
          }
        }
      `,
    })
    handleSilentApiError(response)

    const data = response.data.data.getWorkOrderImpl
    const estimateList = sortBy(data.estimateList, [
      (o) => {
        return o.sequence
      },
    ])
    return {
      data: {
        ...data,
        estimateList,
      },
    }
  }

  static async updatePostedWorkOrder(id, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idWorkOrder: parseInt(id, 10),
    })
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
        mutation {
          updatePostedWorkOrderImpl(${payloadAsParam}) {
            workOrderImpl {
              ${ITEM_DETAIL_QUERY}
            }
          }
        }
      `,
    })

    handleSilentApiError(response)
    const data = response.data.data.updatePostedWorkOrderImpl.workOrderImpl
    const estimateList = sortBy(data.estimateList, [
      (o) => {
        return o.sequence
      },
    ])
    return {
      data: {
        ...data,
        estimateList,
      },
    }
  }

  static async updateWorkOrder(id, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idWorkOrder: parseInt(id, 10),
    })
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
        mutation {
          updateWorkOrderImpl (${payloadAsParam}) {
            workOrderImpl {
              ${ITEM_DETAIL_QUERY}
            }
          }
        }
      `,
    })
    handleSilentApiError(response)

    const data = response.data.data.updateWorkOrderImpl.workOrderImpl
    const estimateList = sortBy(data.estimateList, [
      (o) => {
        return o.sequence
      },
    ])
    return {
      data: {
        ...data,
        estimateList,
      },
    }
  }

  static async getWorkOrderEstimateSchedule(payload = {}) {
    const params = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
        query {
          allWorkOrderDeliveryScheduleImpl (${params}) {
            dueDateDelivery
            mouldCode
            description
            deliveryRemainingDay
            workOrder {
              customer{
                name
              }
            }
          }
        }
      `,
    })

    let data = response.data.data.allWorkOrderDeliveryScheduleImpl

    data = sortBy(data, ["deliveryRemainingDay"])

    return {
      data,
    }
  }

  static async getRecentWorkOrder(payload) {
    const params = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allWorkOrderImpl(${params}) {
          idWorkOrder
          woNumber
          woDate
          estimateList{
            description
            mouldCode
            schedules {
              date
              name
            }
          }
        }
      }`,
    })
    handleSilentApiError(response)
    const arr = []

    response.data.data.allWorkOrderImpl.forEach((wo) => {
      const { estimateList } = wo
      estimateList.forEach((estList) => {
        arr.push({
          ...estList,
          ...wo,
        })
      })
    })
    return {
      data: arr,
    }
  }

  static async getForWorkOrderDistribution(inpParams = {}) {
    try {
      const params = adjustDefaultParams(inpParams)
      let query = `
        query {
          allWorkOrderImpl {
            idWorkOrder
            woNumber
            estimateList {
              description
              mouldCode
            }
          }
        }`
      query = getQueryForAllAndCount(query, params)
      const response = await axios.post(ENDPOINT.QUERY, { query })
      handleSilentApiError(response)
      // const val = getDataAndCountFromResponse(response)
      const val = { data: response.data.data.allWorkOrderImpl }
      return val
    } catch (e) {
      console.log("[API-WORK-ORDER]")
      console.log(e)
      return null
    }
  }

  static async closeWorkOrderEstimateWarranty(id, remark) {
    const payload = newDictToStringParam({
      workOrderEstimateId: parseInt(id, 10),
      remark,
    })

    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        closeWorkOrderEstimateWarrantyStatusImpl(${payload}){
          workOrderEstimateWarrantyStatusImpl {
            status
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data:
        response.data.data.closeWorkOrderEstimateWarrantyStatusImpl
          .workOrderEstimateWarrantyStatusImpl,
    }
  }
}
