import { useRef, useCallback } from "react"
import useSendAndHandleInvalidToken from "./useSendAndHandleInvalidToken"

/**
 * Wrap the callback and catch for any errors caused by invalid or expired token.
 * If there are such errors, the app will display login notice and the program
 * using the callback will retrieve the defaultValue provided by
 * `defaultValueFunction`.
 * The callback won't throw any errors and instead returns value provided by
 * defaltValueFunction.
 *
 * @param {*} callback Callback to be wrapped
 * @param {*} defaultValueFunction Function to get default value
 */

const useWrapHandleInvalidToken = (callback, defaultValueFunction) => {
  const getDefaultValueRef = useRef(defaultValueFunction)
  getDefaultValueRef.current = defaultValueFunction

  const sendAndHandleInvalidToken = useSendAndHandleInvalidToken()

  const wrappedCallback = useCallback(
    async (...args) => {
      const wrapperResponse = await sendAndHandleInvalidToken(() =>
        callback(...args)
      )
      if (wrapperResponse.success) {
        return wrapperResponse.response
      }
      if (typeof getDefaultValueRef.current === "function") {
        return getDefaultValueRef.current()
      }
      return getDefaultValueRef.current
    },
    [callback, sendAndHandleInvalidToken]
  )

  return wrappedCallback
}

export default useWrapHandleInvalidToken
