import { combineReducers } from "redux"

import userReducer from "./user/reducer"
import loadingReducer from "./loading/reducer"
import snackbarReducer from "./snackbar/reducer"
import quotationFormReducer from "./quotation-form/reducers"
import dialogLogoutReducer from "./dialog-logout/reducer"
import breadcrumbReducer from "./breadcrumb/reducer"

export default combineReducers({
  userReducer,
  loadingReducer,
  snackbarReducer,
  quotationFormReducer,
  dialogLogoutReducer,
  breadcrumbReducer,
})
