import { makeStyles } from "@material-ui/core"

export const useBaseFormStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
    flexGrow: 1,
  },
  fieldsWrapper: {
    "& > *": {
      display: "block",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      marginLeft: "10px",
      display: "block",
    },
  },
  formSectionTitle: {
    color: "#0449b0",
    fontSize: theme.typography.h6.fontSize,
    marginTop: "1rem",
  },
  salesFieldContainer: {
    display: "flex",
    flexFlow: "row",
    maxWidth: "450px",
    alignItems: "center",
  },
  salesField: {
    flexGrow: 1,
    marginRight: "10px",
  },
  assignSalesButton: {
    borderColor: "#673AB7",
  },
  errorLabel: {
    lineHeight: 1.2,
  },
  inputCurrencyValue: {
    marginTop: theme.spacing(1.5),
  },
}))

export const useTableEstimationStyles = makeStyles({
  tableContainer: {
    marginBottom: "12px",
  },
  table: {
    "& td, & th": {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
  input: {
    padding: "0.6rem 0.6rem",
  },
  addButton: {
    backgroundColor: "#673AB7",
    color: "white",
    width: "50px",
    display: "flex",
    alignItems: "center",
    borderRadius: 0,
    "&:disabled": {
      backgroundColor: "gray",
      color: "#b3b3b3",
    },
    "&:hover:not(:disabled)": {
      backgroundColor: "#8e55f3",
    },
  },
  addItemCell: {
    padding: "0 !important",
  },
  addItemContainer: {
    display: "flex",
    width: "100%",
  },
  estimationDropdown: {
    minWidth: "300px",
    maxWidth: "300px",
    padding: "8px",
  },
  loadingCell: {
    textAlign: "center",
    padding: "24px",
  },
})

export const GENERAL_BLANK_FORM = {
  slsQuotName: "",
  slsQuotNumber: "",
  customerId: null, // FK to customer ID
  slsQuotDate: "",
  destinationCustomerId: null, // FK to customer ID
  paymentMethod: null,
  reference: "-",
  expShipDate: "",
  trialDate: "",
  leadTime: null,
  approvedBy: "",
  acknowledgedBy: "Shigeki Tashiro",
  checkedBy: "Andrian",
  // eslint-disable-next-line
  comment: null,
  notes: `1. Not Included 3D data Mold
2. Price Not Include Cost of Study & Modeling part, If there is work, we will revise the quotation or make a separate offer
3. Not Included Modification/Change
4. Fix data Received and mayor change, the quotation will be revised if there is a significant change such as the material dimension, will be re estimate.
5. Quotation Validaty : 30 Days From Quotation Date
6. Payment Schedule : Down Payment 50% After PO, Final Payment 50% After Mold Delivery 
7. TOP : 30 Days after invoice received 
8. The Price Not Included Shibo (Etching) & Sandblast
9. Not Included Trial Cost
10. The price attached does not include checking product dimensions, If there is a need to check product dimensions there will be additional costs`,
  top: "",
  warranty: false,
  revise: false,
  poRef: "",
  slsQuotMaster: null,
  slsQuotMasterId: null,
  destinationCustomer: null,
  slsTargetCustomer: null,
}

export const ESTIMATE_ADDITIONAL_BLANK_FORM = {
  additionalCosts:
    [
      {
        id: 0,
        process: "",
        quantity: 0,
        cost: 0,
      },
    ] && [],
  additionalCostMargin: 0,
}

export const ESTIMATE_BLANK_FORM = {
  estimateList:
    [
      {
        estQuotId: 0,
        description: "",
        loc: "",
        quantity: 0,
        unitOfMeasurements: "",
        slsPurchaseOrders: null,
      },
    ] && [],
  margin: 0,
  discount: 0,
  tax: 0,
  sales: null,
}

export const STATUS_BLANK_FORM = {
  status: "enter",
  poDate: "",
  poNumber: "",
  woNumber: "",
  slsPurchaseOrders: [{ poNumber: "", poDate: "" }] && [],
  woDate: "",
  design: false,
  machining: false,
  assembly: false,
  finishing: false,
}

export const CURRENCY_BLANK_FORM = {
  rateInId: null, // FK to currency.idCurr
  rateOutId: null, // FK to currency.idCurr
  rateInValue: 1,
  rateOutValue: 1,
  rateDate: "",
  adjustRate: 1,
}

export const SALES_BLANK_FORM = {
  ...GENERAL_BLANK_FORM,
  ...STATUS_BLANK_FORM,
  ...CURRENCY_BLANK_FORM,
  ...ESTIMATE_BLANK_FORM,
  ...ESTIMATE_ADDITIONAL_BLANK_FORM,
}

export const MARGINS = [5, 10, 15, 20, 25, 30]

export const FORM_MODE_CREATE = 0
export const FORM_MODE_UPDATE = 1
export const FORM_MODE_UPDATE_WORK_ORDER = 2
export const FORM_MODE_READ_ONLY = 3
