import { makeStyles } from "@material-ui/core"

export const useBasePageStyles = makeStyles({
  createButton: {
    margin: "16px",
    marginRight: 0,
    marginLeft: "auto",
    display: "block",
  },
  alignRightHeader: {
    "&": `
    text-align: right;
    `,
    "& > *": `
      flex-direction: inherit;
    `,
  },
})
