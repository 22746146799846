import {
  Button,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import { Add } from "@material-ui/icons"
import React, { useMemo } from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import * as t from "prop-types"
import { yupResolver } from "@hookform/resolvers"
import { numberFormat } from "utils/thousand-separator"
import { getDefaultSettings } from "./utils"

const schema = yup.object({
  description: yup.string().required(),
  unitPrice: yup.number().required(),
  unit: yup.string().required(),
  quantity: yup.number().typeError("This field is required").required(),
})

function ProductDNTableAddForm({ onAddButtonClick }) {
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const quantity = +watch("quantity")
  const unitPrice = +watch("unitPrice")

  const amount = useMemo(() => {
    return quantity * unitPrice
  }, [quantity, unitPrice])
  const reset = () => {
    setValue("description", "")
    setValue("unitPrice", "")
    setValue("unit", "")
    setValue("quantity", 0)
  }
  const handleAddButtonClick = async () => {
    try {
      await new Promise((res, rej) => handleSubmit(res, rej)())
    } catch (error) {
      console.log(error)
      return
    }
    const newItemData = getValues()
    onAddButtonClick({ ...newItemData })
    reset()
  }

  return (
    <TableRow>
      <TableCell>
        <TextField
          multiline
          {...getDefaultSettings(
            "description",
            "Description",
            register,
            errors
          )}
        />
      </TableCell>
      <TableCell>
        <TextField
          {...getDefaultSettings("quantity", "Quantity", register, errors)}
          type="number"
        />
      </TableCell>
      <TableCell>
        <TextField {...getDefaultSettings("unit", "UoM", register, errors)} />
      </TableCell>
      <TableCell>
        <TextField
          {...getDefaultSettings("unitPrice", "Unit Price", register, errors)}
          type="number"
        />
      </TableCell>
      <TableCell>
        <Typography style={{ color: "black" }}>
          {numberFormat(amount)}
        </Typography>
      </TableCell>
      <TableCell>
        <Button
          onClick={handleAddButtonClick}
          color="primary"
          variant="contained"
        >
          <Add />
        </Button>
      </TableCell>
    </TableRow>
  )
}

ProductDNTableAddForm.propTypes = {
  onAddButtonClick: t.func.isRequired,
}

export default ProductDNTableAddForm
