import { createMuiTheme } from "@material-ui/core/styles"

const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Nunito Sans",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#033278",
      light: "#3f6bb5",
      dark: "#3f6bb5",
    },
    sectionTitle: {
      main: "#0449b0",
      light: "#0449b0",
      dark: "#34c0ff",
    },
  },
  formSectionTitle: {
    color: "#0449b0",
    marginTop: "1rem",
  },
})

export default defaultTheme
