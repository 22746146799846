import { ENDPOINT, axios } from "./constants"

export default class ApiAuthorizedSignature {
  static async get() {
    const query = `
        query {
            allAuthorizedSignatureImpl {
                idAuthorizedSignature
                name
                position
            }
        }`

    const response = await axios.post(ENDPOINT.QUERY, { query })

    return { data: response.data.data.allAuthorizedSignatureImpl }
  }
}
