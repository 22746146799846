import PropTypes from "prop-types"
import React from "react"
import NotInterestedIcon from "@material-ui/icons/NotInterested"
import { Box, makeStyles, Button } from "@material-ui/core"
import { Link } from "react-router-dom"

const useStyles = makeStyles({
  container: `
    min-height: 30rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  `,
  icon: `
    font-size: 8rem;
    margin-bottom: 1rem;
    color: #929292;
  `,
  notice: `
    color: #929292;
    margin-bottom: 1rem;
  `,
})

const NotAllowedNotice = ({ bottomComponent }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container} p={2}>
      <NotInterestedIcon className={classes.icon} />
      <div className={classes.notice}>
        You are not allowed to access this page.
      </div>
      {bottomComponent || (
        <Button
          variant="contained"
          disableElevation
          color="primary"
          component={Link}
          to="/"
        >
          Back to Home
        </Button>
      )}
    </Box>
  )
}

NotAllowedNotice.propTypes = {
  bottomComponent: PropTypes.node,
}

NotAllowedNotice.defaultProps = {
  bottomComponent: null,
}

export default NotAllowedNotice
