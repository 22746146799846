const initialState = {
  isLoading: false,
}

export default function loadingReducer(state, action) {
  if (typeof state === "undefined") {
    return initialState
  }

  const { type } = action
  switch (type) {
    case "SET_IS_LOADING":
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}
