/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import BaseDialog from "components/common/BaseDialog"
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
} from "@material-ui/core"
import ApiWorkOrder from "services/api-work-order"

export default function EntriesDialog({
  open,
  onCancel,
  onConfirm,
  defaultApprover = [{ deptName: "", pic: "" }],
}) {
  const [items, setItems] = useState(defaultApprover)

  useEffect(() => {
    let active = true

    const fetchData = async () => {
      const response = await ApiWorkOrder.getWorkOrderApprover()
      if (!active) return
      setItems(
        response.data.map((c) => ({ pic: c.name, deptName: c.departmentName }))
      )
    }
    fetchData()
    return () => {
      active = false
    }
  }, [])

  const handleClose = ({ confirmed }) => {
    if (confirmed) {
      onConfirm(items)
    } else {
      onCancel()
    }
  }

  const deleteItem = (index) => {
    const newItems = [...items]
    newItems.splice(index, 1)
    setItems(newItems)
  }

  const addItem = () => {
    setItems((paramsItems) => [...paramsItems, { deptName: "", pic: "" }])
  }

  const setAttr = (e, index) => {
    const updatedItems = [...items]
    const updatedItem = { ...items[index], [e.target.name]: e.target.value }
    updatedItems[index] = updatedItem
    setItems(updatedItems)
  }

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      dialogTitle="Input Approvers"
      contentComponent={
        <>
          Input the division heads for print work order
          <TableContainer>
            <Table size="small">
              <TableBody>
                {items.map((item, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <TextField
                          name="deptName"
                          label="Departement Name"
                          value={item.deptName}
                          onChange={(e) => setAttr(e, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="pic"
                          label="PIC"
                          value={item.pic}
                          onChange={(e) => setAttr(e, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={items.length <= 1}
                          onClick={() => deleteItem(index)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <TableCell colSpan={4}>
                    <Button onClick={addItem}>Add entry</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    />
  )
}
