import React from "react"
import BaseDialog from "./BaseDialog"

const ChangeStatusDialog = ({ contentComponent, open, ...otherProps }) => {
  return (
    <BaseDialog
      {...otherProps}
      open={open}
      dialogTitle="Change Status?"
      contentComponent={contentComponent ?? "Change this item's status?"}
      confirmButtonTitle="Yes"
      cancelButtonTitle="No"
      confirmButtonProps={{
        color: "primary",
        variant: "contained",
      }}
      cancelButtonProps={{
        color: "primary",
        variant: "outlined",
      }}
    />
  )
}

export default ChangeStatusDialog
