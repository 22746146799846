import resolvePermissionStatus from "utils/permission/resolvePermissionStatus"
import PERMISSIONS from "constants/permissions"
import {
  START_INIT_VALIDATION,
  FINISH_INIT_VALIDATION,
  FINISH_INIT_UPDATE_TOKEN,
  FINISH_INIT_CLEAR_TOKEN,
  SET_USER_DATA,
  SET_TOKEN,
  CLEAR_ALL_DATA,
} from "./actions"

const endpointsSetFromRoles = (roles) => {
  if (!Array.isArray(roles) || roles.length < 1) {
    return new Set()
  }
  const endpointsSet = new Set()
  roles.forEach((role) => {
    role.endpoints.forEach((roleEndpoint) => {
      endpointsSet.add(roleEndpoint.backendName)
    })
  })
  return endpointsSet
}

const initialState = {
  accessToken: "",
  userData: null,
  initialized: false,
  validating: false,
  allowedEndpoints: endpointsSetFromRoles([]),
  resolvedPermissions: {},
}

export default function userReducer(state, action) {
  if (typeof state === "undefined") {
    return initialState
  }

  const { type, payload } = action
  switch (type) {
    case START_INIT_VALIDATION:
      return { ...state, validating: true }
    case FINISH_INIT_VALIDATION:
      return { ...state, validating: false }

    case SET_USER_DATA: {
      const endpointsSet = endpointsSetFromRoles(payload.roles)
      const endpoints = [...endpointsSet]
      return {
        ...state,
        userData: payload,
        allowedEndpoints: endpointsSet,
        resolvedPermissions: resolvePermissionStatus(PERMISSIONS, endpoints),
      }
    }

    case SET_TOKEN:
      return { ...state, accessToken: payload }
    case CLEAR_ALL_DATA:
      return {
        ...state,
        validating: false,
        accessToken: "",
        userData: null,
        allowedEndpoints: endpointsSetFromRoles([]),
        resolvedPermissions: {},
      }

    case FINISH_INIT_UPDATE_TOKEN:
      return {
        ...state,
        initialized: true,
        validating: false,
        accessToken: payload,
      }
    case FINISH_INIT_CLEAR_TOKEN:
      return {
        ...state,
        initialized: true,
        validating: false,
        accessToken: "",
        userData: null,
        allowedEndpoints: endpointsSetFromRoles([]),
      }

    default:
      return state
  }
}
