import { SET_QUOTATION_FORM_CUSTOMER } from "./actions";

const initialState = {
  customer: null
}

const formReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case SET_QUOTATION_FORM_CUSTOMER: {
      return { customer: action.payload }
    }
    default: {
      return state
    }
  }
}

export default formReducer