/* eslint-disable react/prop-types */
import {
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core"
import { Print } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import React, { useEffect, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import ApiWorkOrder from "services/api-work-order"
import ExportButton from "components/common/ExportButton"
import WOReportPrint from "./WOReportPrint"

function WorkOrderFilter({
  fetchWoTypeData,
  classes = {},
  onWorkOrderTypeChange = () => {},
  onDateChange = () => {},
}) {
  const [workOrderTypes, setWorkOrderTypes] = useState([])
  const [selectedWorkOrderTypes, setSelectedWorkOrderTypes] = useState(null)
  const [workOrderList, setWorkOrderList] = useState([])
  const [loading, setLoading] = useState(false)
  const [fromDate, setFromDate] = useState("")
  const [untilDate, setUntilDate] = useState("")

  useEffect(() => {
    let active = true

    const fetchData = async () => {
      const response = await fetchWoTypeData()

      if (!active) return

      setWorkOrderTypes(response.data)
    }
    fetchData()
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (onDateChange) {
      onDateChange({ fromDate, untilDate })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, untilDate])

  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const fetchWorkOrder = () => {
    let active = true

    const fetchData = async () => {
      setLoading(true)
      const params = {}
      if (selectedWorkOrderTypes) {
        params.workOrderTypeId = parseInt(
          selectedWorkOrderTypes.idWorkOrderType,
          10
        )
      }
      if (fromDate) {
        params.fromDate = fromDate
      }
      if (untilDate) {
        params.untilDate = untilDate
      }
      const response = await ApiWorkOrder.get(params)

      if (!active) return
      setLoading(false)
      setWorkOrderList(response.data)
      setTimeout(() => {
        handlePrint()
      }, 500)
      console.log(response)
    }

    fetchData()

    return () => {
      active = false
    }
  }

  return (
    <div>
      <Typography className={classes.filterHeaderTitle} variant="body1">
        Filter
      </Typography>
      <div className={classes.filterBox}>
        <div className={classes.filterPickerContainer}>
          <Autocomplete
            id="wo-type-picker"
            options={workOrderTypes}
            value={selectedWorkOrderTypes}
            className={classes.materialAutocomplete}
            onChange={(e, val) => {
              setSelectedWorkOrderTypes(val)
              onWorkOrderTypeChange(val)
            }}
            getOptionLabel={(opt) => opt.workOrderTypeName}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="dense"
                  label="Work Order Type"
                />
              )
            }}
          />

          <TextField
            type="date"
            margin="dense"
            label="From Date"
            style={{ marginRight: "8px" }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />

          <TextField
            type="date"
            margin="dense"
            label="To Date"
            style={{ marginRight: "8px" }}
            variant="outlined"
            value={untilDate}
            onChange={(e) => setUntilDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />

          <IconButton onClick={fetchWorkOrder} color="primary">
            {loading ? <CircularProgress /> : <Print />}
          </IconButton>

          <ExportButton
            fetchData={ApiWorkOrder.export}
            filename="work-order.xlsx"
            getFormValues={() => {
              const params = {}
              if (selectedWorkOrderTypes)
                params.workOrderTypeId = +selectedWorkOrderTypes.idWorkOrderType

              if (fromDate) params.startDate = fromDate
              if (untilDate) params.endDate = untilDate
              return params
            }}
            text="Export"
          />
        </div>
      </div>

      <WOReportPrint
        workOrders={workOrderList}
        workOrderType={selectedWorkOrderTypes}
        ref={componentRef}
      />
    </div>
  )
}

export default WorkOrderFilter
