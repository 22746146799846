import { orderBy, sortBy } from "lodash"
import { axios, ENDPOINT } from "./constants"
import {
  handleSilentApiError,
  newDictToStringParam,
  adjustDefaultParams,
  getQueryForAllAndCount,
  getDataAndCountFromResponse,
} from "./utils"

const ITEM_DETAIL_QUERY = `
  idBom
  status
  bomNumber
  salesQuotationId
  projectName
  partNumber
  lastUpdated
  productTypeId
  productType {
    productType
    idProductType
  }
  maker3d
  projectUnit
  projectCode
  preparedBy
  checkedBy
  approvedBy
  comment
  issueDate
  workOrderEstId
  billOfMaterialRelation {
    idBomRelation
    bomId
    materialId
    dimension
    requiredQuantity
    reservedQuantity
    remark
    vendor
    category
    partGroup
    isAdditional
    isRequested
    sequence
    status
    partCode
    totalIssue
    uom {
      idUom
      name
    }
    goodIssue {
      edges {
        node {
          giNumber
        }
      }
    }
    material {
      idMaterial
      availableStock
      unitPrice
      dimension
      materialCode
      description
      materialType {
        name
        inventoryType {
          name
        }
      }
    }
    tolerance
    champer
    process
  }
  salesQuotation {
    idSalesQuotation
    woNumber
    slsQuotNumber
    mouldNumber
    workOrder {
      woNumber
    }
  }
  workOrderEst {
    description
    workOrder {
      woNumber
      estimateList {
        idWorkOrderEstimate
        mouldCode
        remark
        dueDateFinish
        dueDateDelivery
        salesEstimateId
        salesEstimate {
          description
          estQuot {
            rfq {
              productName
              productNo
              projectName
              productType {
                idProductType
                productType
                code
              }
            }
          }
        }
      }
    }
    mouldCode
    salesEstimate {
      description
      estQuot {
        rfq {
          productName
          projectName
        }
      }
    }    
  }
`

const ITEM_PROJECT_DETAIL_QUERY = `
  idBom
  status
  projectStatus
  bomNumber
  salesQuotationId
  projectName
  partNumber
  productTypeId
  productType {
    productType
    idProductType
  }
  projectUnit
  projectCode
  preparedBy
  checkedBy
  approvedBy
  comment
  issueDate
  billOfMaterialRelation {
    idBomRelation
    bomId
    materialId
    dimension
    requiredQuantity
    reservedQuantity
    category
    goodIssue {
      edges {
        node {
          giNumber
        }
      }
    }
    material {
      idMaterial
      description
      materialCode
      realStock
      availableStock
    }
  }
  salesQuotation {
    idSalesQuotation
    woNumber
    slsQuotNumber
    slsTargetCustomer {
      name
    }
    workOrder {
      woNumber
    }
  }
`

export default class ApiBillOfMaterial {
  static async get(inpParams = {}) {
    const params = adjustDefaultParams(inpParams)
    let query = `
    query {
      allBillOfMaterialImpl {
        ${ITEM_DETAIL_QUERY}
      }
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    return getDataAndCountFromResponse(response)
  }

  static async getNeedPurchaseBom() {
    const params = newDictToStringParam({
      needPurchase: true,
    })

    const query = `
    query {
      allBillOfMaterialImpl (${params}) {
        idBom
      }
    }`

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    return {
      data: response.data.data.allBillOfMaterialImpl,
    }
  }

  static async getForWTR(inpParams = {}) {
    const params = newDictToStringParam({
      ...inpParams,
      projectStatuses: ["enter", "ongoing"],
    })
    const query = `
    query {
      allBillOfMaterialImpl(${params}) {
        idBom
        projectStatus
        projectCode
        salesQuotation {
          mouldNumber
        }
        workOrderEst {
          workOrder {
            woNumber
          }
          mouldCode
          salesEstimate {
            estQuot {
              rfq {
                productName
              }
            }
          }    
        }
        billOfMaterialRelation {
          category
          vendor
          idBomRelation
          partCode
        }
      }
    }`
    const response = await axios.post(ENDPOINT.QUERY, { query })
    handleSilentApiError(response)
    return {
      data: response.data.data.allBillOfMaterialImpl,
    }
  }

  static async getForPurchaseOrder(inpParams = {}) {
    const params = newDictToStringParam(inpParams)
    const query = `
    query {
      allBillOfMaterialImpl (${params}) {
        projectCode
        partNumber
        idBom
      }
    }`

    const response = await axios.post(ENDPOINT.QUERY, { query })
    handleSilentApiError(response)
    return {
      data: response.data.data.allBillOfMaterialImpl,
    }
  }

  static async getBillOfMaterialList(inpParams = {}) {
    const params = adjustDefaultParams(inpParams)
    let query = `
    query {
      allBillOfMaterialImpl {
        idBom
        issueDate
        bomNumber
        projectName
        lastUpdated
        status
        issueStatus
        purchaseStatus
        workOrderEst {
          mouldCode
          workOrder {
            woNumber
          }
          salesEstimate {
            description
            estQuot {
              rfq {
                productName
                productNo
                projectName
                productType {
                  idProductType
                  productType
                  code
                }
              }
            }
          }
        }
      }
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    return getDataAndCountFromResponse(response)
  }

  static async getPostedBillOfMaterial(inpParams = {}) {
    const params = adjustDefaultParams(inpParams)
    let query = `
    query {
      allBillOfMaterialImpl {
        idBom
        projectName
        workOrderEst {
          mouldCode
          workOrder {
            woNumber
          }
        }
      }
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    return getDataAndCountFromResponse(response)
  }

  static async getPostedBillOfMaterialForProjects(inpParams = {}) {
    const params = adjustDefaultParams({ ...inpParams })
    let query = `
    query {
      allBillOfMaterialImpl {
        idBom 
        projectName 
        projectStatus
        projectCode
      }
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    return getDataAndCountFromResponse(response)
  }

  static async getBillOfMaterialCostSummary(params = {}) {
    const payload = newDictToStringParam(params)
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `query {
        allSummaryBillOfMaterialCostImpl${payload ? `(${payload})` : ""} {
          unitPrice
          bomNumber
          idBom
          materialGroupName
          mouldCode
        }
      }`,
    })

    return {
      data: response.data.data.allSummaryBillOfMaterialCostImpl,
    }
  }

  static async getAllMaterialsOfSales(idSales) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `query ($id: ID!) {
        getAllMaterialSalesItem(id: $id) {
          materialList {
            idMaterial
            materialCode
            description
            dimension
            materialType {
              density
            }
            unitPrice
            availableStock
            realStock
          }
        }
      }`,
      variables: {
        id: idSales,
      },
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.getAllMaterialSalesItem.materialList,
    }
  }

  static async create(payload) {
    const payloadAsParam = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        saveBillOfMaterialImpl(${payloadAsParam}) {
          billOfMaterialImpl {
            idBom
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.saveBillOfMaterialImpl.billOfMaterialImpl,
    }
  }

  static async getBillOfMaterialCost(idBom) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        getBillOfMaterialCostImpl(idBom: ${parseInt(idBom, 10)}) {
          billOfMaterial{
            idBom
            bomNumber
            projectName
            productType {
              productType
            }
            workOrderEst {
              mouldCode
              workOrder {
                customer {
                  name
                }
                status
                woNumber
              }
              salesEstimate{
                description
              }
            }
            billOfMaterialRelation {
              partCode
              vendor
              sequence
              status
              category
              requiredQuantity
              dimension
              material {
                materialType {
                  name
                }
              }
              requiredQuantity
              uom {
                name
              }
              remark
              orderedBomRelation {
                orderedProduct {
                  quantity
                  unitPrice
                  discount
                  weight
                  relatedPurchaseOrder {
                    deliveryDate
                    poNumber
                    vendor {
                      name
                    }
                  }
                  goodReceipts {
                    receiptDate
                    receivedQuantity
                  }
                }
              }          
            }
          }
          outsourcingCosts {
            quantity
            unitPrice
            discount
            weight
            description
            unitOfMeasurement
            relatedPurchaseOrder {
              deliveryDate
              poNumber
              vendor {
                name
              }
            }
            goodsReceipt {
              receiptDate
              receivedQuantity
            }
            material {
              materialType {
                name
              }
            }
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.getBillOfMaterialCostImpl,
    }
  }

  static async getItem(id) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query { 
        getBillOfMaterialImpl (id: ${id}) {
          ${ITEM_DETAIL_QUERY}
          dailyWorkTimes {
            edges {
              node {
                idWorkTime
              }
            }
          }
        }
      }`,
    })
    handleSilentApiError(response)

    const newBomRelation = orderBy(
      response.data.data.getBillOfMaterialImpl.billOfMaterialRelation,
      ["sequence"],
      ["asc"]
    )

    return {
      data: {
        ...response.data.data.getBillOfMaterialImpl,
        billOfMaterialRelation: newBomRelation,
      },
    }
  }

  static async delete(idBom) {
    const response = await axios.post(ENDPOINT.DELETE, {
      query: `
      mutation {
        deleteBillOfMaterialImpl(idBom: ${idBom}) { 
          billOfMaterialImpl { 
            idBom 
          } 
        } 
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.deleteBillOfMaterialImpl.billOfMaterialImpl,
    }
  }

  static async getBomRelationForIssue(idBom) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        getBillOfMaterialImpl(id: ${idBom}) {
          billOfMaterialRelation {
            idBomRelation
            materialId
            partCode
            requiredQuantity
          }
        }
      }`,
    })
    handleSilentApiError(response)

    const { billOfMaterialRelation } = response.data.data.getBillOfMaterialImpl

    return {
      data: billOfMaterialRelation,
    }
  }

  static async getBomRelationGoodsStatus(idBomRelation) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        getBomRelationGoodsStatusImpl(idBomRelation: ${idBomRelation}) {
          quantity
          goodsIssues {
            giNumber
            issueDate
            outgoingQuantity
            projectCode
            idBomRelation
          }
          goodReceipts {
            edges {
              node {
                receiptDate
                receivedQuantity
              }
            }
          }
          relatedPurchaseOrder {
            poNumber
            poDate
          }
        }
        getBomRelationGoodsStatusManualImpl(idBomRelation: ${idBomRelation}){
          poNumber
          issueDate
          outgoingQuantity
          projectCode
          idBomRelation
        }
        getBillOfMaterialRelationImpl(id: ${idBomRelation}) {
          requiredQuantity
        }
      }`,
    })

    const {
      requiredQuantity = 0,
    } = response.data.data.getBillOfMaterialRelationImpl

    const data = []

    response.data.data.getBomRelationGoodsStatusImpl.forEach((item) => {
      data.push({ ...item, requiredQuantity })
    })

    response.data.data.getBomRelationGoodsStatusManualImpl.forEach((item) => {
      data.push({
        requiredQuantity,
        goodsIssues: [item],
        relatedPurchaseOrder: {
          poNumber: item.poNumber,
          poDate: null,
        },
        goodReceipts: {
          edges: [],
        },
      })
    })

    console.log({ data })

    handleSilentApiError(response)
    return {
      data,
    }
  }

  static async getDefaultUom() {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        getBomDefaultUom {
          idUom
          name
        }
      }`,
    })
    handleSilentApiError(response)
    return {
      data: response.data.data.getBomDefaultUom,
    }
  }

  static async exportBomTemplate({ bomId }) {
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        exportTemplateBillOfMaterialImpl (bomId: ${bomId}) {
          billOfMaterialTemplate
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data:
        response.data.data.exportTemplateBillOfMaterialImpl
          .billOfMaterialTemplate,
    }
  }

  static async importBomRelationExcel(payload) {
    const payloadAsParam = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        importBillOfMaterialRelationImpl (${payloadAsParam}) {
          billOfMaterialRelationImpl {
            category
            remark
            dimension
            description
            requiredQuantity
            sequence
            partGroup
            materialId
            uom {
              idUom
              name
            }
            material {
              dimension
              availableStock
              description
              materialCode
              materialType {
                name
                inventoryType {
                  name
                }
              }
            }
            tolerance
            champer
            process
          }
        }
      }`,
    })
    handleSilentApiError(response)
    return {
      data:
        response.data.data.importBillOfMaterialRelationImpl
          .billOfMaterialRelationImpl,
    }
  }

  static async update(idBom, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idBom: parseInt(idBom, 10),
    })

    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateBillOfMaterialImpl (${payloadAsParam}) {
          billOfMaterialImpl {
            ${ITEM_DETAIL_QUERY}
          }
        }
      }`,
    })
    handleSilentApiError(response)
    const newBomRelation = orderBy(
      response.data.data.updateBillOfMaterialImpl.billOfMaterialImpl
        .billOfMaterialRelation,
      ["sequence"],
      ["asc"]
    )
    return {
      data: {
        ...response.data.data.updateBillOfMaterialImpl.billOfMaterialImpl,
        billOfMaterialRelation: newBomRelation,
      },
    }
  }

  static async revise(idBom, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idBom: parseInt(idBom, 10),
    })

    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        reviseBillOfMaterialImpl (${payloadAsParam}) {
          billOfMaterialImpl {
            ${ITEM_DETAIL_QUERY}
          }
        }
      }`,
    })
    handleSilentApiError(response)
    const newBomRelation = orderBy(
      response.data.data.reviseBillOfMaterialImpl.billOfMaterialImpl
        .billOfMaterialRelation,
      ["sequence"],
      ["asc"]
    )
    return {
      data: {
        ...response.data.data.reviseBillOfMaterialImpl.billOfMaterialImpl,
        billOfMaterialRelation: newBomRelation,
      },
    }
  }

  static async updateStatus(idBom, payload) {
    const payloadAsParam = newDictToStringParam({
      status: payload,
      idBom: parseInt(idBom, 10),
    })

    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateStatusBillOfMaterialImpl (${payloadAsParam}) {
          billOfMaterialImpl {
            status
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data:
        response.data.data.updateStatusBillOfMaterialImpl
          .updateStatusBillOfMaterial,
    }
  }

  static async getItemForAssignMaterial(idBom) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        getAssignMaterialHistory (idBom: ${idBom}) {
          calculatedBillOfMaterial {
            ${ITEM_PROJECT_DETAIL_QUERY}
          }
          assignMaterialHistory {
            idGoodIssue
            checkedBy
            createdDate
            reservedStock
            requiredStock
            realStockHistory
            availableStockHistory
            bomRelation {
              dimension
              material {
                materialCode
                description
              }
            }
          }
        }
      }`,
    })
    const errors = response.data?.errors || []
    const itemNotFound = errors.some(
      (error) =>
        error.message ===
        "'NoneType' object has no attribute 'bill_of_material_relation'"
    )

    if (itemNotFound) {
      return null
    }
    handleSilentApiError(response)

    const responseData = response.data.data.getAssignMaterialHistory

    return {
      assignMaterialHistory: responseData.assignMaterialHistory,
      billOfMaterial: responseData.calculatedBillOfMaterial,
    }
  }

  static async getRelatedDocuments(idBom) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query ($id: Int!) {
        relatedDocumentsInAssignMaterial(idBom: $id) {
          purchaseOrders {
            idPurchaseOrder
            poNumber
            deliveryDate
          }
          purchaseRequisitions {
            idPurchaseRequisition
            prNumber
          }
          estimates {
            idEstimate
            estimationNumber
          }
          poGrItems {
            grItems {
              grNumber
            }
            poNumber
          }
          deliveryNotes {
            dnNumber
            idDeliveryNote
          }
          outgoingQcItems {
            idOutgoingMaterialReport
            qcNumber
          }
          goodsIssues {
            giNumber
          }
        }
      }
      `,
      variables: {
        id: idBom,
      },
    })
    const errors = response.data?.errors || []
    const itemNotFound = errors.some(
      (error) =>
        error.message ===
        "'NoneType' object has no attribute 'bill_of_material_relation'"
    )

    if (itemNotFound) {
      return null
    }
    handleSilentApiError(response)

    return {
      data: response.data.data.relatedDocumentsInAssignMaterial,
    }
  }

  static async assignMaterial(goodIssueList) {
    if (goodIssueList.length < 0) {
      return {
        data: [],
      }
    }
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        saveAssignMaterialImpl (${newDictToStringParam({
          assignMaterialInputLists: goodIssueList,
        })}) {
          assignMaterialResults {
            idBomRelation
          }
        }
      }
      `,
    })
    handleSilentApiError(response)

    return { data: response.data.data.saveAssignMaterialImpl }
  }

  static async changeProjectStatus(idBom, status) {
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        changeProjectStatus (bomId: ${idBom}, status: "${status}") {
          bomImpl {
            id
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.changeProjectStatus.bomImpl,
    }
  }

  static async closeProject(idBom) {
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        closeProjectStatus (bomId: ${idBom}) {
          bomImpl {
            id
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.closeProjectStatus.bomImpl,
    }
  }

  static async getProjectCost(idBom) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        getProjectCostImpl (bomId: ${idBom}) {
          category
          vendor
          idBomRelation
          dimension
          qty
          price
          sequence
          materialType
        }
      }
      `,
    })
    handleSilentApiError(response)
    const sorted = sortBy(response.data.data.getProjectCostImpl, [
      (o) => o.sequence,
    ])
    return {
      data: sorted,
    }
  }

  static async getBomRelation(bomId) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query { 
        getBillOfMaterialImpl (id: ${bomId}) {
          billOfMaterialRelation {
            idBomRelation
            vendor
            partCode
            requiredQuantity
            dimension
            status
            uom {
              name
            }
            material {
              description
              unitPrice
            }
          }
        }
      }`,
    })
    handleSilentApiError(response)

    const newBomRelation = orderBy(
      response.data.data.getBillOfMaterialImpl.billOfMaterialRelation.filter(
        (item) => item.status
      ),
      ["sequence"],
      ["asc"]
    )

    return {
      data: {
        ...response.data.data.getBillOfMaterialImpl,
        billOfMaterialRelation: newBomRelation,
      },
    }
  }

  static async getForPurchasingUsage(inpParams = {}) {
    const payload = newDictToStringParam({ ...inpParams })
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allBillOfMaterialImpl(${payload}){
          idBom
          projectCode
          workOrderEst {
            description
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.allBillOfMaterialImpl,
    }
  }

  static async getForManufacturingProgress(inpParams = {}) {
    const payload = newDictToStringParam({
      ...inpParams,
      projectStatuses: ["enter", "ongoing"],
      manufacturingProgress: 0,
    })
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allBillOfMaterialImpl(${payload}) {
          idBom
          workOrderEst {
            mouldCode
            description
            dueDateDelivery
            schedules {
              date
            }
            workOrder {
              customer {
                name
              }
            } 
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.allBillOfMaterialImpl,
    }
  }

  static async exportOrderedBomReport(params) {
    const payload = newDictToStringParam(params)
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        exportOrderedBomReportImpl(${payload}) {
          exportOrderedBomReport
        }
      }`,
    })
    handleSilentApiError(response)
    return {
      data:
        response.data.data.exportOrderedBomReportImpl.exportOrderedBomReport,
    }
  }

  static async deleteBillOfMaterialRelation(ids) {
    const payload = newDictToStringParam({ idBillOfMaterialRelations: ids })
    const response = await axios.post(ENDPOINT.DELETE, {
      query: `
      mutation {
        deleteBillOfMaterialRelationImpl(${payload}) {
          billOfMaterialImpl {
            idBom
          }
        }
      }`,
    })
    handleSilentApiError(response)
    return {
      data:
        response.data.data.deleteBillOfMaterialRelationImpl.billOfMaterialImpl,
    }
  }
}
