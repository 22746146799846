/* eslint-disable react/prop-types */
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import { Button } from "@material-ui/core"
import { useBaseFormStyles } from "./constants"
import WOGeneralForm from "./WOGeneralForm"
import WOEstimateForm from "./WOEstimateForm"
import WOFormContext from "./WOFormContext"

export default function WOForm({
  initialFormData = {},
  fetchQuotationData,
  fetchCustomerData,
  fetchEstimateData,
  fetchMouldCodeData,
  fetchWoTypeData,
  isUpdate = false,
  onCancel = () => {},
  onSubmit = () => {},
  updatePostedWorkOrder = false,
  canDeleteWorkOrderEstimate = false,
}) {
  const classes = useBaseFormStyles()
  const generalFormRef = useRef(null)
  const detailFormRef = useRef(null)
  const [customerId, setCustomerId] = useState(initialFormData?.customerId)

  const [selectedQuotation, setSelectedQuotation] = useState(null)

  const formRef = useRef({})

  const [initData] = useState({
    ...initialFormData,
    woDate: (initialFormData?.woDate ?? new Date().toISOString()).substring(
      0,
      10
    ),
  })

  const aggregateFormData = () => {
    let formData = {}

    const generalFormData = generalFormRef.current.getFormData()
    const detailFormData = detailFormRef.current.getFormData()

    formData = { ...generalFormData, estimateList: detailFormData }
    delete formData.slsQuotId
    delete formData.progress
    return formData
  }

  const submitData = () => {
    onSubmit(formRef.current)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      await Promise.all([
        generalFormRef.current.onSubmit(),
        detailFormRef.current.onSubmit(),
      ])
    } catch (e) {
      console.log(e)
      return
    }

    const formData = aggregateFormData()
    formRef.current = formData

    submitData()
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <WOFormContext.Provider value={{ customerId, setCustomerId }}>
        <WOGeneralForm
          ref={generalFormRef}
          initialFormData={initData}
          fetchCustomerData={fetchCustomerData}
          fetchQuotationData={fetchQuotationData}
          fetchWoTypeData={fetchWoTypeData}
          onSelectQuotation={setSelectedQuotation}
          isUpdate={isUpdate}
          updatePostedWorkOrder={updatePostedWorkOrder}
        />

        <WOEstimateForm
          ref={detailFormRef}
          initialFormData={initialFormData}
          fetchEstimateData={fetchEstimateData}
          slsQuotId={selectedQuotation?.idSalesQuotation}
          fetchQuotationData={fetchQuotationData}
          updatePostedWorkOrder={updatePostedWorkOrder}
          fetchMouldCodeData={fetchMouldCodeData}
          canDeleteWorkOrderEstimate={canDeleteWorkOrderEstimate}
        />
      </WOFormContext.Provider>

      <div className={classes.buttonWrapper}>
        <Button
          variant="outlined"
          color="secondary"
          disableElevation
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
        >
          Save
        </Button>
      </div>
    </form>
  )
}

WOForm.propTypes = {
  fetchQuotationData: PropTypes.func.isRequired,
  fetchCustomerData: PropTypes.func.isRequired,
  fetchEstimateData: PropTypes.func.isRequired,
  fetchWoTypeData: PropTypes.func.isRequired,
  initialFormData: PropTypes.shape({
    slsQuotId: PropTypes.string,
  }).isRequired,
  isUpdate: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updatePostedWorkOrder: PropTypes.bool.isRequired,
}
