import React from "react"
import PropTypes from "prop-types"
import logo from "assets/logo-kmi.jpg"

import clsx from "clsx"
import { useAlignStyles, useTyphoStyles } from "./HeaderPrintStyles"

const HeaderPrint = ({
  // eslint-disable-next-line no-unused-vars
  typeDocument,
  companyInformation = {},
  showAddress = false,
  itemDetail = null,
  showOnlyAddress = false,
  hideNPWP = false,
}) => {
  const typhoClasses = useTyphoStyles()
  const alignClasses = useAlignStyles()

  return (
    <div className={clsx("page-header")} style={{ width: "100%" }}>
      <div style={{ position: "relative" }}>
        <div style={{ textAlign: "left" }}>
          <div className={clsx(alignClasses.headerOverflow)}>
            <img alt="Logo" src={logo} className={typhoClasses.logo} />
            <span className={typhoClasses.companyName}>
              {companyInformation?.companyName || ""}
            </span>
          </div>
          <div
            className={clsx(alignClasses.row, alignClasses.separator)}
            style={{ justifyContent: "space-between" }}
          >
            {showOnlyAddress && (
              <div
                className={clsx(
                  alignClasses.columnLeftAlign,
                  typhoClasses.normal,
                  alignClasses.rowChild
                )}
                style={{
                  marginTop: "-2em",
                  maxWidth: "40%",
                  marginBottom: "-3em",
                }}
              >
                <div className={alignClasses.wrapDisplay}>
                  {companyInformation?.address || ""}
                </div>
              </div>
            )}
            {showAddress && (
              <div
                className={clsx(
                  alignClasses.columnLeftAlign,
                  typhoClasses.normal,
                  alignClasses.rowChild
                )}
                style={{
                  marginTop: "-2em",
                  maxWidth: "40%",
                  marginBottom: "-3em",
                }}
              >
                <div className={alignClasses.wrapDisplay}>
                  {companyInformation?.address || ""}
                </div>

                <div>
                  <b>Phone:</b> {companyInformation?.phone || ""}
                </div>
                <div>
                  <b>Fax:</b> +62-267-647172
                </div>
                {!hideNPWP ? (
                  <div>
                    <b>N.P.W.P No.</b> {companyInformation?.NPWP || ""}
                  </div>
                ) : null}
              </div>
            )}
            {itemDetail}
          </div>
        </div>

        {/* <div
          className="page-counter"
          style={{
            position: "absolute",
            right: 0,
            top: 4,
          }}
        /> */}
      </div>
    </div>
  )
}

HeaderPrint.propTypes = {
  typeDocument: PropTypes.string,
  companyInformation: PropTypes.node,
  showAddress: PropTypes.bool,
  itemDetail: PropTypes.node,
  showOnlyAddress: PropTypes.bool,
}

HeaderPrint.defaultProps = {
  typeDocument: "external",
  companyInformation: {},
  itemDetail: null,
  showOnlyAddress: false,
  showAddress: false,
}

export default HeaderPrint
