import cloneDeep from "lodash/cloneDeep"
import { axios, ENDPOINT } from "./constants"

import {
  handleSilentApiError,
  newDictToStringParam,
  adjustDefaultParams,
  getQueryForAllAndCount,
  getDataAndCountFromResponse,
} from "./utils"

const ITEM_DETAIL_QUERY = `
idCurr
code
description
`

export default class ApiCurrency {
  static async get(inpParams = {}) {
    const params = adjustDefaultParams(inpParams)
    let query = `
    query { 
      allCurrencyImpl { 
        idCurr
        code
        description
      } 
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    return getDataAndCountFromResponse(response)
  }

  static async create(payload) {
    const payloadAsParam = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        saveCurrencyImpl (${payloadAsParam}) {
          currencyImpl { 
            idCurr 
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.saveCurrencyImpl.currencyImpl }
  }

  static async getItem(id) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query { 
        getCurrencyImpl (id: ${id}) {
          ${ITEM_DETAIL_QUERY}
        } 
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.getCurrencyImpl }
  }

  static async delete(idCurr) {
    const result = { success: false }

    try {
      await axios.post(ENDPOINT.DELETE, {
        query: `
        mutation {
          deleteCurrencyImpl(idCurr: ${idCurr}) { 
            currencyImpl { 
              idCurr 
            } 
          } 
        }`,
      })
      result.success = true
    } catch (e) {
      result.error = cloneDeep(e)
    }

    return result
  }

  static async update(idCurr, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idCurr: parseInt(idCurr, 10),
    })

    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateCurrencyImpl (${payloadAsParam}) {
          currencyImpl {
            ${ITEM_DETAIL_QUERY}
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.updateCurrencyImpl.currencyImpl }
  }
}
