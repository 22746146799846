import { transformProperty, toDict } from "utils/objects"
import reduce from "lodash/reduce"
import isNil from "lodash/isNil"

import merge from "lodash/merge"
import cloneDeep from "lodash/cloneDeep"
import { findSalesEstimatesActiveReduction } from "components/sales-quotation/utils"

/**
 * Convert process estimate items into list of price
 *
 * @param {Array} items
 * @param {Object} masterDataAsDict
 */

export const processEstimatesToPrice = (items, masterDataAsDict) =>
  items.map((item) => {
    const masterItem = masterDataAsDict[item.processId]
    if (!masterItem) return 0
    return parseFloat(item.time) * parseFloat(masterItem.cost)
  })

/**
 * Convert material estimate items into list of price
 * @param {Array} items
 * @param {Object} masterDataAsDict
 */

export const materialEstimatesToPrice = (items, masterDataAsDict) =>
  items.map((item) => {
    let masterItem = masterDataAsDict[item.materialId]
    const [length = 1, width = 1, height = 1] = (item?.dimension ?? "1x1x1")
      .toLowerCase()
      .split("x")

    if (!masterItem) return 0

    masterItem = { ...masterItem }
    const itemResult = { ...item, length, width, height } // Make a shallow, 1-level copy

    // Mutate the objects' properties by transforming to Number object
    transformProperty(masterItem, ["density", "unitPrice"], parseFloat)
    transformProperty(
      itemResult,
      ["length", "width", "height", "quantity"],
      parseFloat
    )

    return (
      itemResult.length *
      itemResult.width *
      itemResult.height *
      (masterItem.density / 1e6) *
      itemResult.quantity *
      (item?.unitPrice ?? masterItem.unitPrice)
    )
  })

/**
 * Convert additional estimate items into list of price
 * @param {Array} items
 */

export const additionalEstimatesToPrice = (items) =>
  items.map((item) => parseFloat(item.quantity) * parseFloat(item.cost))

/** Sum all array numbers */

const sum = (items) => items.reduce((acc, item) => acc + item, 0) || 0

/**
 * Simplify list of estimations into idEstimate, unitPrice, and estimationNumber
 * @param {Array} item
 * @param {Object} masterDataAsDict dictionary-like object consisting of material & process
 */

export const simplifyEstimationItems = (item, { process, material }) => {
  if (!process || !material) {
    throw new Error("Master data is not supplied")
  }

  const processCosts = processEstimatesToPrice(item.processCosts || [], process)
  const materialCosts = materialEstimatesToPrice(
    item.materialCosts || [],
    material
  )
  const additionalCosts = additionalEstimatesToPrice(item.additionalCosts || [])

  const processCost = sum(processCosts) || 0
  const materialCost = sum(materialCosts) || 0
  const additionalCost = sum(additionalCosts) || 0

  const processHandling = parseFloat(item.processCostHandling) || 0
  const materialHandling = parseFloat(item.materialCostHandling) || 0
  const additionalHandling = parseFloat(item.additionalCostHandling) || 0

  const unitPrice =
    processCost * (1 + processHandling / 100) +
    materialCost * (1 + materialHandling / 100) +
    additionalCost * (1 + additionalHandling / 100)

  return {
    idEstimate: item.idEstimate,
    unitPrice,
    estimationNumber: item.estimationNumber,
  }
}

export const joinSalesEstimatesAndMasterData = (
  salesEstimates,
  estimatesAsDict,
  materialsAsArray,
  processAsArray,
  marginMultiplier
) => {
  const materialsAsDict = toDict(materialsAsArray, "idMaterial")
  const processAsDict = toDict(processAsArray, "idProcess")

  return salesEstimates
    .map((item) => {
      const estimateItem = estimatesAsDict[item.estQuotId]
      if (isNil(estimateItem)) {
        return null
      }

      const estimationData = simplifyEstimationItems(estimateItem, {
        material: materialsAsDict,
        process: processAsDict,
      })
      const unitPrice = estimationData.unitPrice * marginMultiplier
      const totalCost = parseFloat(item.quantity) * unitPrice

      return {
        ...estimateItem,
        ...item,
        unitPrice,
        totalCost,
      }
    })
    .filter((item) => item)
}
/**
 * Purges top level fields of initial form data. Any fields which
 * aren't in baseBlankForm will be deleted.
 * @param {*} initialData
 * @param {*} baseBlankForm
 */

export const purgeInitialFormData = (initialData, baseBlankForm) => {
  // eslint-disable-next-line prefer-const
  let blankForm = cloneDeep(baseBlankForm)
  const formData = merge(cloneDeep(blankForm), cloneDeep(initialData))

  Object.keys(formData).forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!blankForm.hasOwnProperty(key)) {
      delete formData[key]
    }
  })

  return formData
}

export const sumEstimates = (joinedEstimates) =>
  reduce(joinedEstimates, (sumEst, item) => sumEst + item.totalCost, 0)

export function simplifyInvoiceQuotItems(items) {
  return items.map((item) => {
    const estQuot = item?.salesEstimate?.estQuot ?? item?.estQuot ?? {}
    const processCost = (estQuot?.processCosts || [])
      .map(
        (processItem) =>
          parseFloat(processItem.time) * parseFloat(processItem.cost)
      )
      .reduce((a, b) => a + b, 0)

    const materialCost = materialEstimatesToPrice(
      estQuot?.materialCosts ?? [],
      toDict(
        (estQuot?.materialCosts || []).map((m) => ({
          ...m.material,
          dimension: m.dimension,
          materialId: m.materialId,
          density: m.material.materialType.density,
        })),
        "materialId"
      )
    ).reduce((a, b) => a + b, 0)
    const additionalCost = additionalEstimatesToPrice(
      estQuot?.additionalCosts ?? []
    ).reduce((a, b) => a + b, 0)

    const processHandling = parseFloat(estQuot?.processCostHandling) || 0
    const materialHandling = parseFloat(estQuot?.materialCostHandling) || 0
    const additionalHandling = parseFloat(estQuot?.additionalCostHandling) || 0

    const activeReduction = findSalesEstimatesActiveReduction(item ?? {})

    const { totalCost: totalCostFromEQ } = estQuot

    let unitPrice =
      processCost * (1 + processHandling / 100) +
      materialCost * (1 + materialHandling / 100) +
      additionalCost * (1 + additionalHandling / 100)

    if (totalCostFromEQ) unitPrice = totalCostFromEQ

    const discount =
      (item.discount || 0) *
      (item?.salesEstimate?.quantity || item?.quantity || 1)

    const totalCost = (
      parseFloat(item?.salesEstimate?.quantity || item?.quantity || 1) *
        unitPrice *
        (1 + (item?.handling / 100 || 0)) -
      (activeReduction?.reduction ?? 0) -
      discount
    ).toFixed(2)

    return {
      totalCost,
      unitPrice,
      materialCost,
      description: item.description,
      quantity: item.quantity,
      unit: item.unit,
      idDnDetail: item.idDnDetail,
    }
  })
}
