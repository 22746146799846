import React from "react"
import PropTypes from "prop-types"
import { Snackbar } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const AppSnackbar = ({ open, message, status, onClose, autoHideDuration }) => {
  let closeButtons = (
    <>
      <Button
        style={{ color: "cyan", fontWeight: "bold" }}
        size="small"
        onClick={onClose}
      >
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  )
  if (status === "loading") {
    closeButtons = <></>
  }

  const handleClosed = (event, reason) => {
    if (reason !== "clickaway") {
      onClose(event)
    }
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      onClose={handleClosed}
      message={message}
      action={closeButtons}
      autoHideDuration={autoHideDuration}
    />
  )
}

AppSnackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.node,
  status: PropTypes.oneOf(["", "loading"]),
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
}

AppSnackbar.defaultProps = {
  open: false,
  message: null,
  status: "",
  onClose: () => {},
  autoHideDuration: null,
}

export default AppSnackbar
