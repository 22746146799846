import every from "lodash/every"

const pruneDisallowedRoutes = (routes, resolvedPermissions) => {
  /* 
  const CALL_LIMIT = 500
  let i = CALL_LIMIT
 */

  const checkAllowed = (permissionCodes) => {
    return every(
      permissionCodes,
      (code) =>
        resolvedPermissions[code]?.allow &&
        (resolvedPermissions[code]?.unsatisfiedDeps || []).length === 0
    )
  }

  /**
   * Prune route with missing dependencies.
   * If route has missing dependencies, it will not be included
   * in the parent route.
   * If route is not a link and has no child routes,
   * the route is discarded.
   *
   * @param {*} routeItem
   * @param {*} newRouteItem
   */

  const pruneRoute = (routeItem, newRouteItem) => {
    /*
    // Just in case infinite recursion happens
    i -= 1
    if (i <= 0) throw new Error("Maximum call exceeded")
    */

    const neededPermissions = routeItem?.permissions || []
    const needPermission = neededPermissions.length > 0

    if (needPermission) {
      const isAllowed = checkAllowed(neededPermissions)
      if (!isAllowed) return false
    }

    /* eslint-disable no-param-reassign */
    if (routeItem.routes) {
      newRouteItem.routes = routeItem.routes
        .map((childRouteItem) => {
          const newChildRouteItem = { ...childRouteItem }
          const shouldAppendChild = pruneRoute(
            childRouteItem,
            newChildRouteItem
          )
          return shouldAppendChild ? newChildRouteItem : null
        })
        .filter((childRouteItem) => !!childRouteItem)

      if (newRouteItem.routes.length <= 0) {
        delete newRouteItem.routes
      }
    }
    /* eslint-enable no-param-reassign */

    const routeHasChild = (newRouteItem.routes || []).length > 0
    const routeHasLink = !!newRouteItem.link

    if (!routeHasChild && !routeHasLink) {
      return false
    }

    return true
  }

  const rootRoute = { routes }
  const rootNewRoute = {}
  pruneRoute(rootRoute, rootNewRoute)
  // console.log(CALL_LIMIT - i);

  return rootNewRoute.routes || []
}

export default pruneDisallowedRoutes
