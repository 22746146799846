import {
  createRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"

const createArrayRefs = (len) => {
  return new Array(len).fill(null).map(() => createRef())
}

export const openOnlyTopLevel = ({ level }) => level === 0

/**
  Provide a ref to get all descendants' and this route's open state.
  The structure should be like these:
  Each route is represented in this format
  [<ROUTE_OPEN_STATE>, [<CHILDREN_OPEN_STATE>]]
  if a route has no children open state, it's referenced purely by number

  For example: 1 // has no children and opened        
               [1, []] // has no children and opened
               [0, []] // has no children and closed
               [0, [1, 0]] // is closed, has two children, and first child is opened

  If this has no children, return boolean only.
  The parent is assumed to know the children's route name so no need
  to provide this route's name.
*/
export const useOpenState = (open, routes, ref) => {
  const [childrenRefs, setChildrenRefs] = useState([])
  const routeLength = routes?.length || 0
  useEffect(() => {
    setChildrenRefs(createArrayRefs(routeLength))
  }, [routeLength])

  useImperativeHandle(
    ref,
    () => {
      return {
        /** Get the open state of this component and all of this descendants.
         * The state is converted from Boolean to Number
         * for compact info when stringified.
         */
        getDescendantsOpenState: () => {
          const openAsNumber = Number(Boolean(open))
          if (!routes?.length) {
            return openAsNumber
          }
          const childrenOpenStates = []
          childrenRefs.forEach((childRef, i) => {
            const getChildOpenStates =
              childRef?.current?.getDescendantsOpenState
            let isOpen = 0 // false
            if (typeof getChildOpenStates === "function") {
              isOpen = getChildOpenStates()
            }
            childrenOpenStates[i] = isOpen
          })

          return [openAsNumber, childrenOpenStates]
        },
      }
    },
    [open, routes, childrenRefs]
  )

  return childrenRefs
}

/**
 * Extract open states from localStorage
 */

export const getSavedRouteOpenStates = () => {
  try {
    return JSON.parse(localStorage.getItem("nav-open-states"))
  } catch (e) {
    return null
  }
}

/**
 * Get side nav init open rule based on saved open state from localStorage.
 */

export const useNavOpenRule = () => {
  const [initStates] = useState(getSavedRouteOpenStates)
  const rule = useMemo(() => {
    if (!initStates) {
      return openOnlyTopLevel
    }

    return ({ indices }) => {
      let routeConfig = [0, initStates]
      let children = []

      // Iteratively find the last element that has the pattern [open_state, children]
      for (let i = 0; i < indices.length; i++) {
        if (!Array.isArray(routeConfig)) {
          // The states from localStorage may have been differed
          // from the current routes, so let's fallback to closed state.
          return false
        }

        ;[, children] = routeConfig
        const elemId = indices[i]

        // The states from localStorage may have been corrupted via user's console.log
        // So if it's not array, just return false.
        if (Array.isArray(children)) {
          routeConfig = children[elemId]
        } else {
          return false
        }
      }

      if (Array.isArray(routeConfig)) {
        return !!routeConfig[0]
      }
      return !!routeConfig
    }
  }, [initStates])

  const ref = useRef()

  useEffect(() => {
    window.addEventListener("unload", () => {
      const getOpenStates = ref?.current?.getOpenStates
      if (typeof getOpenStates === "function") {
        localStorage.setItem("nav-open-states", JSON.stringify(getOpenStates()))
      } else {
        localStorage.removeItem("nav-open-states")
      }
    })
  }, [])

  return { rule, ref, initStates }
}
