/* eslint-disable global-require */

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import validator from "services/auth/validator"

import App from "./App"
import store from "./constants/store"
import * as serviceWorker from "./serviceWorker"

import "./index.css"

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById("root")
  )
}

const token = localStorage.getItem("accessToken")
validator.validateFromToken(token)

render(App)

if (process.env.NODE_ENV === "production") {
  if (typeof window !== "undefined") {
    window.console.log = () => {}
    window.console.error = () => {}
  }
}

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
