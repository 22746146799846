/* eslint-disable react/prop-types */
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Grid, Typography } from "@material-ui/core"
import { purgeInitialFormData } from "utils/form-data"
import { ESTIMATE_BLANK_FORM, useBaseFormStyles } from "./constants"
import InputTableEstimate from "./InputTableEstimate"

const WOEstimateForm = forwardRef(
  (
    {
      initialFormData,
      fetchEstimateData,
      fetchQuotationData,
      updatePostedWorkOrder = false,
      fetchMouldCodeData = () => [],
      canDeleteWorkOrderEstimate,
    },
    ref
  ) => {
    const classes = useBaseFormStyles()

    const [formData] = useState(() => {
      return purgeInitialFormData(initialFormData || {}, ESTIMATE_BLANK_FORM)
    })

    const inputTableRef = useRef(null)

    useImperativeHandle(ref, () => ({
      onSubmit: () =>
        new Promise((res, rej) => {
          Promise.all([inputTableRef.current.onSubmit()]).then(res).catch(rej)
        }),
      getFormData: () => {
        const estimateList = inputTableRef.current.getFormData()
        return estimateList
      },
    }))

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            Work Order Detail
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <InputTableEstimate
            ref={inputTableRef}
            defaultValue={formData.estimateList}
            fetchEstimateData={fetchEstimateData}
            fetchQuotationData={fetchQuotationData}
            initialFormData={formData}
            updatePostedWorkOrder={updatePostedWorkOrder}
            canDeleteWorkOrderEstimate={canDeleteWorkOrderEstimate}
            fetchMouldCodeData={fetchMouldCodeData}
          />
        </Grid>
      </Grid>
    )
  }
)

WOEstimateForm.propTypes = {
  initialFormData: PropTypes.shape({}),
  fetchEstimateData: PropTypes.func.isRequired,
  fetchQuotationData: PropTypes.func.isRequired,
  updatePostedWorkOrder: PropTypes.bool.isRequired,
}

WOEstimateForm.defaultProps = {
  initialFormData: {},
}

export default WOEstimateForm
