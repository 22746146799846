/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { simplifyInvoiceQuotItems } from "components/delivery-note/utils"
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
  useRef,
} from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { transformProperty } from "utils/objects"
import InvoiceDownPaymentRow from "./InvoiceDownPaymentRow"
import InvoiceFormContext from "./InvoiceFormContex"
import SalesQuotationPicker from "./SalesQuotationPicker"
import InvoiceDescriptionFormContext from "./InvoiceDescriptionFormContext"
import { getAllIndexes } from "./utils"

const InvoiceDownPaymentTable = React.forwardRef(
  ({ initialValue = {}, fetchPOData = () => [], fetchInitPOData }, ref) => {
    const [initialFormData] = useState(() => {
      return (initialValue?.productDescriptions ?? []).map((item, index) => {
        return {
          __id: index,
          description: item.description,
          unitPrice: item.unitPrice,
          quantity: item.quantity,
          unit: item.unit,
          slsQuot: item.slsEstimate?.slsQuot,
          slsEstimateId: item.slsEstimateId,
          totalCost: item.unitPrice,
        }
      })
    })

    const { rateIn, setProductDescriptions, invoiceType } = useContext(
      InvoiceFormContext
    )

    const { onAddInvoiceDetail, onRemoveInvoiceDetail } = useContext(
      InvoiceDescriptionFormContext
    )

    const { register, control, errors, getValues, setValue } = useForm({
      defaultValues: { productDescriptions: initialFormData },
    })

    const { fields, remove } = useFieldArray({
      control,
      name: "productDescriptions",
      keyName: "__id",
    })

    const lastId = useRef(fields.length)

    const [salesQuotationList] = useState([])

    const [selectedSlsQuotations, setSelectedSlsQuotations] = useState(() => {
      return Array.from(
        new Set(
          (initialValue?.productDescriptions ?? []).map(
            (item) => item.slsEstimate?.slsQuot?.slsQuotNumber
          )
        )
      )
    })

    useEffect(() => {
      let active = true

      ;(async () => {
        const ids = Array.from(
          new Set(
            (initialValue?.productDescriptions ?? []).map(
              (item) => item.slsEstimate?.slsQuotId
            )
          )
        )
        const response = await fetchInitPOData({ ids })
        if (!active) return

        const estimateIds = Array.from(
          new Set(
            (initialValue?.productDescriptions ?? []).map(
              (item) => item.slsEstimate?.idSalesEstimate
            )
          )
        )

        const defaultValue = response.map((sq) => {
          return {
            ...sq,
            estimateList: sq.estimateList.filter((eq) =>
              estimateIds.includes(eq.idSalesEstimate)
            ),
          }
        })

        setSelectedSlsQuotations(defaultValue)
      })()

      return () => {
        active = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useImperativeHandle(ref, () => ({
      getFormData: () => {
        const data = getValues()
        const dataCopy = (data?.productDescriptions ?? []).map((product) => {
          transformProperty(
            product,
            ["quantity", "slsEstimateId", "sequence", "itemKey"],
            parseInt
          )
          transformProperty(product, ["unitPrice"], parseFloat)
          // eslint-disable-next-line no-param-reassign
          if (!product.dnDetailId) delete product.dnDetailId
          // eslint-disable-next-line no-param-reassign
          if (!product.quantity) delete product.quantity
          return product
        })
        return { productDescriptions: dataCopy }
      },
      submitForm: () => {},
    }))

    const refRate = React.useRef(1)
    useEffect(() => {
      if (refRate.current !== 1) {
        setSelectedSlsQuotations([])
      } else {
        refRate.current += 1
      }
    }, [rateIn])

    useEffect(() => {
      const temp = []
      const prevValues = getValues().productDescriptions || []

      lastId.current = 0

      selectedSlsQuotations.forEach((curr) => {
        const currentList = curr?.estimateList || []
        const initValues = (initialFormData ?? []).map((fd) => fd.slsEstimateId)

        currentList
          .filter(
            (estList) =>
              estList.paymentStatus !== 3 ||
              initValues.includes(estList.idSalesEstimate)
          )
          .forEach((estList) => {
            const { totalCost } = simplifyInvoiceQuotItems([estList])[0]
            const current = prevValues.filter(
              (item) => +item.slsEstimateId === estList.idSalesEstimate
            )[0]
            const fromInit = initialFormData.filter(
              (item) => item.slsEstimateId === estList.idSalesEstimate
            )[0]
            temp.push({
              ...estList,
              itemKey: lastId.current,
              description:
                current?.description ||
                fromInit?.description ||
                estList.description,
              slsEstimateId: estList.idSalesEstimate,
              totalCost: estList.totalAmount || totalCost,
              unitPrice: estList.totalAmount || totalCost,
              slsQuot: {
                slsQuotNumber: curr.slsQuotNumber,
                poNumber: estList.slsPurchaseOrder?.poNumber,
              },
            })
            lastId.current += 1
          })
      })
      setProductDescriptions(temp)
      setValue("productDescriptions", temp)
      temp.forEach((val) => {
        setTimeout(() => {
          onAddInvoiceDetail({ key: val.itemKey })
        }, 250)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps, prettier/prettier
    }, [selectedSlsQuotations])

    const onAddSlsQuotation = (slsQuot) => {
      const dataCopy = [...selectedSlsQuotations]
      dataCopy.push(slsQuot)
      setSelectedSlsQuotations(dataCopy)
    }

    const onDeleteSlsQuotation = (slsQuotNumber) => {
      const dataCopy = [...selectedSlsQuotations]
      const newData = dataCopy.filter((item) => item !== slsQuotNumber)
      const deletedIndexes = getAllIndexes(
        fields,
        slsQuotNumber,
        (curr, val) => {
          if (typeof val === "string")
            return curr?.slsQuot?.slsQuotNumber === val
          return curr?.slsQuot?.slsQuotNumber === val?.slsQuotNumber
        }
      )

      deletedIndexes.forEach((idx) => {
        setTimeout(() => {
          onRemoveInvoiceDetail({ index: fields[idx].itemKey })
        }, 250)
      })
      setSelectedSlsQuotations(newData)
    }

    const onDeleteEstimateList = (index) => {
      const curr = fields[index]
      setTimeout(() => {
        onRemoveInvoiceDetail({ index: fields[index].itemKey })
      }, 250)

      remove(index)

      const selectedSlsQuotCopy = selectedSlsQuotations
        .map((sq) => {
          const { estimateList } = sq
          const newEstimateList = estimateList
            .filter((est) => est.idSalesEstimate !== curr.idSalesEstimate)
            .filter((est) => est.paymentStatus !== 3)
          return {
            ...sq,
            estimateList: newEstimateList,
          }
        })
        .filter((sq) => sq.estimateList.length)
      setSelectedSlsQuotations(selectedSlsQuotCopy)
    }

    useEffect(() => {
      setValue("productDescriptions", initialFormData)
      setProductDescriptions(initialFormData)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: "9rem" }}>
                  Sls Quot number
                </TableCell>
                <TableCell>PO Number</TableCell>
                <TableCell style={{ minWidth: "24rem" }}>Description</TableCell>
                <TableCell style={{ width: "7rem" }}>Quantity</TableCell>
                <TableCell style={{ width: "7rem" }}>UoM</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Sequence</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.length === 0 ? (
                <TableRow>
                  <TableCell style={{ textAlign: "center" }} colSpan={9}>
                    No Data
                  </TableCell>
                </TableRow>
              ) : null}
              {fields.map((item, index) => {
                return (
                  <InvoiceDownPaymentRow
                    key={item.__id}
                    register={register}
                    errors={errors}
                    item={item}
                    index={index}
                    showDelete
                    onDeleteButtonClick={onDeleteEstimateList}
                    showAdditionalCostButton={invoiceType === "INVOICE"}
                  />
                )
              })}
            </TableBody>
            <TableFooter>
              <SalesQuotationPicker
                onAddItem={onAddSlsQuotation}
                selectedSalesQuotations={selectedSlsQuotations}
                salesQuotationList={salesQuotationList}
                onDeleteItem={onDeleteSlsQuotation}
                fetchPOData={fetchPOData}
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    )
  }
)

export default InvoiceDownPaymentTable
