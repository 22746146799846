/* eslint-disable react/prop-types */
import React, { useState } from "react"
import FileDownload from "js-file-download"

import { useSendAndHandleInvalidToken as useSend } from "utils/invalid-token"
import useShowSnackbar from "utils/snackbar-hooks"

import { axios } from "services/constants"
import environment from "utils/environment"

import { Button, CircularProgress, Fade } from "@material-ui/core"
import IconDownload from "@material-ui/icons/CloudDownload"

export default function ExportButton({
  getFormValues,
  fetchData,
  text = "Download",
  filename = "Report",
  containerStyle = {},
}) {
  const sendAndHandleInvalidToken = useSend()
  const [downloading, setDownloading] = useState(false)

  const [showSnackbar, , hideSnackbar] = useShowSnackbar()

  const handleDownloadTemplate = async () => {
    const currentFormData = getFormValues()
    let fetchState = {}

    try {
      setDownloading(true)
      fetchState = await sendAndHandleInvalidToken(() =>
        fetchData(currentFormData)
      )
    } catch (e) {
      console.log({ e })
      // Error not caused by invalid token
      showSnackbar("Error while downloading template")
      setDownloading(false)
      return
    }

    if (fetchState.success) {
      const response = await axios.get(
        `${environment.baseRootApi}${fetchState.response.data}`,
        {
          responseType: "blob",
        }
      )
      FileDownload(response.data, filename)
    } else {
      // There are some errors caused by invalid token
      hideSnackbar()
    }
    setDownloading(false)
  }
  return (
    <div style={{ padding: "8px", ...containerStyle }}>
      <Button
        variant="contained"
        color="primary"
        type="button"
        disableElevation
        onClick={handleDownloadTemplate}
        style={{ textTransform: "none" }}
      >
        <div style={{ position: "relative" }}>
          <Fade in={!downloading}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconDownload />
              <span style={{ whiteSpace: "pre" }}>{"  "}</span>
              <span>{text}</span>
            </div>
          </Fade>
          <Fade in={downloading}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress
                color="inherit"
                size={24}
                thickness={4}
                style={{
                  color: "white",
                  margin: "auto",
                }}
              />
            </div>
          </Fade>
        </div>
      </Button>
    </div>
  )
}
