import React, { useState } from "react"
import PropTypes from "prop-types"

import Drawer from "@material-ui/core/Drawer"
import { makeStyles } from "@material-ui/core/styles"

import logo from "assets/logo-sidebar.png"
import useMediaQuery from "utils/media-query/useMediaQuery"
import SideNavigation from "./SideNavigation"
import AppBar from "./AppBar"

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    // width: `calc(100% - ${drawerWidth}px)`,
    width: `calc(100%)`,
    // marginLeft: drawerWidth,
    backgroundColor: "#033278",
  },
  appBarDesktop: {
    width: `calc(100% - ${drawerWidth}px)`,
    // width: `calc(100%)`,
    marginLeft: drawerWidth,
    backgroundColor: "#033278",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: "scroll",
    background: theme.palette.background.default,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  companyLogo: {
    width: "calc(100% - 77px)",
    margin: "13px auto",
  },
}))

const AppPage = ({ navProps, appBarProps, children, hide }) => {
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(false)

  const isDesktop = useMediaQuery("(min-width: 1200px)")

  return (
    <div className={classes.root}>
      <AppBar
        {...appBarProps}
        classes={{
          appBar: isDesktop ? classes.appBarDesktop : classes.appBar,
        }}
        style={{
          display: hide ? "none" : null,
        }}
        onDashboardButtonClick={() => {
          if (!isDesktop) {
            setOpenDrawer(!openDrawer)
          }
        }}
      />
      <Drawer
        className={classes.drawer}
        variant={isDesktop ? "permanent" : "temporary"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="left"
        classes={{
          paper: classes.drawerPaper,
        }}
        style={{
          display: hide ? "none" : null,
        }}
      >
        <img src={logo} alt="Company Logo" className={classes.companyLogo} />
        <SideNavigation {...navProps} />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

AppPage.propTypes = {
  navProps: PropTypes.shape({}),
  appBarProps: PropTypes.shape({}),
  children: PropTypes.node,
  hide: PropTypes.bool,
}

AppPage.defaultProps = {
  navProps: { routes: [] },
  appBarProps: {},
  children: null,
  hide: false,
}

export default AppPage
