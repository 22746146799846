import { API_LOGIN_URL } from "constants/apiConstants"
import { ROUTE_HOMEPAGE, ROUTE_LOGIN } from "constants/routeConstants"
import history from "app-history"
import { axios } from "services/constants"
import environment from "utils/environment"
import {
  startInitTokenValidation,
  setTokenFromSuccessLogin,
  clearTokenAtLogout,
  setUserData,
} from "state/user/actions"
import ApiUser from "services/api-user"
import validatorInstance from "services/auth/validator"

export const performLogin = async (username, password, dispatch, env = 1) => {
  dispatch(startInitTokenValidation())

  let response = await axios.post(environment.rootApi + API_LOGIN_URL, {
    query: ` 
    mutation { 
      login(name: "${username}", password: "${password}", env:${env}) {
          accessToken,
          refreshToken,
      } 
    }`,
  })

  // Login

  const token = response.data?.data?.login?.accessToken
  if (!token) {
    throw new Error("AUTHENTICATION_FAILED")
  }

  // Fetch complete user data
  response = await ApiUser.getCurrentUser(token)
  const userData = response.data

  await dispatch(setTokenFromSuccessLogin(token))
  dispatch(setUserData(userData))
  validatorInstance.setSuccessValidationState({ token, userData })

  history.push(ROUTE_HOMEPAGE)
}

export const performLogout = async (dispatch) => {
  await dispatch(clearTokenAtLogout())
  validatorInstance.resetValidation()
  history.push(ROUTE_LOGIN)
}
