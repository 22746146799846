export const addOrModifyItem = ({ id, match, name, level }) => {
  return {
    type: "BREADCRUMB_ADD_OR_MODIFY",
    payload: {
      id,
      match,
      name,
      level,
    },
  }
}

export const deleteItem = (id) => {
  return {
    type: "BREADCRUMB_DELETE",
    payload: id,
  }
}
