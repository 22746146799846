/* eslint-disable no-use-before-define */

import { setToken, clearToken } from "services/constants"
import getUserDataFromToken from "utils/auth/getUserDataFromToken"

export const START_INIT_VALIDATION = "USER_START_INIT_VALIDATION"
export const FINISH_INIT_VALIDATION = "USER_FINISH_INIT_VALIDATION"
export const FINISH_INIT_UPDATE_TOKEN = "USER_FINISH_INIT_UPDATE_TOKEN"
export const FINISH_INIT_CLEAR_TOKEN = "USER_FINISH_INIT_CLEAR_TOKEN"
export const SET_USER_DATA = "USER_SET_DATA"
export const SET_TOKEN = "USER_SET_TOKEN"
export const CLEAR_ALL_DATA = "USER_CLEAR_ALL_DATA"

// Synchronous actions

export const startInitTokenValidation = () => {
  return { type: START_INIT_VALIDATION }
}
export const finishInitTokenValidation = () => {
  return { type: FINISH_INIT_VALIDATION }
}
export const finishAndUpdateToken = (accessToken) => {
  return { type: FINISH_INIT_UPDATE_TOKEN, payload: accessToken }
}
export const finishAndClearToken = () => {
  return { type: FINISH_INIT_CLEAR_TOKEN }
}
export const setUserData = (userData) => {
  return { type: SET_USER_DATA, payload: userData }
}
export const setUserAccessToken = (accessToken) => {
  return { type: SET_TOKEN, payload: accessToken }
}
export const clearUserAndTokenData = () => {
  return { type: CLEAR_ALL_DATA }
}

// Asynchronous actions

export const setTokenFromSuccessLogin = (accessToken, userData) => {
  return async (dispatch) => {
    setStorageAndAxiosToken(accessToken)
    dispatch(finishAndUpdateToken(accessToken))

    if (userData) {
      dispatch(setUserData(userData))
    } else {
      const userDataFromToken = getUserDataFromToken(accessToken)
      dispatch(setUserData(userDataFromToken))
    }
  }
}

export const clearTokenAtLogout = () => {
  return async (dispatch) => {
    clearStorageAndAxiosToken()
    dispatch(finishAndClearToken())
  }
}

// Utils

const setStorageAndAxiosToken = (token) => {
  setToken(token)
  localStorage.setItem("accessToken", token)
}

const clearStorageAndAxiosToken = () => {
  clearToken()
  localStorage.removeItem("accessToken")
}
