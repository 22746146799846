/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react"
import * as PropTypes from "prop-types"
import { Button, Grid, makeStyles } from "@material-ui/core"
import useShowSnackbar from "utils/snackbar-hooks"
import { purgeInitialFormData } from "utils/form-data"

import InvoiceGeneralForm from "./InvoiceGeneralForm"
import InvoiceCustomerForm from "./InvoiceCustomerForm"
import InvoiceFormContext from "./InvoiceFormContex"
import InvoiceDescriptionForm from "./InvoiceDescriptionForm"
import { GENERAL_BLANK_FORMS } from "./const"
import TotalCostSection from "./TotalCostSection"

const useStyles = makeStyles({
  root: {
    minWidth: 600,
    marginBottom: "20px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      marginLeft: "10px",
      display: "block",
    },
  },
  selectField: {
    width: "100%",
  },
  switchLabel: {
    marginTop: "10px",
  },
})

function InvoiceForm({
  onCancel,
  onSubmit,
  fetchDeliveryNoteData,
  fetchCurrencyData,
  fetchCustomerData,
  fetchAuthorizedSignatureData,
  isUpdate,
  initialValue,
  fetchInitDeliveryNoteData = () => [],
  fetchSalesQuotationData = () => [],
  fetchPOData = () => [],
  fetchInitPOData = () => [],
  fetchInitSalesQuotationData,
}) {
  const classes = useStyles()

  const [formData] = useState(() =>
    purgeInitialFormData(initialValue, GENERAL_BLANK_FORMS)
  )
  const [invoiceType, setInvoiceType] = useState(formData.invoiceType)
  const [productDescriptions, setProductDescriptions] = useState(
    initialValue?.productDescriptions ?? []
  )
  const [invoiceDate, setInvoiceDate] = useState(formData.date)
  const [source, setSource] = useState(formData.source)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [paymentPercentage, setPaymentPercentage] = useState(
    formData.paymentPercentage
  )
  const [rateIn, setRateIn] = useState(initialValue?.rateIn ?? null)

  const [currencyRate, setCurrencyRate] = useState(
    initialValue?.currencyRate ?? 1
  )
  const [selectedCustomer, setSelectedCustomer] = React.useState(
    initialValue?.customer ?? null
  )

  const [showSnackbar] = useShowSnackbar()

  const formProps = {
    isUpdate,
    initialValue,
  }

  const generalFormRef = useRef()
  const generalForm = (
    <InvoiceGeneralForm
      ref={generalFormRef}
      fetchDeliveryNoteData={fetchDeliveryNoteData}
      fetchAuthorizedSignatureData={fetchAuthorizedSignatureData}
      {...formProps}
    />
  )

  const customerFormRef = useRef()
  const customerForm = (
    <InvoiceCustomerForm
      ref={customerFormRef}
      fetchCurrencyData={fetchCurrencyData}
      {...formProps}
      fetchCustomerData={fetchCustomerData}
      fetchPoData={fetchPOData}
    />
  )

  const descriptionFormRef = useRef()
  const descriptionForm = (
    <InvoiceDescriptionForm
      ref={descriptionFormRef}
      {...formProps}
      fetchDeliveryNoteData={fetchDeliveryNoteData}
      fetchSalesQuotationData={fetchSalesQuotationData}
      fetchPOData={fetchPOData}
      fetchInitPOData={fetchInitPOData}
      fetchInitDeliveryNoteData={fetchInitDeliveryNoteData}
      fetchInitSalesQuotationData={fetchInitSalesQuotationData}
    />
  )

  const totalSectionRef = useRef()

  const submitForm = async (e) => {
    e.preventDefault()
    try {
      await Promise.all([
        generalFormRef.current.submitForm(),
        customerFormRef.current.submitForm(),
        descriptionFormRef.current.submitForm(),
      ])
    } catch (err) {
      console.log(err)
      return
    }

    const data = {
      ...generalFormRef.current.getFormData(),
      ...customerFormRef.current.getFormData(),
      ...descriptionFormRef.current.getFormData(),
      ...totalSectionRef.current.getFormData(),
    }
    if (data.productDescriptions.length === 0) {
      showSnackbar("Product descriptions is required")
      return
    }

    onSubmit(data)
  }

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={2}>
        <InvoiceFormContext.Provider
          value={{
            invoiceType,
            setInvoiceType,
            productDescriptions,
            setProductDescriptions,
            selectedCurrency,
            setSelectedCurrency,
            paymentPercentage,
            setPaymentPercentage,
            currencyRate,
            setCurrencyRate,
            selectedCustomer,
            setSelectedCustomer,
            rateIn,
            setRateIn,
            source,
            setSource,
            invoiceDate,
            setInvoiceDate,
          }}
        >
          {generalForm}
          {customerForm}
          {descriptionForm}

          <TotalCostSection ref={totalSectionRef} {...formProps} />
        </InvoiceFormContext.Provider>
      </Grid>
      <div className={classes.buttonWrapper} style={{ marginTop: "20px" }}>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </div>
    </form>
  )
}

InvoiceForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchDeliveryNoteData: PropTypes.func.isRequired,
  fetchCurrencyData: PropTypes.func.isRequired,
  fetchCustomerData: PropTypes.func.isRequired,
  fetchAuthorizedSignatureData: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.object,
}

InvoiceForm.defaultProps = {
  isUpdate: false,
  initialValue: {},
}

export default InvoiceForm
