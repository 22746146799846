import { makeStyles } from "@material-ui/core"

export const useBaseFormStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
    flexGrow: 1,
  },
  rowNonInput: {
    paddingTop: "14px",
  },
  table: {
    "& > * > *": {
      verticalAlign: "top",
    },
  },
  fieldsWrapper: {
    "& > *": {
      display: "block",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      marginLeft: "10px",
      display: "block",
    },
  },
  formSectionTitle: {
    color: "#0449b0",
    fontSize: theme.typography.h6.fontSize,
    marginTop: "1rem",
  },
  salesFieldContainer: {
    display: "flex",
    flexFlow: "row",
    maxWidth: "450px",
    alignItems: "center",
  },
  salesField: {
    flexGrow: 1,
    marginRight: "10px",
  },
  assignSalesButton: {
    borderColor: "#673AB7",
  },
  errorLabel: {
    lineHeight: 1.2,
  },
  inputCurrencyValue: {
    marginTop: theme.spacing(1.5),
  },
}))

export const GENERAL_BLANK_FORM = {
  customerId: null,
  slsQuotId: null,
  workOrderTypeId: null,
  status: "",
  madeBy: "",
  acknowledgeBy: "Shigeki Tashiro",
  approvedBy: "Andrian",
  woDate: "",
  projectName: "",
}

export const ESTIMATE_BLANK_FORM = {
  estimateList:
    [
      {
        idWorkOrderEstimate: 0,
        slsEstimateId: 0,
        mouldCode: "",
        dueDateFinish: "",
        dueDateDelivery: "",
      },
    ] && [],
}

export const WORK_ORDER_BLANK_FORM = {
  ...GENERAL_BLANK_FORM,
  ...ESTIMATE_BLANK_FORM,
}
