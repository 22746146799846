class EventDispatcher {
  eventsListeners = {}

  addListener(event, callback) {
    if (typeof callback !== "function") {
      console.error("Callback must be a function")
      return false
    }

    if (typeof event !== "string") {
      console.error("Event must be a string")
      return false
    }

    if (this.eventsListeners[event] === undefined) {
      this.eventsListeners[event] = [callback]
    } else {
      this.eventsListeners[event].push(callback)
    }
    return true
  }

  removeListener(event, callback) {
    if (typeof callback !== "function") {
      console.error("Callback must be a function")
      return false
    }

    if (typeof event !== "string") {
      console.error("Event must be a string")
      return false
    }
    const listeners = this.eventsListeners[event]
    if (listeners === undefined) {
      return true
    }
    const callbackIndex = listeners.indexOf(callback)
    if (callbackIndex >= 0) {
      listeners.splice(callbackIndex, 1)
    }
    return true
  }

  dispatchEvent(event, ...data) {
    if (this.eventsListeners[event] === undefined) {
      return false
    }
    this.eventsListeners[event].forEach((listener) =>
      listener.apply(this, data)
    )
    return true
  }
}

export default EventDispatcher
