/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import {
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core"
import { simplifyInvoiceQuotItems } from "components/delivery-note/utils"
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { transformProperty } from "utils/objects"
import InvoiceDownPaymentRow from "./InvoiceDownPaymentRow"
import InvoiceFormContext from "./InvoiceFormContex"
import SalesQuotationPicker from "./SalesQuotationPicker"
import InvoiceDescriptionFormContext from "./InvoiceDescriptionFormContext"
import { getAllIndexes } from "./utils"

const InvoiceExportTable = forwardRef(
  (
    {
      fetchSalesQuotationData = () => [],
      initialValue = {},
      fetchInitSalesQuotationData,
    },
    ref
  ) => {
    const [initialFormData] = useState(() => {
      return (initialValue?.productDescriptions ?? []).map((item, index) => {
        return {
          __id: index,
          description: item.description,
          unitPrice: item.unitPrice,
          quantity: item.quantity,
          unit: item.unit,
          slsQuot: item.slsEstimate?.slsQuot,
          slsEstimateId: item.slsEstimateId,
          totalCost: item.unitPrice,
          remark: item.remark,
          paymentPercentage: item.paymentPercentage,
        }
      })
    })

    const { setProductDescriptions } = useContext(InvoiceFormContext)

    const { onAddInvoiceDetail, onRemoveInvoiceDetail } = useContext(
      InvoiceDescriptionFormContext
    )

    const { register, control, errors, getValues, setValue } = useForm({
      defaultValues: { productDescriptions: initialFormData },
    })

    const { fields } = useFieldArray({
      control,
      name: "productDescriptions",
      keyName: "__id",
    })

    const lastIdRef = useRef(fields.length)

    const [selectedSlsQuotations, setSelectedSlsQuotations] = useState([])
    const [deletedSlsEstimates, setDeletedSlsEstimates] = useState([])
    const [initiateSlsEstimates] = useState(() => {
      if (!initialValue?.productDescriptions) return []

      return Array.from(
        new Set(
          initialValue.productDescriptions.map((item) => item.slsEstimateId)
        )
      )
    })

    useEffect(() => {
      let active = true

      ;(async () => {
        const idSalesQuotations = Array.from(
          new Set(
            (initialValue?.productDescriptions ?? []).map(
              (item) => item.slsEstimate?.slsQuotId
            )
          )
        )
        const response = await fetchInitSalesQuotationData({
          idSalesQuotations,
        })
        if (!active) return

        setSelectedSlsQuotations(response)
      })()

      return () => {
        active = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
      const temp = []
      const prevValues = getValues().productDescriptions || []

      selectedSlsQuotations.forEach((curr) => {
        const currentList = curr.estimateList || []

        currentList
          .filter((estList) => {
            if (initiateSlsEstimates.includes(estList.idSalesEstimate)) {
              return !deletedSlsEstimates.includes(estList.idSalesEstimate)
            }
            return estList.paymentStatus !== 3
          })
          .forEach((estList) => {
            const { totalCost } = simplifyInvoiceQuotItems([estList])[0]
            const current = prevValues.filter(
              (item) => +item.slsEstimateId === estList.idSalesEstimate
            )[0]
            const fromInit = initialFormData.filter(
              (item) => item.slsEstimateId === estList.idSalesEstimate
            )[0]
            const obj = {
              ...estList,
              itemKey: current?.itemKey ?? lastIdRef.current,
              description:
                current?.description ||
                fromInit?.description ||
                estList.description,
              remark: current?.remark || fromInit?.remark || "",
              paymentPercentage: current?.paymentPercentage ?? "",
              slsEstimateId: estList.idSalesEstimate,
              totalCost: estList.totalAmount || totalCost,
              unitPrice: estList.totalAmount || totalCost,
              slsQuot: {
                slsQuotNumber: curr.slsQuotNumber,
                poNumber: estList.slsPurchaseOrder?.poNumber,
              },
            }

            if (!current?.itemKey) {
              lastIdRef.current += 1
            }
            temp.push(obj)
          })
      })
      setProductDescriptions(temp)
      setValue("productDescriptions", temp)

      temp.forEach((val) => {
        setTimeout(() => {
          onAddInvoiceDetail({ key: val.itemKey })
        }, 250)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSlsQuotations])

    const onDeleteItem = (index) => {
      const curr = fields[index]
      const arrCopy = [...deletedSlsEstimates]
      arrCopy.push(curr)

      const selectedSlsQuotCopy = selectedSlsQuotations
        .map((sq) => {
          const { estimateList } = sq
          const newEstimateList = estimateList.filter(
            (est) => est.idSalesEstimate !== curr.slsEstimateId
          )
          return {
            ...sq,
            estimateList: newEstimateList,
          }
        })
        .filter((sq) => sq.estimateList.length)

      setDeletedSlsEstimates(
        arrCopy.filter((se) => {
          const currSq = selectedSlsQuotCopy.filter(
            (sq) => sq.slsQuotNumber === se.slsQuot.slsQuotNumber
          )[0]
          return currSq
        })
      )
      setSelectedSlsQuotations(selectedSlsQuotCopy)
    }

    useImperativeHandle(ref, () => ({
      getFormData: () => {
        const data = getValues()
        const dataCopy = (data?.productDescriptions ?? []).map((product) => {
          transformProperty(
            product,
            ["quantity", "slsEstimateId", "sequence", "itemKey"],
            parseInt
          )
          transformProperty(product, ["unitPrice"], parseFloat)
          // eslint-disable-next-line no-param-reassign
          if (!product.dnDetailId) delete product.dnDetailId
          // eslint-disable-next-line no-param-reassign
          if (!product.quantity) delete product.quantity
          // eslint-disable-next-line no-param-reassign
          if (!product.paymentPercentage) delete product.paymentPercentage
          else transformProperty(product, ["paymentPercentage"], parseFloat)
          return product
        })
        return { productDescriptions: dataCopy }
      },
      submitForm: () => {},
    }))

    const onAddSlsQuotation = (slsQuot) => {
      const dataCopy = [...selectedSlsQuotations]
      dataCopy.push(slsQuot)
      setSelectedSlsQuotations(dataCopy)
    }

    const onDeleteSlsQuotation = (slsQuotNumber) => {
      const dataCopy = [...selectedSlsQuotations]
      const newData = dataCopy.filter((item) => item !== slsQuotNumber)
      const deletedCopy = [...deletedSlsEstimates]
      const newDeleted = deletedCopy.filter(
        (item) => item.slsQuot.slsQuotNumber !== slsQuotNumber
      )

      const deletedIndexes = getAllIndexes(
        fields,
        slsQuotNumber,
        (curr, val) => {
          if (typeof dnNumber === "string") return curr?.dnNumber === val
          return curr?.dnNumber === val?.dnNumber
        }
      )

      deletedIndexes.forEach((idx) => {
        setTimeout(() => {
          onRemoveInvoiceDetail({ index: fields[idx].itemKey })
        }, 250)
      })
      setDeletedSlsEstimates(newDeleted)
      setSelectedSlsQuotations(newData)
    }

    useEffect(() => {
      setValue("productDescriptions", initialFormData)
      setProductDescriptions(initialFormData)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: "9rem" }}>
                  Sls Quot number
                </TableCell>
                <TableCell>PO Number</TableCell>
                <TableCell style={{ minWidth: "24rem" }}>Description</TableCell>
                <TableCell style={{ width: "7rem" }}>Quantity</TableCell>
                <TableCell style={{ width: "7rem" }}>UoM</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>%</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Sequence</TableCell>
                <TableCell>Remark</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.length === 0 ? (
                <TableRow>
                  <TableCell style={{ textAlign: "center" }} colSpan={9}>
                    No Data
                  </TableCell>
                </TableRow>
              ) : null}
              {fields.map((item, index) => {
                return (
                  <InvoiceDownPaymentRow
                    key={item.__id}
                    register={register}
                    errors={errors}
                    item={item}
                    index={index}
                    showRemark
                    showDelete
                    onDeleteButtonClick={onDeleteItem}
                    showPercentage
                    showAdditionalCostButton
                  />
                )
              })}
            </TableBody>
            <TableFooter>
              <SalesQuotationPicker
                onAddItem={onAddSlsQuotation}
                selectedSalesQuotations={selectedSlsQuotations}
                onDeleteItem={onDeleteSlsQuotation}
                fetchSalesQuotationData={fetchSalesQuotationData}
                label="Quotation Number"
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    )
  }
)

export default InvoiceExportTable
