import React, { useEffect, useRef, useState } from "react"
import { ReactComponent as OrnamentBottom } from "assets/ornament-bottom.svg"
import { ReactComponent as OrnamentLeft } from "assets/ornament-left.svg"
import { ReactComponent as OrnamentRight } from "assets/ornament-right.svg"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import Typography from "@material-ui/core/Typography"
import InputLabel from "@material-ui/core/InputLabel"

import CompanyLogo from "assets/logo-main.png"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  page: {
    background:
      "linear-gradient(133.91deg, #00FFD1 8.37%, #8CC2E8 57.55%, #C3D6E2 80.89%, #DDDDDD 88.39%), #DDDDDD",
    minWidth: "100vw",
    minHeight: "100vh",
    "& > .card-root": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
    },
  },
  cardContent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch",
    padding: "30px",
    "& .notice": {
      margin: "10px 0px",
    },
  },
  cardForm: {
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch",
    "& button": {
      marginTop: "24px",
    },
  },
  ornaments: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    "& > *": {
      position: "absolute",
    },
    "& .left": {
      left: "0",
      top: "40%",
      transform: "translateY(-50%)",
    },
    "& .right": {
      left: "100%",
      top: "100%",
      transform: "translate(-60% , -60%)",
    },
    "& .bottom": {
      top: "100%",
      right: "80%",
      transform: "translate(50%, -100%)",
    },
  },
  formControl: {
    marginBottom: "10px",
  },
  companyLogo: {
    height: "auto",
    width: "100%",
  },
  companyLogoContainer: {
    width: "100%",
    maxWidth: "180px",
    margin: "0 auto 10px",
  },
  notice: {
    minHeight: "27px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  errorMessage: {
    color: "red",
  },
  successMessage: {
    color: "green",
  },
})

const LoginPageDisplay = ({
  onSubmit,
  errorMessage,
  loading,
  success,
  // eslint-disable-next-line react/prop-types
  fetchCompanyInformation = null,
}) => {
  const usernameRef = useRef()
  const passwordRef = useRef()

  const [companyInformation, setCompanyInformation] = useState(null)
  useEffect(() => {
    let active = true

    const fetchData = async () => {
      const response = await fetchCompanyInformation()
      if (!active) return
      setCompanyInformation(response.data)
    }

    if (fetchCompanyInformation) {
      fetchData()
    }

    return () => {
      active = false
    }
  }, [fetchCompanyInformation])

  const classes = useStyles()

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit({
      username: usernameRef.current.querySelector("input").value,
      password: passwordRef.current.querySelector("input").value,
    })
  }

  const loginErrorNotice = errorMessage ? (
    <div className={classes.errorMessage}>{errorMessage}</div>
  ) : null
  const loadingNotice = loading ? (
    <CircularProgress size="24px" thickness={5} />
  ) : null
  const successNotice = success ? (
    <div className={classes.successMessage}>Log in successful.</div>
  ) : null
  const notice = loadingNotice || loginErrorNotice || successNotice

  return (
    <div className={clsx(classes.page, "page-container")}>
      <div className={classes.ornaments}>
        <OrnamentBottom className="bottom" />
        <OrnamentLeft className="left" />
        <OrnamentRight className="right" />
      </div>
      <Card
        classes={{
          root: "card-root",
        }}
        elevation={10}
        className={classes.root}
      >
        <div className={classes.cardContent}>
          <div className={classes.companyLogoContainer}>
            <img
              className={classes.companyLogo}
              src={CompanyLogo}
              alt="Company Logo"
            />
          </div>
          <Typography
            style={{ textAlign: "center", fontWeight: "bold" }}
            variant="h6"
          >
            Log in to Manuflex application
          </Typography>
          {companyInformation && (
            <Typography style={{ textAlign: "center", marginTop: "8px" }}>
              {companyInformation?.companyName}
            </Typography>
          )}

          <div className={clsx(classes.notice, "notice")}>{notice}</div>

          <form
            className={classes.cardForm}
            // classes={{
            //   root: classes.formControl,
            // }}
            onSubmit={handleSubmit}
          >
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="username">Username</InputLabel>
              <Input ref={usernameRef} id="username" />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                type="password"
                ref={passwordRef}
                id="password"
                autoComplete="on"
              />
            </FormControl>

            <Button
              color="primary"
              variant="contained"
              disableElevation
              style={{ textTransform: "none" }}
              type="submit"
            >
              Log In
            </Button>
          </form>
        </div>
      </Card>
    </div>
  )
}

LoginPageDisplay.propTypes = {
  onSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.bool,
}

LoginPageDisplay.defaultProps = {
  onSubmit: () => {},
  errorMessage: "",
  loading: false,
  success: false,
}

export default LoginPageDisplay
