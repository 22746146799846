import PropTypes from "prop-types"
import React, { useState, useEffect, useContext, useMemo } from "react"
import isEqual from "lodash/isEqual"
import { useDispatch } from "react-redux"
import usePrevious from "utils/use-previous"
import { addOrModifyItem, deleteItem } from "state/breadcrumb/actions"

import BreadcrumbContext from "./BreadcrumbContext"

let cnt = 0
const getCnt = () => {
  cnt += 1
  return cnt
}

/**
 * NOTE: matchArgs is the same as props related to path matching that
 *       is passed into <Route />
 * @param {*} param0
 */

const BreadcrumbItem = ({
  name: propName,
  level: propLevel,
  children,
  ...matchArgs
}) => {
  const [id] = useState(getCnt)
  const dispatch = useDispatch()
  const prevMatch = usePrevious(matchArgs)

  // Compare match object deeply since it match result can be different
  // every time route is rendering children.
  const usedMatch = isEqual(matchArgs, prevMatch) ? prevMatch : matchArgs

  const parentContext = useContext(BreadcrumbContext)
  const parentLevel = parentContext ? parentContext.level : -1
  const level = typeof propLevel === "number" ? propLevel : parentLevel + 1

  const [nameFromChild, setNameFromChild] = useState()
  const name = nameFromChild || propName

  const contextValue = useMemo(() => {
    return {
      id,
      level,
      setRouteName: setNameFromChild,
    }
  }, [id, level, setNameFromChild])

  // if link or name or level changes depply, dispatch modify array.
  useEffect(() => {
    dispatch(addOrModifyItem({ id, match: usedMatch, name, level }))
  }, [dispatch, usedMatch, name, level, id])

  useEffect(() => {
    return () => {
      dispatch(deleteItem(id))
    }
  }, [dispatch, id])

  return (
    <BreadcrumbContext.Provider value={contextValue}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

BreadcrumbItem.propTypes = {
  children: PropTypes.node,
  level: PropTypes.number,
  name: PropTypes.node,
}

BreadcrumbItem.defaultProps = {
  children: null,
  level: null,
  name: null,
}

export default BreadcrumbItem
