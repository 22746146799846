import React, { useCallback, useMemo, useState } from "react"
import useCheckPermission from "utils/auth/useCheckPermission"
import PERMISSION_CODES from "constants/permission-codes"
import { Route, Switch, useHistory } from "react-router-dom"
import { useWrapHandleInvalidToken } from "utils/invalid-token"
import ApiWorkOrder from "services/api-work-order"
import useDatatablesFetchHelper from "utils/datatables/useDatatablesFetchHelper"
import { ROUTE_WORK_ORDER } from "constants/routeConstants"
import BasePage from "components/common/BasePage"
import NotAllowedNotice from "components/NotAllowedNotice"
import BaseTableNew from "components/common/BaseTableNew"
import { makeStyles, Button, Paper, Box } from "@material-ui/core"
import WorkOrderFilter from "components/work-order/WorkOrderFilter"
import RouteBreadcrumb from "components/page-layout/breadcrumbs/RouteBreadcrumb"
import FilterDatatable from "components/common/FilterDatatable"
import ApiCustomer from "services/api-customer"
import WorkOrderCreatePage from "./WorkOrderCreatePage"
import WorkOrderItemPage from "./WorkOrderItemPage"

const useStyles = makeStyles((theme) => ({
  filterHeaderTitle: {
    color: theme.palette.sectionTitle.main,
  },
  createButton: {
    margin: "16px",
    marginRight: 0,
    marginLeft: "auto",
    display: "block",
  },
  pageTitle: {
    color: theme.palette.sectionTitle.main,
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  materialAutocomplete: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
  filterBox: {
    border: `1px solid #bccce3`,
    padding: theme.spacing(1),
    borderRadius: 5,
    marginBottom: theme.spacing(3),
  },
  filterPickerContainer: {
    display: "flex",
    marginBottom: theme.spacing(-1),
  },
  filterChips: {
    marginTop: theme.spacing(1),
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  materialChip: {
    backgroundColor: "#673ab7",
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "#ffffffa8",
    },
  },
  // Remove table shadow manually
  table: {
    "& .MuiPaper-root": {
      boxShadow: "none !important",
    },
    "& tr > th": {
      borderBottom: "1px solid black",
      lineHeight: 1,
    },
    // Remove bottom border in footer
    "& .MuiTableFooter-root tr > *": {
      borderBottom: "none",
    },
  },
  filterMaterialLoading: {
    position: "absolute",
    right: 16,
  },
  launchIcon: {
    position: "relative",
    top: 3,
    left: 2,
    fontSize: theme.typography.body1.fontSize,
  },
  linkContainer: {
    position: "relative",
  },
  linkLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}))

const fetchCustomerData = async (searchText) =>
  (await ApiCustomer.get({ searchText, limit: 10, fromColumns: ["name"] })).data
const fetchWorkOrderTypeData = async (searchText) =>
  (
    await ApiWorkOrder.getWorkOrderType({
      searchText,
      fromColumns: ["workOrderTypeName"],
      limit: 10,
    })
  ).data

export default function WorkOrderListPage() {
  const history = useHistory()
  const classes = useStyles()

  const { checkWithoutUpdateState } = useCheckPermission()
  const [fromDate, setFromDate] = useState("")
  const [untilDate, setUntilDate] = useState("")
  const [params, setParams] = useState({})

  const canCreateItem = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.WRITE_WORK_ORDER],
      }),
    [checkWithoutUpdateState]
  )

  const wrappedFetchItems = useWrapHandleInvalidToken(
    ApiWorkOrder.getList,
    () => []
  )
  const wrappedFetchWoTypeData = useWrapHandleInvalidToken(
    ApiWorkOrder.getWorkOrderType,
    () => []
  )
  const [workOrderType, setWorkOrderType] = useState(null)

  const getParams = useCallback(() => {
    const paramsCopy = {}
    if (workOrderType) {
      paramsCopy.workOrderTypeId = parseInt(workOrderType.idWorkOrderType, 10)
    }
    if (fromDate) {
      paramsCopy.fromDate = fromDate
    }
    if (untilDate) {
      paramsCopy.untilDate = untilDate
    }
    return { ...paramsCopy, ...params }
  }, [workOrderType, fromDate, untilDate, params])
  const { options: helperOptions, data, loading } = useDatatablesFetchHelper({
    fetchData: wrappedFetchItems,
    getParams,
  })

  const columnSettings = useMemo(
    () => [
      { name: "woNumber", label: "WO Number" },
      {
        name: "customerName",
        label: "Customer",
        options: {
          customBodyRenderLite: (index) => {
            const item = data[index]
            return `${
              item.salesQuotation?.slsTargetCustomer?.name ??
              item?.customer?.name ??
              "-"
            }`
          },
        },
      },
      {
        name: "poNumber",
        label: "PO Number",
        options: {
          customBodyRenderLite: (index) => {
            const item = data[index]
            let poNumber = []
            item.estimateList.map((est) => {
              poNumber.push(
                est?.salesEstimate?.slsPurchaseOrder?.poNumber ?? "-"
              )
              return 1
            })
            poNumber = Array.from(new Set(poNumber.map((number) => number)))
            return poNumber.join(" | ")
          },
        },
      },
      {
        name: "estimateNumber",
        label: "Est Quot. Number",
        options: {
          searchable: false,
          customBodyRenderLite: (index) => {
            const items = data[index]
            const arr = items.estimateList.map((item) => {
              const text = `${item.salesEstimate?.estQuot?.estimationNumber}`
              return text
            })

            return Array.from(new Set(arr.map((a) => a))).join(", ")
          },
        },
      },
      {
        name: "slsQuotNumber",
        label: "Sls Quot. Number",
        options: {
          searchable: false,
          customBodyRenderLite: (index) => {
            const items = data[index]
            const arr = items.estimateList.map((item) => {
              const text = `${item.salesEstimate?.slsQuot?.slsQuotNumber}`
              return text
            })

            return Array.from(new Set(arr.map((a) => a))).join(", ")
          },
        },
      },
      {
        name: "project",
        label: "Project",
        options: {
          searchable: false,
          customBodyRenderLite: (index) => {
            const items = data[index]
            const arr = items.estimateList.map((item) => {
              const text = `${item.description} (${item.mouldCode})`
              return text
            })

            return arr.join(", ")
          },
        },
      },
      {
        name: "workOrderType",
        label: "Type",
        options: {
          searchable: false,
          customBodyRenderLite: (index) => {
            const items = data[index]
            return items?.workOrderType?.workOrderTypeName
          },
        },
      },
      {
        name: "warrantyStatus",
        label: "Warranty Status",
        options: {
          searchable: false,
          sortable: false,
          customBodyRenderLite: (index) => {
            const item = data[index]

            if (item?.workOrderType?.workOrderTypeName !== "Warranty")
              return null

            const isClosed = item?.isWarrantyClosed

            return (
              <span
                style={{
                  backgroundColor: "white",
                  display: "block",
                  padding: "2px",
                  borderRadius: "50px",
                  textAlign: "center",
                }}
              >
                {isClosed ? "Open" : "Closed"}
              </span>
            )
          },
        },
      },
    ],
    [data]
  )

  const setRowProps = useCallback(
    (row, dataIndex, rowIndex) => {
      const item = data[rowIndex]

      if (item?.workOrderType?.workOrderTypeName !== "Warranty") return {}

      if (item?.isWarrantyClosed) {
        return {
          style: {
            backgroundColor: "red",
          },
        }
      }

      return {}
    },
    [data]
  )

  const goToItemAtIndex = (_, { dataIndex }) => {
    const item = data[dataIndex]
    window.open(`${ROUTE_WORK_ORDER}/${item.idWorkOrder}`)
  }

  const options = {
    ...helperOptions,
    sort: false,
    onRowClick: goToItemAtIndex,
    setTableProps: () => {
      return {
        size: "small",
      }
    },
    setRowProps,
  }

  const PAGE_PERMISSIONS = [PERMISSION_CODES.READ_WORK_ORDER]

  const allowed = useMemo(
    () => checkWithoutUpdateState({ permissions: PAGE_PERMISSIONS }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkWithoutUpdateState]
  )

  if (!allowed) {
    return (
      <BasePage maxWidth={800}>
        <NotAllowedNotice />
      </BasePage>
    )
  }

  const initiateCreateItem = () => {
    history.push(`${ROUTE_WORK_ORDER}/create`)
  }

  return (
    <Paper style={{ maxWidth: 1300 }}>
      <Box p={2}>
        <Switch>
          <Route exact path={ROUTE_WORK_ORDER}>
            <WorkOrderFilter
              fetchWoTypeData={wrappedFetchWoTypeData}
              classes={classes}
              onWorkOrderTypeChange={(val) => setWorkOrderType(val)}
              onDateChange={(payload) => {
                setFromDate(payload.fromDate)
                setUntilDate(payload.untilDate)
              }}
            />
            <FilterDatatable
              columns={[
                { key: "woNumber", label: "WO Number", type: "text" },
                {
                  key: "customerId",
                  label: "Customer",
                  type: "list",
                  fetchData: fetchCustomerData,
                  getOptionLabel: (opt) => opt.name,
                  getOptionSelected: (opt, val) => opt.idCust === val?.idCust,
                  getOptionValue: (opt) => opt?.idCust,
                },
                { key: "poNumber", label: "PO Number", type: "text" },
                {
                  key: "estQuotNumber",
                  label: "EQ Number",
                  type: "text",
                },
                {
                  key: "slsQuotNumber",
                  label: "SQ Number",
                  type: "text",
                },
                {
                  key: "project",
                  label: "Project",
                  type: "text",
                },
                {
                  key: "workOrderTypeId",
                  label: "Type",
                  type: "list",
                  fetchData: fetchWorkOrderTypeData,
                  getOptionLabel: (opt) => opt.workOrderTypeName,
                  getOptionSelected: (opt, val) =>
                    opt.idWorkOrderType === val?.idWorkOrderType,
                  getOptionValue: (opt) =>
                    opt ? parseInt(opt?.idWorkOrderType, 10) : null,
                },
              ]}
              onChange={setParams}
            />
            <BaseTableNew
              data={data}
              columns={columnSettings}
              options={options}
              loading={loading}
              onClick={goToItemAtIndex}
            />
            {canCreateItem && (
              <Button
                className={classes.createButton}
                variant="contained"
                disableElevation
                color="primary"
                onClick={initiateCreateItem}
              >
                Create New Work Order
              </Button>
            )}
          </Route>
          <RouteBreadcrumb
            path={`${ROUTE_WORK_ORDER}/create`}
            component={WorkOrderCreatePage}
            name="Create Work Order"
          />
          <RouteBreadcrumb
            name="Work Order Detail"
            path={`${ROUTE_WORK_ORDER}/:id`}
            component={WorkOrderItemPage}
          />
        </Switch>
      </Box>
    </Paper>
  )
}
