// Copied from: https://usehooks.com/usePrevious/
import { useRef, useEffect } from "react"

/** use previous value */
function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default usePrevious
