/* eslint-disable react/prop-types */
import { yupResolver } from "@hookform/resolvers"
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import * as t from "prop-types"
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { useForm, Controller } from "react-hook-form"
import { transformProperty } from "utils/objects"
import * as yup from "yup"
import { purgeInitialFormData } from "utils/form-data"
import InvoiceFormContext from "./InvoiceFormContex"
import { getDefaultSettings } from "./utils"
import { GENERAL_BLANK_FORMS } from "./const"

const schema = yup.object().shape({
  invoiceType: yup.string().required("This field is required"),
  date: yup.string().required(),
  authorizedSignatureId: yup.number().required("This field is required"),
  remarks: yup.string(),
  paymentTerms: yup.string(),
  // invoiceNumber: yup.string().required("This field is required"),
})

const InvoiceGeneralForm = forwardRef(
  ({ fetchAuthorizedSignatureData, initialValue, isUpdate }, ref) => {
    const [authorizedSignatures, setAuthorizedSignatures] = useState([])
    const [
      selectedAuthorizedSignature,
      setSelectedAuthorizedSignature,
    ] = useState(null)

    const [formData] = useState(() =>
      purgeInitialFormData(initialValue, GENERAL_BLANK_FORMS)
    )

    const {
      register,
      errors,
      control,
      setValue,
      getValues,
      handleSubmit,
    } = useForm({
      resolver: yupResolver(schema),
      defaultValues: formData,
    })

    const {
      invoiceType,
      setInvoiceType,
      setPaymentPercentage,
      setCurrencyRate,
      source,
      setSource,
      invoiceDate,
      setInvoiceDate,
      rateIn,
    } = useContext(InvoiceFormContext)

    const firstRenderRef = useRef(1)

    React.useLayoutEffect(() => {
      if (firstRenderRef.current !== 1) {
        setPaymentPercentage(null)
        setValue("paymentPercentage", null)
      }
      firstRenderRef.current += 1
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceType])

    React.useEffect(() => {
      if (firstRenderRef.current > 2) {
        setInvoiceDate("")
      }
      firstRenderRef.current += 1
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source, invoiceType, rateIn])

    React.useEffect(() => {
      let active = true

      const fetchData = async () => {
        const response = await fetchAuthorizedSignatureData()

        if (!active) return
        setAuthorizedSignatures(response)
      }

      fetchData()
      return () => {
        active = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useImperativeHandle(ref, () => ({
      getFormData: () => {
        const data = getValues()
        transformProperty(
          data,
          ["idDeliveryNote", "authorizedSignatureId"],
          parseInt
        )
        if (!data.idDeliveryNote) delete data.idDeliveryNote
        delete data.poNumber
        delete data.poDate
        delete data.refQuot

        if (!data.payment) delete data.payment
        if (!data.paymentPercentage) delete data.paymentPercentage
        if (!["DOWN PAYMENT", "INVOICE"].includes(data.invoiceType))
          delete data.source

        return data
      },
      submitForm: () => {
        return new Promise((res, rej) => handleSubmit(res, rej)())
      },
    }))

    React.useEffect(() => {
      const current = authorizedSignatures.filter(
        (item) => item.idAuthorizedSignature === formData.authorizedSignatureId
      )[0]

      if (current) {
        setSelectedAuthorizedSignature(current)
      }
    }, [authorizedSignatures, formData])

    const isDeliveryNote = React.useMemo(() => {
      return (
        ["INVOICE", "DOWN PAYMENT"].includes(invoiceType) &&
        source === "DELIVERY NOTE"
      )
    }, [source, invoiceType])

    return (
      <>
        <Grid item md={6}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            General Data
          </Typography>

          {isUpdate ? (
            <TextField
              label="Invoice Number"
              defaultValue={initialValue.invoiceNumber}
              disabled
              fullWidth
            />
          ) : null}
          <Controller
            control={control}
            name="invoiceType"
            defaultValue={formData.invoiceType || ""}
            render={({ onChange }) => {
              return (
                <FormControl fullWidth error={!!errors?.invoiceType}>
                  <InputLabel id="invoice-type-label">Type</InputLabel>
                  <Select
                    defaultValue={invoiceType || ""}
                    onChange={(e) => {
                      const { value } = e.target
                      setInvoiceType(value)
                      onChange(value)
                      setCurrencyRate(1)
                    }}
                    name="invoiceType"
                    id="invoice-type-select"
                    labelId="invoice-type-label"
                  >
                    <MenuItem value="INVOICE">Invoice</MenuItem>
                    <MenuItem value="INVOICE EXPORT">Invoice Export</MenuItem>
                    <MenuItem value="DEBIT NOTE">Debit Note</MenuItem>
                    <MenuItem value="DOWN PAYMENT">Down Payment</MenuItem>
                  </Select>
                  {!!errors?.invoiceType && (
                    <FormHelperText>
                      {errors?.invoiceType?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )
            }}
          />
          <TextField
            type="date"
            {...getDefaultSettings("date", "Date", register, errors)}
            InputLabelProps={{ shrink: true }}
            defaultValue={formData.date}
            InputProps={{ readOnly: isDeliveryNote }}
            value={invoiceDate}
            onChange={(e) => setInvoiceDate(e.target.value)}
          />
          <Grid container spacing={2}>
            <Grid item md={8}>
              <TextField
                disabled={invoiceType === "DEBIT NOTE"}
                {...getDefaultSettings("payment", "Payment", register, errors)}
                defaultValue={formData.payment}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                {...getDefaultSettings(
                  "paymentPercentage",
                  "(%)",
                  register,
                  errors
                )}
                onChange={(e) => {
                  setValue("paymentPercentage", e.target.value)
                  setPaymentPercentage(e.target.value)
                }}
                disabled={invoiceType === "DEBIT NOTE"}
                defaultValue={formData.paymentPercentage}
              />
            </Grid>
          </Grid>
          <Controller
            name="authorizedSignatureId"
            control={control}
            defaultValue={formData.authorizedSignatureId}
            render={({ onChange }) => {
              return (
                <Autocomplete
                  options={authorizedSignatures}
                  getOptionLabel={(opt) => opt.name}
                  getOptionSelected={(opt, val) => {
                    return opt.idAuthorizedSignature === val
                  }}
                  onChange={(e, val) => {
                    onChange(val?.idAuthorizedSignature)
                    setSelectedAuthorizedSignature(val)
                  }}
                  value={selectedAuthorizedSignature}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        margin="dense"
                        error={!!errors?.authorizedSignatureId}
                        helperText={errors?.authorizedSignatureId?.message}
                        label="Signed By"
                      />
                    )
                  }}
                />
              )
            }}
          />
          <TextField
            {...getDefaultSettings("remarks", "Remarks", register, errors)}
            defaultValue={formData.remarks}
            multiline
          />
          <TextField
            {...getDefaultSettings(
              "paymentTerms",
              "Payment Terms",
              register,
              errors
            )}
            defaultValue={formData.paymentTerms}
          />
          <TextField
            {...getDefaultSettings("notes", "Notes", register, errors)}
            multiline
            defaultValue={formData?.notes}
            InputProps={{ minRows: 3 }}
          />
          {["DOWN PAYMENT", "INVOICE"].includes(invoiceType) && (
            <Controller
              control={control}
              name="source"
              defaultValue={formData.source || ""}
              render={({ onChange }) => {
                return (
                  <FormControl fullWidth error={!!errors?.source}>
                    <InputLabel id="source-label">Source</InputLabel>
                    <Select
                      defaultValue={source || ""}
                      onChange={(e) => {
                        setSource(e.target.value)
                        onChange(e.target.value)
                      }}
                      name="source"
                      id="source-select"
                      labelId="source-label"
                    >
                      <MenuItem value="DELIVERY NOTE">Delivery Note</MenuItem>
                      <MenuItem value="PURCHASE ORDER">Purchase Order</MenuItem>
                    </Select>
                    {!!errors?.source && (
                      <FormHelperText>{errors?.source?.message}</FormHelperText>
                    )}
                  </FormControl>
                )
              }}
            />
          )}
        </Grid>
      </>
    )
  }
)

InvoiceGeneralForm.propTypes = {
  fetchDeliveryNoteData: t.func.isRequired,
  fetchAuthorizedSignatureData: t.func.isRequired,
}

export default InvoiceGeneralForm
