
import React from 'react'

import InputLabelMui from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'

export const useTextFieldStyles = makeStyles({
  labelShrinked: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
});

/** Extends InputLabel with bold italic when label is shrinked */

export const InputLabel = props => {
  const classes = useTextFieldStyles()
  return (<InputLabelMui {...props} classes={{shrink: classes.labelShrinked}}>{props.children}</InputLabelMui>)
}

InputLabel.muiName = InputLabelMui.muiName

export default InputLabel