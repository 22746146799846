import { useState, useCallback } from "react"

import { useSelector } from "react-redux"
import every from "lodash/every"

/**
 * `useCheckPermission` hook can be used to help check permission against
 * list of allowed endpoints from user global state. This hook returns
 * several attributes:
 * - `checking` Set to true if permission checking is being done.
 * - `initialized` Set to true if permission checking has been done at least once.
 * - `allowed` Result from checking.
 * - `check` check the page's required endpoint against allowed endpoints
 *    that the user can access. User must have all the required endpoints.
 */

const useCheckPermission = () => {
  const [checking, toggleChecking] = useState(false)
  const [initialized, toggleInitialized] = useState(false)
  const [allowed, toggleAllowed] = useState(false)

  const allowedEndpointsSet = useSelector(
    (state) => state.userReducer.allowedEndpoints
  )
  const resolvedPermissions = useSelector(
    (state) => state.userReducer.resolvedPermissions
  )

  const checkWithoutUpdateState = useCallback(
    (endpoints) => {
      let permissions = null
      const firstArg = endpoints
      if (!Array.isArray(firstArg) && firstArg) {
        // eslint-disable-next-line no-param-reassign
        endpoints = firstArg.endpoints
        permissions = firstArg.permissions
      }

      let endpointsSatisfied = true
      if (Array.isArray(endpoints)) {
        endpointsSatisfied = every(endpoints, (endpoint) =>
          allowedEndpointsSet.has(endpoint)
        )
      }

      let permissionsSatisfied = true
      if (Array.isArray(permissions)) {
        permissionsSatisfied = every(
          permissions,
          (permissionCode) => resolvedPermissions[permissionCode]?.allow
        )
      }

      return endpointsSatisfied && permissionsSatisfied
    },
    [allowedEndpointsSet, resolvedPermissions]
  )

  const check = useCallback(
    (endpoints) => {
      toggleChecking(true)

      const isAllowed = checkWithoutUpdateState(endpoints)
      toggleAllowed(isAllowed)
      toggleInitialized(true)
      toggleChecking(false)

      return isAllowed
    },
    [checkWithoutUpdateState]
  )

  return { checking, allowed, initialized, check, checkWithoutUpdateState }
}

export default useCheckPermission
