/* eslint-disable react/prop-types */
import BaseDialog from "components/common/BaseDialog"
import React, { useContext, useMemo } from "react"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core"
import { Add, Delete } from "@material-ui/icons"
import InvoiceDescriptionFormContext from "./InvoiceDescriptionFormContext"

const schema = yup.object({
  poNumber: yup.string().required("This field is required"),
  description: yup.string().required("This field is required"),
  quantity: yup.number().required("This field is required"),
  unit: yup.string().required("This field is required"),
  unitPrice: yup.number().required("This field is required"),
})

function InvoiceAdditionalCostDialog({ open, onClose }) {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const {
    selectedDetail,
    onAddInvoiceAdditionalCost,
    additionalCosts,
    onRemoveInvoiceAdditionalCost,
  } = useContext(InvoiceDescriptionFormContext)

  const values = useMemo(() => {
    if (!selectedDetail) return []

    const index = additionalCosts.findIndex(
      (item) => item.key === selectedDetail.itemKey
    )

    if (index < 0) return []

    return additionalCosts[index].additionalCosts
  }, [additionalCosts, selectedDetail])

  const reset = () => {
    setValue("poNumber", "")
    setValue("description", "")
    setValue("quantity", "")
    setValue("unitPrice", "")
    setValue("remark", "")
    setValue("unit", "")
  }

  const getFormAttribute = (name) => {
    const errObj = errors[name]
    return {
      inputRef: register,
      name,
      margin: "dense",
      variant: "outlined",
      fullWidth: true,
      error: !!errObj,
      helperText: errObj?.message,
    }
  }

  const qty = watch("quantity")
  const unitPrice = watch("unitPrice")

  const onSubmit = async () => {
    try {
      await Promise.all([new Promise((res, rej) => handleSubmit(res, rej)())])
    } catch (error) {
      console.log({ error })
      return
    }
    const val = getValues()
    onAddInvoiceAdditionalCost({ ...val, itemKey: selectedDetail?.itemKey })
    reset()
  }

  return (
    <BaseDialog
      dialogTitle="Additional Cost"
      open={open}
      onClose={() => {
        onClose()
        clearErrors()
      }}
      maxWidth="xxl"
      hideCancelButton
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>PO. Number</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>UoM</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Remark</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!values.length ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No data available
                </TableCell>
              </TableRow>
            ) : null}
            {values.map((val, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={i}>
                  <TableCell>{val.poNumber}</TableCell>
                  <TableCell>{val.description}</TableCell>
                  <TableCell>{val?.quantity}</TableCell>
                  <TableCell>{val?.unit}</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(val?.unitPrice)}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      val?.unitPrice * val.quantity
                    )}
                  </TableCell>
                  <TableCell>{val?.remark ?? ""}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        onRemoveInvoiceAdditionalCost({
                          itemKey: selectedDetail?.itemKey,
                          index: i,
                        })
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <TextField
                  {...getFormAttribute("poNumber")}
                  style={{ width: "10rem" }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  {...getFormAttribute("description")}
                  style={{ width: "10rem" }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  {...getFormAttribute("quantity")}
                  style={{ width: "5rem" }}
                  type="number"
                />
              </TableCell>
              <TableCell>
                <TextField
                  {...getFormAttribute("unit")}
                  style={{ width: "5rem" }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  {...getFormAttribute("unitPrice")}
                  style={{ width: "8rem" }}
                  type="number"
                />
              </TableCell>
              <TableCell>
                {new Intl.NumberFormat().format(qty * unitPrice)}
              </TableCell>
              <TableCell>
                <TextField
                  {...getFormAttribute("remark")}
                  style={{ width: "10rem" }}
                />
              </TableCell>
              <TableCell>
                <IconButton onClick={onSubmit}>
                  <Add />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </BaseDialog>
  )
}

export default InvoiceAdditionalCostDialog
