import React, { useEffect, useState } from "react"
import { performLogin } from "utils/user-auth"
import { useDispatch, useSelector } from "react-redux"
import { ROUTE_HOMEPAGE } from "constants/routeConstants"
import history from "app-history"
import LoginPageDisplay from "components/log-in/LoginPageDisplay"
import { useWrapHandleInvalidToken } from "utils/invalid-token"
import ApiCompanyInformation from "services/api-company-information"

const ERR_NO_ERROR = 0
const ERR_AUTH_FAILED = 1
const ERR_UNKNOWN = 2

const LoginPage = () => {
  const dispatch = useDispatch()
  const accessToken = useSelector((state) => state.userReducer.accessToken)

  const [errorCode, setErrorCode] = useState(ERR_NO_ERROR)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const wrappedFetchCompanyItems = useWrapHandleInvalidToken(
    ApiCompanyInformation.get
  )

  let errorMessage = null
  switch (errorCode) {
    case ERR_AUTH_FAILED:
      errorMessage = <>Username/password not match.</>
      break
    case ERR_UNKNOWN:
      errorMessage = (
        <>
          An unexpected error occurred.
          <br />
          Please try again later.
        </>
      )
      break
    default:
      errorMessage = null
  }

  const accessTokenInvalid = accessToken === "" || accessToken === null

  useEffect(() => {
    if (!accessTokenInvalid) {
      history.push(ROUTE_HOMEPAGE)
    }
  }, [accessTokenInvalid])

  const handleSubmit = async ({ username, password, env }) => {
    setErrorCode(ERR_NO_ERROR)
    setLoading(true)
    setSuccess(false)
    try {
      await performLogin(username, password, dispatch, env)
    } catch (e) {
      if (e.message === "AUTHENTICATION_FAILED") {
        setErrorCode(ERR_AUTH_FAILED)
      } else {
        console.error(e)
        setErrorCode(ERR_UNKNOWN)
      }
      setLoading(false)
    }
  }

  return (
    <div style={{ position: "absolute", top: "0" }}>
      <LoginPageDisplay
        errorMessage={errorMessage}
        success={success}
        loading={loading}
        onSubmit={handleSubmit}
        fetchCompanyInformation={wrappedFetchCompanyItems}
      />
    </div>
  )
}

export default LoginPage
