import { makeStyles } from "@material-ui/core"

export const useTyphoStyles = makeStyles({
  companyName: {
    fontWeight: "bolder",
    fontStyle: "italic",
    fontSize: "1.25rem",
    paddingLeft: "1rem",
  },
  title: {
    fontWeight: "bolder",
    fontSize: "1rem",
    marginBottom: "0.25rem",
  },
  normal: {
    fontSize: "0.75rem",
  },
  logo: {
    width: "4mm",
  },
  leftAlign: {
    textAlign: "left",
  },
  rightAlign: {
    textAlign: "right",
  },
  centerAlign: {
    textAlign: "center",
  },
})

export const useAlignStyles = makeStyles({
  columnLeftAlign: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  columnRightAlign: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  rowMiddleAlign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  rowRightAlign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  rowChild: {
    flexGrow: 1,
  },
  rowChildBasisMedium: {
    flexBasis: "370px",
  },
  rowChildBasisSmall: {
    flexBasis: "277px",
  },
  rowChildBasisXtraSmall: {
    flexBasis: "211px",
  },
  separator: {
    paddingTop: "1.25rem",
  },
  headerOverflow: {
    width: "740px",
  },
  smallTextCenter: {
    textAlign: "center",
    width: "20%",
  },
  marginLeftItem: {
    marginLeft: "1rem",
  },
  wrapDisplay: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
})
