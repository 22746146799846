import { useState, useRef, useCallback, useEffect, useMemo } from "react"
import debounce from "lodash/debounce"
import getActive from "./getActive"
import useMuiDatatablesHelper from "./useMuiDatatablesHelper"

/** Helper to deal with:
 *  - Setting data and count parameter from fetchData function
 *  - Setting error to a state if fetch fails
 *  - Debounce fetching data
 *
 *  Constraints:
 *  - Tested only for server side data tables.
 *  - Needs stable `getParams` and `fetchData` function.
 *  - Needs expected response from the `fetchData` function with format: `{ data, count }`.
 *    If the fetch function does not follow the format,
 *    adjust the response from `fetchData` with useCallback first.
 */

const useDatatablesFetchHelper = ({
  initOptions = {},
  onError,
  getParams,
  fetchData,
  stickyHeader = true,
}) => {
  const [data, setData] = useState([])
  const [count, setCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(initOptions?.rowsPerPage)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [flagRefetch, setFlagRefetch] = useState(false)

  const refetch = useCallback(() => setFlagRefetch((flag) => !flag), [])

  const {
    tableState,
    getDefaultFetchParams,
    onTableChange: helperTableChange,
    onTableInit: helperTableInit,
  } = useMuiDatatablesHelper()

  const onTableChange = useCallback(
    (action, paramTableState) => {
      setRowsPerPage(paramTableState.row)
      helperTableChange(action, paramTableState)
    },
    [helperTableChange]
  )

  const onTableInit = useCallback(
    (action, paramTableState) => {
      setRowsPerPage(paramTableState.row)
      helperTableInit(action, paramTableState)
    },
    [helperTableInit]
  )

  const onErrorRef = useRef(onError)

  const fetchAndSetData = useCallback(
    async (config, checkActive) => {
      setLoading(true)
      setError()
      let responseData
      let responseCount
      try {
        const result = await fetchData(config)
        responseData = result.data
        responseCount = result.count
      } catch (e) {
        console.log(e)
        setError(e)
        if (typeof onErrorRef.current === "function") {
          onErrorRef.current(e)
        }
        return
      }

      if (checkActive()) {
        setData(responseData)
        setCount(responseCount)
        setLoading(false)
      } else {
        console.log("Loading should be false")
      }
    },
    [fetchData]
  )

  const debouncedFetchData = useMemo(() => debounce(fetchAndSetData, 1000), [
    fetchAndSetData,
  ])

  useEffect(() => {
    const { checkActive, cleanupEffect } = getActive()
    if (tableState === undefined) {
      return cleanupEffect
    }

    const defaultParams = getDefaultFetchParams()
    let additionalParams = {}
    if (typeof getParams === "function") {
      additionalParams = getParams(tableState)
    }

    debouncedFetchData({ ...defaultParams, ...additionalParams }, checkActive)

    return cleanupEffect
  }, [
    flagRefetch,
    debouncedFetchData,
    tableState,
    getDefaultFetchParams,
    getParams,
  ])

  return {
    error,
    options: {
      onTableChange,
      onTableInit,
      count,
      rowsPerPage: rowsPerPage ?? 100,
      serverSide: true,
      ...(stickyHeader
        ? {
            tableBodyMaxHeight: "700px",
          }
        : {}),
    },
    loading,
    data,
    refetch,
  }
}

export default useDatatablesFetchHelper
