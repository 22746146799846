import every from "lodash/every"
import findLastIndex from "lodash/findLastIndex"

const createArrayWith = (length, fill) => {
  return [...new Array(length)].fill(fill)
}

/**
 * Given a permission config and array of endpoint names as string,
 * return a resolved permission status which states whether the
 * action is allowed and whether there are any permission dependencies
 * that are not met.
 */

const resolvePermissionStatus = (permissionConfig, allowedEndpoints) => {
  const permissionCodes = Object.keys(permissionConfig)
  if (permissionCodes.length < 1) return {}

  // Resolve all endpoint name dependencies for each permission item

  const allowedEndpointsAsSet = new Set(allowedEndpoints)
  const allowedEndpointItems = permissionCodes.map((code) => {
    const permissionDetail = permissionConfig[code]
    const endpointDependencies = permissionDetail.endpoint_dependencies || []
    const allSatisfied = every(endpointDependencies, (endpoint) =>
      allowedEndpointsAsSet.has(endpoint)
    )
    return allSatisfied
  })

  // Resolve all permission dependencies for each permission item
  // by treating each permission as graph node and walk through
  // all node.

  /* eslint-disable no-param-reassign */
  const codesToIndex = permissionCodes.reduce((prev, curr, index) => {
    prev[curr] = index
    return prev
  }, {})
  /* eslint-enable no-param-reassign */

  const numItems = permissionCodes.length

  /** Array to keep check of walked permission item */
  const resolvedStatusItems = createArrayWith(numItems, false)

  let currentVisitedNodes = new Set()

  /**
   * Resolve a permission item recursively. Parent dependencies
   * will be resolved first.
   * @param {Number} code
   */

  const resolveItem = (code) => {
    const permissionCodeIndex = codesToIndex[code]
    if (resolvedStatusItems[permissionCodeIndex]) return

    if (currentVisitedNodes.has(`${code}`)) {
      throw new Error("Circular dependencies found")
    }
    currentVisitedNodes.add(`${code}`)

    /*
    console.log(
      resolvedStatusItems
        .map((item, index) => {
          let num = item ? "X" : "_";
          if (index % 5 === 4) {
            num += "-";
          }
          return num;
        })
        .join("")
    ); */

    const permissionDetail = permissionConfig[code]
    const endpointsAllowed = allowedEndpointItems[permissionCodeIndex]
    let allowed = !!endpointsAllowed
    let permissionsAllowed = true

    /*
    const allowToPrint = code === 16;
    if (allowToPrint) {
      console.log(permissionDetail);
    }
    */

    const permissionDependencies =
      permissionDetail.permission_dependencies || []

    if (permissionDependencies.length > 0) {
      permissionDependencies.forEach((parentCode) => {
        resolveItem(parentCode)
      })
      const permissionsAllowedItems = permissionDependencies.map(
        (parentCode) => {
          const permissionDepIndex = codesToIndex[parentCode]
          return allowedEndpointItems[permissionDepIndex]
        }
      )

      /* if (allowToPrint) {
        console.log(permissionsAllowedItems, every(permissionsAllowedItems));
      } */

      permissionsAllowed = every(permissionsAllowedItems)
    }

    /* if (allowToPrint) {
      console.log("true", allowed, permissionsAllowed)
    } */

    allowed = !!(allowed && permissionsAllowed)

    allowedEndpointItems[permissionCodeIndex] = allowed
    resolvedStatusItems[permissionCodeIndex] = true
    currentVisitedNodes.delete(code)
  }

  // Resolve permission items from the back

  let lastUnresolvedIndex = resolvedStatusItems.length - 1

  while (lastUnresolvedIndex >= 0) {
    currentVisitedNodes = new Set()
    const unresolvedCode = permissionCodes[lastUnresolvedIndex]
    resolveItem(unresolvedCode)

    lastUnresolvedIndex = findLastIndex(
      resolvedStatusItems,
      (val) => !val,
      lastUnresolvedIndex
    )
  }

  // Combine allow status from endpoints and parent permissions

  const returnedPermissions = {}
  permissionCodes.forEach((code, index) => {
    const permissionDetail = permissionConfig[code]
    const parentDependencies = permissionDetail.permission_dependencies || []
    returnedPermissions[code] = {
      allow: allowedEndpointItems[index],
      unsatisfiedDeps: parentDependencies.filter((parentCode) => {
        const parentPermissionId = codesToIndex[parentCode]
        return !allowedEndpointItems[parentPermissionId]
      }),
    }
  })

  return returnedPermissions
}

export default resolvePermissionStatus
