/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableFooter,
} from "@material-ui/core"

import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { transformProperty } from "utils/objects"
import useShowSnackbar from "utils/snackbar-hooks"
import DeliveryNotePicker from "./DeliveryNotePicker"
import InvoiceFormContext from "./InvoiceFormContex"
import ProductDescriptionRow from "./ProductDescriptionRow"
import { getAllIndexes, simplifyDNProductDetails } from "./utils"
import InvoiceDescriptionFormContext from "./InvoiceDescriptionFormContext"

const ProductDescriptionTable = forwardRef(
  ({ fetchDeliveryNoteData, initialValue, fetchInitDeliveryNoteData }, ref) => {
    const [initialFormData] = useState(() => {
      return (initialValue?.productDescriptions ?? []).map((item, index) => {
        return {
          __id: index,
          description: item.description,
          unitPrice: item.unitPrice,
          quantity: item.quantity,
          unit: item.unit,
          dnNumber: item?.productDetail?.deliveryNote?.dnNumber,
          poNumber:
            item?.productDetail?.salesEstimate?.slsPurchaseOrder?.poNumber,
          salesEstimateId: item?.productDetail?.salesEstimateId,
          idDnDetail: item.dnDetailId,
          totalCost: item.unitPrice,
          paymentPercentage: item.paymentPercentage,
        }
      })
    })
    const { register, control, setValue, errors, getValues } = useForm()
    const [showSnackbar] = useShowSnackbar()

    const { fields } = useFieldArray({
      control,
      name: "productDescriptions",
      keyName: "__id",
    })

    const lastId = useRef(fields.length)

    const {
      setProductDescriptions,
      rateIn,
      setInvoiceDate,
      invoiceDate,
      productDescriptions,
      invoiceType,
    } = useContext(InvoiceFormContext)

    const { onAddInvoiceDetail, onRemoveInvoiceDetail } = useContext(
      InvoiceDescriptionFormContext
    )

    const [selectedDeliveryNotes, setSelectedDeliveryNotes] = useState(() => {
      return Array.from(
        new Set(
          (initialValue?.productDescriptions ?? []).map(
            (item) => item.productDetail?.deliveryNote?.dnNumber
          )
        )
      )
    })
    const [deliveryNoteList] = useState([])

    useEffect(() => {
      let active = true

      const fetchData = async () => {
        const defaultIds = Array.from(
          new Set(
            (initialValue?.productDescriptions ?? []).map(
              (item) => item.productDetail?.deliveryNoteId
            )
          )
        )
        const response = await fetchInitDeliveryNoteData({
          ids: defaultIds,
        })
        if (!active) return

        setSelectedDeliveryNotes(response)
      }

      fetchData()

      return () => {
        active = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      setValue("productDescriptions", initialFormData)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useImperativeHandle(ref, () => ({
      getFormData: () => {
        const data = getValues()
        const dataCopy = (data?.productDescriptions ?? []).map((product, i) => {
          const curr = fields[i]
          transformProperty(
            product,
            ["quantity", "dnDetailId", "sequence"],
            parseInt
          )
          transformProperty(product, ["unitPrice"], parseFloat)
          // eslint-disable-next-line no-param-reassign
          if (!product.dnDetailId) delete product.dnDetailId
          // eslint-disable-next-line no-param-reassign
          if (!product.quantity) delete product.quantity
          // eslint-disable-next-line no-param-reassign
          if (!product.paymentPercentage) delete product.paymentPercentage
          else transformProperty(product, ["paymentPercentage"], parseFloat)

          // eslint-disable-next-line no-param-reassign
          product.itemKey = curr.itemKey
          return product
        })
        return { productDescriptions: dataCopy }
      },
      submitForm: () => {},
    }))

    useEffect(() => {
      const temp = []

      selectedDeliveryNotes.forEach((curr) => {
        const productDetails = simplifyDNProductDetails(
          curr?.productDetails || []
        ).map((item) => {
          const obj = {
            ...item,
            itemKey: lastId.current,
            dnNumber: curr?.dnNumber,
          }

          lastId.current += 1

          return obj
        })

        productDetails.forEach((item) => {
          const c = productDescriptions.filter(
            (pd) => pd.idDnDetail === item.idDnDetail
          )[0]

          temp.push({
            ...item,
            paymentPercentage: c?.paymentPercentage ?? "",
          })
        })
      })
      setProductDescriptions(temp)
      setValue("productDescriptions", temp)
      temp.forEach((val) => {
        setTimeout(() => {
          onAddInvoiceDetail({ key: val.itemKey })
        }, 250)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDeliveryNotes])

    const refRate = useRef(1)

    useEffect(() => {
      if (refRate.current !== 1) {
        setSelectedDeliveryNotes([])
      } else {
        refRate.current += 1
      }
    }, [rateIn])

    const onAddDeliveryNote = (deliveryNote) => {
      if (invoiceDate) {
        if (invoiceDate !== deliveryNote.deliveryDate) {
          showSnackbar("Delivery date not match")
          return
        }
      }
      setInvoiceDate(deliveryNote.deliveryDate)
      const dataCopy = [...selectedDeliveryNotes]
      setSelectedDeliveryNotes([...dataCopy, deliveryNote])
    }

    const onDeleteDeliveryNote = (dnNumber) => {
      const dataCopy = [...selectedDeliveryNotes]
      const newData = dataCopy.filter((item) => item !== dnNumber)
      const deletedIndexes = getAllIndexes(fields, dnNumber, (curr, val) => {
        if (typeof dnNumber === "string") return curr?.dnNumber === val
        return curr?.dnNumber === val?.dnNumber
      })

      deletedIndexes.forEach((idx) => {
        setTimeout(() => {
          onRemoveInvoiceDetail({ index: fields[idx].itemKey })
        }, 250)
      })

      setSelectedDeliveryNotes(newData)
      if (!newData[0]) {
        setInvoiceDate("")
      }
    }

    useEffect(() => {
      setValue("productDescriptions", initialFormData)
      setProductDescriptions(initialFormData)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: "9rem" }}>DN Number</TableCell>
                <TableCell>PO Number</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>UoM</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>%</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Sequence</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10}>
                    <p style={{ textAlign: "center" }}>No Data</p>
                  </TableCell>
                </TableRow>
              ) : (
                (fields ?? []).map((product, index) => {
                  return (
                    <ProductDescriptionRow
                      key={product.__id}
                      product={product}
                      register={register}
                      errors={errors}
                      index={index}
                      showAdditionalCostButton={invoiceType === "INVOICE"}
                    />
                  )
                })
              )}
            </TableBody>
            <TableFooter>
              <DeliveryNotePicker
                selectedDeliveryNotes={selectedDeliveryNotes}
                setSelectedDeliveryNotes={setSelectedDeliveryNotes}
                fetchDeliveryNoteData={fetchDeliveryNoteData}
                onAddItem={onAddDeliveryNote}
                onDeleteItem={onDeleteDeliveryNote}
                deliveryNoteList={deliveryNoteList}
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    )
  }
)

export default ProductDescriptionTable
