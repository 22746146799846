import { Button } from "@material-ui/core"
import InvoiceItemDisplay from "components/invoice/InvoiceItemDisplay"
import RouteBreadcrumb from "components/page-layout/breadcrumbs/RouteBreadcrumb"
import PERMISSION_CODES from "constants/permission-codes"
import { ROUTE_INVOICE_ROOT } from "constants/routeConstants"
import React, { useEffect, useMemo, useState } from "react"
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom"
import ApiCompanyInformation from "services/api-company-information"
import ApiInvoice from "services/api-invoice"
import useCheckPermission from "utils/auth/useCheckPermission"
import useShowSnackbar from "utils/snackbar-hooks"
import InvoiceMarkAsPaidDialog from "components/invoice/InvoiceMarkAsPaidDialog"
import InvoiceUpdatePage from "./InvoiceUpdatePage"

function InvoiceItemPage() {
  const { checkWithoutUpdateState } = useCheckPermission()
  const [flagRefetch, setFlagRefetch] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [showSnackbar, showSnackbarLoading] = useShowSnackbar()
  const history = useHistory()
  const routeMatch = useRouteMatch()
  const id = routeMatch?.params?.id

  const handleConfirm = async ({ confirmed, paidDate }) => {
    if (confirmed) {
      showSnackbarLoading("Updating...")
      const response = await ApiInvoice.updatePaymentStatus(id, {
        paymentStatus: "paid",
        paidDate,
      })
      if (response) {
        showSnackbar("Success update data")
        setFlagRefetch(!flagRefetch)
      }
    }
    setShowDialog(false)
  }

  const onEnableReviseSQChange = async (status) => {
    try {
      await ApiInvoice.updateEnableReviseSQ({
        idInvoice: parseInt(id, 10),
        status,
      })
    } catch (error) {
      showSnackbar("Failed to update data")
    }
  }

  const [item, setItem] = useState(null)
  const [fetchingInitData, setFetchingInitData] = useState(false)
  const [companyInfo, setCompanyInfo] = useState(null)

  const loading = fetchingInitData

  const getActiveAndCleanup = () => {
    let active = true
    const checkActive = () => active
    const cleanup = () => {
      active = false
    }
    return { checkActive, cleanup }
  }

  useEffect(() => {
    const { checkActive, cleanup } = getActiveAndCleanup()

    setFetchingInitData(true)
    const fetchData = async () => {
      const response = await (await ApiInvoice.getItem(id)).data
      const company = await (await ApiCompanyInformation.get()).data
      if (!checkActive) return

      setItem(response)
      setCompanyInfo(company)
      setFetchingInitData(false)
    }

    fetchData()

    return () => {
      cleanup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagRefetch])

  const ITEM_ROUTE = `${ROUTE_INVOICE_ROOT}/${id}`
  const EDIT_ROUTE = `${ITEM_ROUTE}/edit`

  const initiateEdit = () => {
    history.push(EDIT_ROUTE)
  }

  const canEditPage = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.WRITE_INVOICE],
      }),
    [checkWithoutUpdateState]
  )

  const canUpdatePaymentStatus = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.WRITE_INVOICE_PAYMENT_STATUS],
      }),
    [checkWithoutUpdateState]
  )

  const canUpdateEnableReviseSQ = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.ENABLE_REVISE_SQ_FROM_INVOICE],
      }),
    [checkWithoutUpdateState]
  )

  const changeStatus = async (status) => {
    showSnackbarLoading("Deleting...")
    const response = await ApiInvoice.updateStatus(id, status)
    if (response) {
      showSnackbar("Invoice data was canceled successfully")
      history.push(ROUTE_INVOICE_ROOT)
    }
  }

  const mainContent = (
    <>
      <Switch>
        <RouteBreadcrumb name="Edit Invoice" path={EDIT_ROUTE}>
          <InvoiceUpdatePage
            id={id}
            formData={{
              ...item,
              productDescriptions: (item?.productDescriptions ?? []).map(
                (pd) => {
                  const obj = { ...pd }
                  if (obj.paymentPercentage === item.paymentPercentage) {
                    delete obj.paymentPercentage
                  }
                  return obj
                }
              ),
            }}
            setFormData={setItem}
          />
        </RouteBreadcrumb>

        <Route exact path={ITEM_ROUTE}>
          <InvoiceItemDisplay
            companyInformation={companyInfo}
            onConfirmEdit={initiateEdit}
            showEdit={canEditPage}
            data={item}
            id={id}
            onConfirmChangeStatus={changeStatus}
            setFlagRefetch={() => setFlagRefetch(!flagRefetch)}
            canUpdatePaymentStatus={canUpdatePaymentStatus}
            onUpdatePaymentStatus={() => setShowDialog(true)}
            canUpdateEnableReviseSQ={canUpdateEnableReviseSQ}
            onEnableReviseSQChange={onEnableReviseSQChange}
          />
          <InvoiceMarkAsPaidDialog
            onClose={handleConfirm}
            showDialog={showDialog}
          />
        </Route>
      </Switch>
    </>
  )

  const notFoundDisplay = (
    <div>
      Invoice data not found.
      <Button
        component={Link}
        style={{ display: "block", margin: "auto", width: "100px" }}
        to={ROUTE_INVOICE_ROOT}
        variant="contained"
        color="primary"
      >
        Back
      </Button>
    </div>
  )

  if (loading) {
    return <>Loading...</>
  }

  return item ? mainContent : notFoundDisplay
}

export default InvoiceItemPage
