/**
 * Return user Data: { idUser, name }. If no user data found,
 * return null.
 * @param {*} token
 */
import { Buffer } from "buffer"

const getUserDataFromToken = (token) => {
  if (typeof token !== "string") {
    return null
  }

  const encodedUserData = token.split(".")[1]
  if (!encodedUserData) {
    return null
  }

  let userData = Buffer.from(encodedUserData, "base64").toString("utf-8")
  let userId = 0
  try {
    userData = JSON.parse(userData)
    userId = userData.identity.id_user
  } catch (e) {
    console.error(`Error while decoding id_user: ${encodedUserData}`)
    return null
  }

  userId = parseFloat(userId)
  if (Number.isNaN(userId)) {
    return null
  }

  userData = userData.identity
  delete userData.id_user
  return { idUser: userId, ...userData }
}

export default getUserDataFromToken
