import { showLogoutDialog } from "state/dialog-logout/actions"
import anyErrorsCausedByInvalidToken from "./anyErrorsCausedByInvalidToken"

/**
 * Wrap a callback to API and perform dispatch show logout dialog
 * if there are any errors caused by invalid or expired token.
 * If there are no errors, this will return
 * `{ success: true, response: <return value from original callback>}`.
 * While dispatching, callback doesn't throw any errors and
 * returns `{ success: false }`.
 * Any other errors will be thrown to the caller.
 * @param {*} callback
 * @param {*} dispatch
 */

const sendAndHandleInvalidToken = async (callback, dispatch) => {
  try {
    return { success: true, response: await callback() }
  } catch (e) {
    const isTokenInvalidError = anyErrorsCausedByInvalidToken(e.errors)

    if (isTokenInvalidError) {
      dispatch(showLogoutDialog())
      return { success: false }
    }
    throw e
  }
}

export default sendAndHandleInvalidToken
