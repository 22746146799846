import { useMemo } from "react"
import { useDispatch } from "react-redux"
import {
  showSnackbarAutohide,
  showSnackbarLoading,
  hideSnackbar,
} from "state/snackbar/actions"

export default function useShowSnackbar() {
  const dispatch = useDispatch()

  const methods = useMemo(() => {
    const show = (message) => dispatch(showSnackbarAutohide(message))
    const showLoading = (message) => dispatch(showSnackbarLoading(message))
    const hide = () => dispatch(hideSnackbar())

    return [show, showLoading, hide]
  }, [dispatch])

  return methods
}
