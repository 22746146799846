import PropTypes from "prop-types"
import { Box, Paper } from "@material-ui/core"
import React from "react"

const BasePage = ({ style, className, maxWidth, width, children, p }) => {
  return (
    <Paper className={className} style={{ ...style, maxWidth, width }}>
      <Box p={p}>{children}</Box>
    </Paper>
  )
}

BasePage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.shape({}),
  p: PropTypes.number,
}

BasePage.defaultProps = {
  children: null,
  className: null,
  maxWidth: null,
  width: null,
  style: null,
  p: 2,
}

export default BasePage
