const initialState = {
  items: [],
}

export default function dialogLogoutReducer(state, action) {
  if (typeof state === "undefined") {
    return initialState
  }

  const { type, payload } = action

  switch (type) {
    case "BREADCRUMB_ADD_OR_MODIFY": {
      const { id, name, match, level } = payload

      const newItems = [...state.items]
      const item = newItems[level]
      const levelExists = !!item

      // console.log(
      //   "Try to add/modify:",
      //   level,
      //   name,
      //   match,
      //   level,
      //   JSON.stringify(newItems.map((item) => item?.name))
      // )

      if (levelExists) {
        if (id !== item.id) {
          newItems.splice(level, newItems.length - level + 1, {
            id,
            name,
            match,
            level,
          })
        } else {
          newItems[level] = {
            id,
            name,
            match,
            level,
          }
        }
      } else {
        newItems[level] = { id, name, match }
      }

      // console.log(JSON.stringify(newItems.map((item) => item?.name)))
      return { ...state, items: newItems }
    }

    case "BREADCRUMB_DELETE": {
      const id = payload
      const itemIndex = state.items.findIndex((item) => item && item.id === id)
      if (itemIndex < 0) return state

      const newItems = [...state.items]
      newItems.splice(itemIndex)

      // console.log(
      //   "Try to delete:",
      //   itemIndex,
      //   state.items[itemIndex].name,
      //   JSON.stringify(newItems.map((item) => item?.name))
      // )

      return { ...state, items: newItems }
    }

    default:
      return state
  }
}
