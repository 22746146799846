import forEach from "lodash/forEach"
import reduce from "lodash/reduce"
import isNil from "lodash/isNil"
import has from "lodash/has"

const transformFunc = (obj, key, func) => {
  if (has(obj, key)) {
    // eslint-disable-next-line no-param-reassign
    obj[key] = func(obj[key])
  }
}

export const transformProperty = (obj, keys, func) => {
  if (typeof keys === "string") {
    const key = keys
    transformFunc(obj, key, func)
  } else if (Array.isArray(keys)) {
    forEach(keys, (key) => transformFunc(obj, key, func))
  }
}

export const toDict = (list, idPropName = "id") => {
  if (typeof idPropName === "string") {
    return reduce(
      list,
      (acc, val) => {
        const key = (val ?? {})[idPropName]
        if (!isNil(key)) {
          // eslint-disable-next-line no-param-reassign
          acc[key] = val
        }
        return acc
      },
      {}
    )
  }
  console.error("idPropName is not a string")
  return {}
}
