import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import PropTypes from "prop-types"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers"
import { has, isNaN } from "lodash"
import { purgeInitialFormData } from "utils/form-data"
import { useForm } from "react-hook-form"
import { Grid, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import StandardTextField from "components/common/input/StandardTextField"
import { transformProperty } from "utils/objects"
import { GENERAL_BLANK_FORM, useBaseFormStyles } from "./constants"
import WOFormContext from "./WOFormContext"

const schema = yup.object().shape({
  workOrderTypeId: yup.number().transform((cv) => {
    return isNaN(cv) ? undefined : cv
  }),
  woDate: yup.string().required("This field is required"),
  customerId: yup.number().transform((cv) => {
    return isNaN(cv) ? undefined : cv
  }),
  slsQuotId: yup.number().transform((cv) => {
    return isNaN(cv) ? undefined : cv
  }),
  status: yup.string().required("This field is required"),
  madeBy: yup.string().required("This field is required"),
  acknowledgeBy: yup.string().required("This field is required"),
  approvedBy: yup.string().required("This field is required"),
  woNumber: yup.string().when("$manualNumber", (manualNumber, s) => {
    if (manualNumber) {
      return s.required("This field is required")
    }
    return s
  }),
  projectName: yup.string().required("This field is required"),
})

const WOGeneralForm = forwardRef(
  (
    {
      initialFormData = {},
      fetchCustomerData,
      fetchWoTypeData,
      isUpdate = false,
      updatePostedWorkOrder = false,
    },
    ref
  ) => {
    const classes = useBaseFormStyles()
    const [formData, setFormData] = useState(() => {
      return purgeInitialFormData(initialFormData || {}, GENERAL_BLANK_FORM)
    })

    const { register, handleSubmit, errors, setValue, getValues } = useForm({
      defaultValues: formData,
      resolver: yupResolver(schema),
      context: {
        manualNumber: Boolean(+process.env.REACT_APP_WO_NUMBER_MANUAL),
      },
    })

    const { customerId, setCustomerId } = useContext(WOFormContext)

    useImperativeHandle(ref, () => ({
      getFormData: () => {
        const currentFormData = getValues()
        const data = { ...formData, ...currentFormData, customerId }
        transformProperty(data, ["workOrderTypeId", "customerId"], parseInt)
        // delete data.customerId
        if (!+process.env.REACT_APP_WO_NUMBER_MANUAL) delete data.woNumber
        return data
      },
      onSubmit: () =>
        new Promise((res, rej) => {
          handleSubmit(res, rej)()
        }),
    }))

    const handleChange = (event) => {
      const { name, value } = event.target
      if (has(formData, name)) {
        const newFormData = { ...formData, [name]: value }
        setFormData(newFormData)
      }
    }

    const settings = {
      onchange: handleChange,
      fullWidth: true,
      margin: "dense",
    }

    const [customerList, setCustomerList] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [woTypeList, setWoTypeList] = useState([])
    const [selectedWoType, setSelectedWoType] = useState(null)

    const fetchData = async () => {
      const customer = await fetchCustomerData()
      setCustomerList(customer)

      const woType = await fetchWoTypeData()
      setWoTypeList(woType)
    }

    useEffect(() => {
      fetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      setCustomerId(selectedCustomer?.idCust)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer])

    useEffect(() => {
      const idCust =
        initialFormData?.salesQuotation?.customerId ??
        initialFormData?.customerId
      if (idCust) {
        setValue("customerId", idCust)
        setFormData({ ...formData, customerId: idCust })
        const cust = customerList.filter((item) => item.idCust === idCust)

        if (cust[0]) setSelectedCustomer(cust[0])
        setCustomerId(idCust)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialFormData, customerList])

    useEffect(() => {
      const idWorkOrderType = +initialFormData?.workOrderTypeId
      if (idWorkOrderType) {
        setValue("workOrderTypeId", idWorkOrderType)
        setFormData({ ...formData, workOrderTypeId: idWorkOrderType })
        const wo = woTypeList.filter(
          (item) => +item.idWorkOrderType === idWorkOrderType
        )
        setSelectedWoType(wo[0])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialFormData, woTypeList])

    const woNumberDisplay = (
      <Grid item xs={12} md={12}>
        <StandardTextField
          label="WO Number"
          type="text"
          name="woNumber"
          // InputLabelProps={{
          //   shrink: true,
          // }}
          disabled={!+process.env.REACT_APP_WO_NUMBER_MANUAL}
          fullWidth
          defaultValue={initialFormData.woNumber}
          {...settings}
          inputRef={register}
          error={!!errors?.woNumber}
          helperText={errors?.woNumber?.message}
        />
      </Grid>
    )
    const showWoNumber =
      isUpdate || Boolean(+process.env.REACT_APP_WO_NUMBER_MANUAL)
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            Work Order Description
          </Typography>
        </Grid>

        {/* TODO: Hide wo number form on create */}
        {/* {woNumberDisplay} */}
        {showWoNumber && woNumberDisplay}
        <Grid item xs={12} md={12}>
          <input
            type="hidden"
            name="workOrderTypeId"
            defaultValue={formData.workOrderTypeId}
            ref={register}
          />
          <Autocomplete
            options={woTypeList}
            renderInput={(params) => (
              <StandardTextField
                {...params}
                label="Work Order Type"
                margin="dense"
                fullWidth
                inputRef={register}
                error={!!errors?.workOrderTypeId}
                helperText={errors?.workOrderTypeId?.message}
              />
            )}
            getOptionLabel={(option) =>
              option ? `${option.workOrderTypeName}` : ""
            }
            onChange={(event, newValue) => {
              setSelectedWoType(newValue)
              setFormData((oldData) => {
                return {
                  ...oldData,
                  workOrderTypeId: newValue?.idWorkOrderType,
                }
              })
              setValue("workOrderTypeId", newValue?.idWorkOrderType)
              setValue("status", newValue?.workOrderTypeName ?? "")
            }}
            value={selectedWoType || ""}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <StandardTextField
            label="WO Date"
            {...settings}
            type="date"
            name="woDate"
            inputRef={register}
            error={!!errors.woDate}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors?.woDate?.message}
            defaultValue={formData.woDate.substring(0, 10) || ""}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <input
            type="hidden"
            name="customerId"
            defaultValue={formData.customerId}
            ref={register}
          />
          <Autocomplete
            options={customerList}
            renderInput={(params) => (
              <StandardTextField
                {...params}
                label="Customer"
                margin="dense"
                fullWidth
                inputRef={register}
                error={!!errors?.customerId}
                helperText={errors?.customerId?.message}
              />
            )}
            getOptionLabel={(option) =>
              option ? `${option.name} (${option.custCode})` : ""
            }
            renderOption={(option) => (
              <div>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  {option.custCode}
                </Typography>
                <Typography variant="body2">{option.name}</Typography>
              </div>
            )}
            onChange={(event, newValue) => {
              setSelectedCustomer(newValue)
              setValue("customerId", newValue?.idCust)
            }}
            value={selectedCustomer || ""}
            disabled={updatePostedWorkOrder}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <StandardTextField
            label="Status"
            {...settings}
            name="status"
            inputRef={register}
            error={!!errors.status}
            helperText={errors?.status?.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <StandardTextField
            label="Project Name"
            {...settings}
            name="projectName"
            inputRef={register}
            error={!!errors.projectName}
            helperText={errors?.projectName?.message}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <StandardTextField
            label="Made by"
            {...settings}
            name="madeBy"
            inputRef={register}
            error={!!errors.madeBy}
            helperText={errors?.madeBy?.message}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <StandardTextField
            label="Mengetahui"
            {...settings}
            name="acknowledgeBy"
            inputRef={register}
            error={!!errors.acknowledgeBy}
            helperText={errors?.acknowledgeBy?.message}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <StandardTextField
            label="Hormat Kami"
            {...settings}
            inputRef={register}
            name="approvedBy"
            error={!!errors.approvedBy}
            helperText={errors?.approvedBy?.message}
          />
        </Grid>
      </Grid>
    )
  }
)

WOGeneralForm.propTypes = {
  initialFormData: PropTypes.shape({}).isRequired,
  fetchCustomerData: PropTypes.func.isRequired,
  fetchQuotationData: PropTypes.func.isRequired,
  fetchWoTypeData: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  onSelectQuotation: PropTypes.func.isRequired,
  updatePostedWorkOrder: PropTypes.bool.isRequired,
}

export default WOGeneralForm
