import React, { useCallback, useEffect, useMemo } from "react"
import { Router, Route, useLocation, useHistory } from "react-router-dom"

import AppPage from "components/page-layout/AppPage"
import AppSnackbar from "components/AppSnackbar"

import LoginPage from "pages/login/LoginPage"
import { performLogout } from "utils/user-auth"

import {
  DRAWER_ROUTES,
  ROUTE_LOGIN,
  ROUTE_USER_ROOT,
} from "constants/routeConstants"
import pruneDisallowedRoutes from "utils/permission/pruneDisallowedRoutes"

// Theme and styling related

import {
  ThemeProvider,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import jssTemplate from "jss-plugin-template"
import { create } from "jss"

import HomeIcon from "@material-ui/icons/Home"

// User auth actions and global state from redux

import { useSelector, useDispatch } from "react-redux"
import { hideSnackbar, showSnackbarAutohide } from "state/snackbar/actions"
import {
  setTokenFromSuccessLogin,
  clearTokenAtLogout,
  setUserData,
} from "state/user/actions"
import { useNavOpenRule } from "utils/side-nav-open-state"

import AppContentObstructor from "components/page-layout/AppContentObstructor"
import validator from "services/auth/validator"
import { hideLogoutDialog } from "state/dialog-logout/actions"
import BaseDialog from "components/common/BaseDialog"

import BreadcrumbItem from "components/page-layout/breadcrumbs/BreadcrumbItem"
import BreadcrumbDisplay from "components/page-layout/breadcrumbs/BreadcrumbDisplay"

import appHistory from "./app-history"
import defaultTheme from "./app-theme"
import ProtectedRoute from "./routes"

const jss = create({
  plugins: [jssTemplate(), ...jssPreset().plugins],
})

const AppPages = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const accessToken = useSelector((state) => state.userReducer.accessToken)
  const currentUser = useSelector((state) => state.userReducer.userData)
  const authenticated = !!accessToken

  const handleDialogClick = (mode) => {
    if (mode === "USER_PROFILE") {
      history.push(`${ROUTE_USER_ROOT}/${currentUser.idUser}`)
    }
  }

  const handleLogoutClick = () => {
    performLogout(dispatch)
  }

  const resolvedPermissions = useSelector(
    (state) => state.userReducer.resolvedPermissions
  )
  const allowedRoutes = useMemo(() => {
    return pruneDisallowedRoutes(DRAWER_ROUTES, resolvedPermissions)
  }, [resolvedPermissions])

  const homeIcon = useMemo(
    () => (
      <HomeIcon
        style={{ fontSize: "inherit", position: "relative", top: "2px" }}
      />
    ),
    []
  )

  const { rule: initOpenRule, ref: sideNavRef } = useNavOpenRule()

  return (
    <>
      <AppPage
        hide={location.pathname === ROUTE_LOGIN}
        navProps={{
          routes: allowedRoutes,
          location: location.pathname,
          initOpenRule,
          ref: sideNavRef,
        }}
        appBarProps={{
          userData: currentUser,
          authenticated,
          onDialogChoose: handleDialogClick,
          onLogoutButtonClick: handleLogoutClick,
        }}
      >
        <BreadcrumbDisplay />
        <BreadcrumbItem name={homeIcon} path="/">
          <ProtectedRoute authenticated={authenticated} />
        </BreadcrumbItem>
      </AppPage>
    </>
  )
}

const Snackbar = () => {
  const dispatch = useDispatch()
  const open = useSelector((state) => state.snackbarReducer.snackbarOpen)
  const status = useSelector((state) => state.snackbarReducer.status)
  const autoHideDuration = useSelector(
    (state) => state.snackbarReducer.autoHideDuration
  )
  const snackbarMessage = useSelector(
    (state) => state.snackbarReducer.snackbarMessage
  )

  return (
    <AppSnackbar
      open={open}
      message={snackbarMessage}
      status={status}
      onClose={() => {
        dispatch(hideSnackbar())
      }}
      autoHideDuration={autoHideDuration}
    />
  )
}

const TimeoutDialog = () => {
  const dispatch = useDispatch()

  const onClose = useCallback(() => {
    dispatch(hideLogoutDialog())
    performLogout(dispatch)
  }, [dispatch])

  const open = useSelector((state) => state.dialogLogoutReducer.open)

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      dialogTitle="Your session has timed out."
      hideCancelButton
      contentComponent="Please log in again."
    />
  )
}

const App = () => {
  const dispatch = useDispatch()
  const ready = useSelector((state) => state.userReducer.initialized)

  useEffect(() => {
    /* eslint-disable no-use-before-define */
    const unsubscribeFromValidator = () => {
      validator.removeListener("validationfinish", onValidationFinish)
      validator.removeListener("validationerror", onValidationError)
    }

    const subscribeToValidator = () => {
      validator.addListener("validationfinish", onValidationFinish)
      validator.addListener("validationerror", onValidationError)
    }
    /* eslint-enable no-use-before-define */

    const onValidationFinish = async ({ valid, token, userData }) => {
      if (valid) {
        await dispatch(setTokenFromSuccessLogin(token))
        await dispatch(setUserData(userData))
      } else {
        // Treat invalid token as if user was logged out
        dispatch(clearTokenAtLogout())
      }
      unsubscribeFromValidator()
    }

    const onValidationError = () => {
      dispatch(
        showSnackbarAutohide(
          "Cannot log in at the moment. Please try again later."
        )
      )
      unsubscribeFromValidator()
    }

    const { validating } = validator.getState()

    if (validating) {
      subscribeToValidator()
    } else {
      const { valid, token, userData, error } = validator.getState()
      if (error) {
        onValidationError({ error })
      } else {
        onValidationFinish({ valid, token, userData })
      }
    }

    // This effect runs at app init; no need to pass deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (process.env.NODE_ENV === "development") window.histori = appHistory

  return (
    <ThemeProvider theme={defaultTheme}>
      <StylesProvider jss={jss}>
        <CssBaseline />
        {ready ? (
          <Router history={appHistory}>
            <Route component={AppPages} />
            <Route exact path={ROUTE_LOGIN} component={LoginPage} />
          </Router>
        ) : (
          <AppContentObstructor open />
        )}
        <Snackbar />
        <TimeoutDialog />
      </StylesProvider>
    </ThemeProvider>
  )
}

export default App
