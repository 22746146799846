/* eslint-disable react/prop-types */
import { Grid, Typography } from "@material-ui/core"
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import InvoiceDownPaymentTable from "./InvoiceDownPaymentTable"
import InvoiceExportTable from "./InvoiceExportTable"
import InvoiceFormContext from "./InvoiceFormContex"
import ProductDescriptionTable from "./ProductDescriptionTable"
import ProductDNTable from "./ProductDNTable"
import {
  ADD_INVOICE_ADDITIONAL_COST,
  ADD_INVOICE_DETAIL,
  EMPTY_DATA,
  REMOVE_INVOICE_ADDITIONAL_COST,
  REMOVE_INVOICE_DETAIL,
  useInvoiceAdditionalCostReducer,
} from "./useInvoiceAdditionalCostReducer"
import InvoiceDescriptionFormContext from "./InvoiceDescriptionFormContext"
import InvoiceAdditionalCostDialog from "./InvoiceAdditionalCostDialog"

const InvoiceDescriptionForm = forwardRef(
  (
    {
      initialValue,
      fetchDeliveryNoteData,
      fetchSalesQuotationData,
      fetchPOData,
      fetchInitDeliveryNoteData,
      fetchInitPOData,
      fetchInitSalesQuotationData,
    },
    ref
  ) => {
    const [defaultValue] = useState(() => {
      if (!initialValue) return initialValue
      const { productDescriptions = [], ...otherItems } = initialValue ?? {}

      return {
        ...otherItems,
        productDescriptions: productDescriptions.map((pd, i) => {
          return {
            ...pd,
            itemKey: i,
          }
        }),
      }
    })
    const productTableRef = useRef()
    const [open, setOpen] = useState(false)
    const [selectedDetail, setSelectedDetail] = useState(null)
    const {
      dispatch,
      state: additionalCosts,
    } = useInvoiceAdditionalCostReducer(
      defaultValue?.productDescriptions?.map((pd, i) => {
        return {
          key: i,
          additionalCosts: pd.additionalCosts,
        }
      })
    )

    const onConfirmAddCost = (detail) => {
      setSelectedDetail(detail)
      setOpen(true)
    }

    const onAddInvoiceDetail = (payload) => {
      dispatch({ type: ADD_INVOICE_DETAIL, payload })
    }

    const onRemoveInvoiceDetail = (payload) => {
      dispatch({ type: REMOVE_INVOICE_DETAIL, payload })
    }

    const onAddInvoiceAdditionalCost = (payload) => {
      dispatch({
        type: ADD_INVOICE_ADDITIONAL_COST,
        payload: {
          ...payload,
          key: payload.itemKey,
        },
      })
    }

    const onRemoveInvoiceAdditionalCost = (payload) => {
      dispatch({
        type: REMOVE_INVOICE_ADDITIONAL_COST,
        payload: {
          ...payload,
          key: payload.itemKey,
        },
      })
    }

    const emptyInvoiceDetailData = () => {
      dispatch({ type: EMPTY_DATA })
    }

    useImperativeHandle(ref, () => ({
      getFormData: () => {
        const values = productTableRef.current?.getFormData()
          ?.productDescriptions

        return {
          productDescriptions: values.map((val) => {
            const costs =
              (additionalCosts.filter((ac) => ac.key === val.itemKey)[0] ?? {})
                .additionalCosts ?? []
            // eslint-disable-next-line no-param-reassign
            delete val.itemKey

            return {
              ...val,
              additionalCosts: costs,
            }
          }),
        }
      },
      submitForm: () => {},
    }))

    const { invoiceType, source } = useContext(InvoiceFormContext)

    let table = (
      <ProductDNTable ref={productTableRef} initialValue={defaultValue} />
    )
    if (
      ["INVOICE", "DOWN PAYMENT"].includes(invoiceType) &&
      source === "DELIVERY NOTE"
    ) {
      table = (
        <ProductDescriptionTable
          ref={productTableRef}
          initialValue={defaultValue}
          fetchDeliveryNoteData={fetchDeliveryNoteData}
          fetchInitDeliveryNoteData={fetchInitDeliveryNoteData}
        />
      )
    } else if (
      ["INVOICE", "DOWN PAYMENT"].includes(invoiceType) &&
      source === "PURCHASE ORDER"
    ) {
      table = (
        <InvoiceDownPaymentTable
          ref={productTableRef}
          fetchSalesQuotationData={fetchSalesQuotationData}
          fetchPOData={fetchPOData}
          initialValue={initialValue}
          fetchInitPOData={fetchInitPOData}
        />
      )
    } else if (invoiceType === "INVOICE EXPORT") {
      table = (
        <InvoiceExportTable
          ref={productTableRef}
          fetchSalesQuotationData={fetchSalesQuotationData}
          initialValue={initialValue}
          fetchInitSalesQuotationData={fetchInitSalesQuotationData}
        />
      )
    }
    return (
      <>
        <InvoiceDescriptionFormContext.Provider
          value={{
            additionalCosts,
            onAddInvoiceDetail,
            onRemoveInvoiceDetail,
            onAddInvoiceAdditionalCost,
            onRemoveInvoiceAdditionalCost,
            selectedDetail,
            onConfirmAddCost,
            emptyInvoiceDetailData,
          }}
        >
          <Grid item md={12}>
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              Description
            </Typography>

            {table}
          </Grid>
          <InvoiceAdditionalCostDialog
            open={open}
            onClose={() => setOpen(false)}
          />
        </InvoiceDescriptionFormContext.Provider>
      </>
    )
  }
)

export default InvoiceDescriptionForm
