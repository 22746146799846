import { transformProperty, toDict } from "utils/objects"
import reduce from "lodash/reduce"
import isNil from "lodash/isNil"

/**
 * Convert process estimate items into list of price
 *
 * @param {Array} items
 * @param {Object} masterDataAsDict
 */

export const processEstimatesToPrice = (items, masterDataAsDict) =>
  items.map((item) => {
    const masterItem = masterDataAsDict[item.processId]

    if (!masterItem && !item.cost) return 0
    return (
      parseFloat(item.time) *
      (parseFloat(masterItem?.cost) || parseFloat(item.cost))
    )
  })

/**
 * Convert material estimate items into list of price
 * @param {Array} items
 * @param {Object} masterDataAsDict
 */

export const materialEstimatesToPrice = (items, masterDataAsDict) =>
  items.map((item) => {
    const [l = 1, w = 1, t = 1] = (item?.dimension ?? "1x1x1")
      .toLowerCase()
      .split("x")
    let masterItem = masterDataAsDict[item.materialId]
    if (!masterItem) return 0

    const { materialType } = masterItem

    masterItem = { ...masterItem, density: materialType.density }

    const itemResult = { ...item, length: l, width: w, height: t } // Make a shallow, 1-level copy

    // Mutate the objects' properties by transforming to Number object
    transformProperty(masterItem, ["density", "unitPrice"], parseFloat)
    transformProperty(
      itemResult,
      ["length", "width", "height", "quantity", "unitPrice"],
      parseFloat
    )
    return (
      itemResult.length *
      itemResult.width *
      itemResult.height *
      (masterItem.density / 1e6) *
      itemResult.quantity *
      itemResult.unitPrice
    )
  })

/**
 * Convert additional estimate items into list of price
 * @param {Array} items
 */

export const additionalEstimatesToPrice = (items) =>
  items.map(
    (item) =>
      parseFloat(item.quantity) *
      parseFloat(item.cost) *
      (1 + (item?.handling ?? 0) / 100)
  )

/** Sum all array numbers */

const sum = (items) => items.reduce((acc, item) => acc + item, 0) || 0

/**
 * Simplify list of estimations into idEstimate, unitPrice, and estimationNumber
 * @param {Array} item
 * @param {Object} masterDataAsDict dictionary-like object consisting of material & process
 */

export const simplifyEstimationItems = (item, { process, material }) => {
  if (!process || !material) {
    throw new Error("Master data is not supplied")
  }

  if (item?.totalCost) {
    return {
      idEstimate: item.idEstimate,
      unitPrice: item.totalCost,
      estimationNumber: item.estimationNumber,
      estimationRfq: item.rfq,
    }
  }

  // const processCosts = processEstimatesToPrice(
  //   item?.processCosts || [],
  //   process
  // )
  const processCosts = (item?.processCosts || []).map(
    (processItem) => parseFloat(processItem.time) * parseFloat(processItem.cost)
  )
  const materialCosts = materialEstimatesToPrice(
    item?.materialCosts || [],
    material
  )
  const additionalCosts = additionalEstimatesToPrice(
    item?.additionalCosts || []
  )
  const outsourcingCosts = additionalEstimatesToPrice(
    item?.outsourcingCosts || []
  )

  const processCost = sum(processCosts) || 0
  const materialCost = sum(materialCosts) || 0
  const additionalCost = sum(additionalCosts) || 0
  const outsourcingCost = sum(outsourcingCosts) || 0

  const processHandling = parseFloat(item.processCostHandling) || 0
  const materialHandling = parseFloat(item.materialCostHandling) || 0
  const additionalHandling = parseFloat(item.additionalCostHandling) || 0
  const outsourcingHandling = parseFloat(item.outsourcingCostHandling) || 0

  const unitPrice =
    processCost * (1 + processHandling / 100) +
    materialCost * (1 + materialHandling / 100) +
    additionalCost * (1 + additionalHandling / 100) +
    outsourcingCost * (1 + outsourcingHandling / 100)
  return {
    idEstimate: item.idEstimate,
    unitPrice,
    estimationNumber: item.estimationNumber,
    estimationRfq: item.rfq,
  }
}

export const findSalesEstimatesActiveReduction = (salesEstimatesItem) => {
  let activeReduction = {}

  if (!isNil(salesEstimatesItem?.reductionHistory)) {
    let reductionHistory = salesEstimatesItem.reductionHistory

    if (typeof reductionHistory === "string") {
      reductionHistory = JSON.parse(salesEstimatesItem.reductionHistory)
    }

    console.log({ reductionHistory })

    reductionHistory.forEach((item, index) => {
      if (+item.status === 1) {
        activeReduction = item
      }
    })
  }
  return activeReduction
}

export const joinSalesEstimatesAndMasterData = (
  salesEstimates,
  estimatesAsDict,
  materialsAsArray,
  processAsArray,
  marginMultiplier
) => {
  const materialsAsDict = toDict(materialsAsArray, "idMaterial")
  const processAsDict = toDict(processAsArray, "idProcess")

  return salesEstimates
    .map((item) => {
      const estimateItem = estimatesAsDict[item.estQuotId]
      if (isNil(estimateItem)) {
        return null
      }

      const estimationData = simplifyEstimationItems(estimateItem, {
        material: materialsAsDict,
        process: processAsDict,
      })

      const unitPrice = estimationData.unitPrice * marginMultiplier

      const totalCost =
        parseFloat(item.quantity) * unitPrice * (1 + item.handling / 100 || 0) -
        item.reduction

      return {
        ...estimateItem,
        ...item,
        unitPrice,
        totalCost: item.totalAmount || totalCost,
      }
    })
    .filter((item) => item)
}

export const sumEstimates = (joinedEstimates) =>
  reduce(joinedEstimates, (sumEst, item) => sumEst + item.totalCost, 0)

export const sumAdditionalCosts = (additionalCost, marginMultiplier) =>
  reduce(
    additionalCost,
    (sumAdd, item) => sumAdd + item.cost * marginMultiplier,
    0
  )
