/* eslint-disable react/prop-types */
import { FormControlLabel, Switch } from "@material-ui/core"
import React, { useState, useEffect } from "react"

function EnableReviseToggle({
  defaultValue,
  onEnableReviseSQChange = () => {},
}) {
  const [enableReviseSQ, setEnableReviseSQ] = useState(defaultValue ?? false)

  console.log({ enableReviseSQ })
  useEffect(() => {
    const x = setTimeout(() => {
      onEnableReviseSQChange(enableReviseSQ)
    }, 1000)

    return () => {
      clearTimeout(x)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableReviseSQ])

  return (
    <FormControlLabel
      control={
        <Switch
          checked={enableReviseSQ}
          onChange={(e) => setEnableReviseSQ(e.target.checked)}
        />
      }
      label="Enable Revise SQ"
    />
  )
}

export default EnableReviseToggle
