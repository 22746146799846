import React from "react"
import {
  additionalEstimatesToPrice,
  findSalesEstimatesActiveReduction,
  materialEstimatesToPrice,
  processEstimatesToPrice,
} from "components/sales-quotation/utils"
import { toDict } from "utils/objects"
import { numberFormat } from "utils/thousand-separator"

export function getDefaultSettings(
  name,
  label = "",
  // eslint-disable-next-line no-unused-vars
  register = null,
  errors = null
) {
  const errorObj = (errors || {})[name]

  return {
    name,
    label,
    fullWidth: true,
    margin: "dense",
    error: !!errorObj,
    helperText: errorObj?.message ?? "",
    inputRef: register,
  }
}

export function simplifyDNProductDetails(items) {
  return items.map((item) => {
    const estQuot = item?.salesEstimate?.estQuot ?? {}
    const processCost = processEstimatesToPrice(
      estQuot?.processCosts || [],
      toDict(
        (estQuot?.processCost || []).map((process) => {
          return { ...process.process, processId: process.processId }
        }),
        "processId"
      )
    ).reduce((a, b) => a + b, 0)

    const materialCost = materialEstimatesToPrice(
      estQuot?.materialCosts ?? [],
      toDict(
        (estQuot?.materialCosts || []).map((m) => ({
          ...m.material,
          dimension: m.dimension,
          materialId: m.materialId,
          density: m.material.materialType.density,
        })),
        "materialId"
      )
    ).reduce((a, b) => a + b, 0)

    const additionalCost = additionalEstimatesToPrice(
      estQuot?.additionalCosts ?? []
    ).reduce((a, b) => a + b, 0)
    const outsourcingCost = additionalEstimatesToPrice(
      estQuot?.outsourcingCosts || []
    ).reduce((a, b) => a + b, 0)

    const processHandling = parseFloat(estQuot?.processCostHandling) || 0
    const materialHandling = parseFloat(estQuot?.materialCostHandling) || 0
    const additionalHandling = parseFloat(estQuot?.additionalCostHandling) || 0
    const outsourcingHandling =
      parseFloat(estQuot?.outsourcingCostHandling) || 0

    const activeReduction = findSalesEstimatesActiveReduction(
      item?.salesEstimate
    )

    const unitPrice = !item.unitPrice
      ? processCost * (1 + processHandling / 100) +
        materialCost * (1 + materialHandling / 100) +
        additionalCost * (1 + additionalHandling / 100) +
        outsourcingCost * (1 + outsourcingHandling / 100)
      : item.unitPrice

    const totalCost = !item.unitPrice
      ? parseFloat(item?.salesEstimate?.quantity ?? 1) *
          unitPrice *
          (1 + (item?.handling / 100 || 0)) -
        (activeReduction?.reduction ?? 0)
      : item.unitPrice

    const discount = item.discount || item.salesEstimate?.discount || 0

    return {
      totalCost: totalCost - discount,
      unitPrice: unitPrice - discount,
      description: item.description,
      quantity: item.quantity,
      unit: item.unit,
      idDnDetail: item.idDnDetail,
      poNumber: item.salesEstimate?.slsPurchaseOrder?.poNumber,
      reduction: activeReduction,
    }
  })
}

export function currencyOperation(value, rateIn, currencyRate) {
  if (rateIn?.code === "IDR") {
    return value / (currencyRate ?? 1)
  }
  return value * (currencyRate ?? 1)
}

export function getAllIndexes(arr, val, callback) {
  const indexes = []
  for (let i = 0; i < arr.length; i++) {
    if (callback) {
      if (callback(arr[i], val)) {
        indexes.push(i)
      }
    } else if (arr[i] === val) indexes.push(i)
  }
  return indexes
}

function generateDescriptionPrint({ product, isMergeDetail, isDebitNote }) {
  let description = (
    <strong style={{ whiteSpace: "pre-line" }}>{product.description}</strong>
  )

  if (isMergeDetail) {
    description = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "80%", whiteSpace: "pre-line" }}>
          <span style={{ display: "block" }}>{product.description}</span>
          <span>{product.remark}</span>
        </div>
        <strong style={{ textAlign: "right" }}>{product?.mouldCode}</strong>
      </div>
    )
  }

  if (!isDebitNote) {
    description = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "80%", whiteSpace: "pre-line" }}>
          <span style={{ display: "block" }}>{product.description}</span>
          <span>{product.remark}</span>
        </div>
        <strong style={{ textAlign: "right" }}>
          {product?.productDetail?.mouldCode}
        </strong>
      </div>
    )
  }

  return description
}

function generateUnitPricePrint({ data, unitPrice }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      <span>{data.currency.code}</span>
      <span>{numberFormat(unitPrice, 0, ",", ".")}</span>
    </div>
  )
}

export function generateTotalAmountPrint({ data, total }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      <span>{data.currency.code}</span>
      <span>{numberFormat(total, 0, ",", ".")}</span>
    </div>
  )
}

export function generateProductDescriptions({
  data,
  arr,
  isDebitNote,
  isMergeDetail,
  mergeAdditionalCost,
}) {
  let no = 1
  return arr
    .map((product) => {
      const description = generateDescriptionPrint({
        product,
        isDebitNote,
        isMergeDetail,
      })

      const quantity = `${product.quantity} ${product.unit}`

      let price =
        isDebitNote || isMergeDetail
          ? product.unitPrice
          : product.unitPrice / (product.quantity || 1)

      if (mergeAdditionalCost) {
        const totalAdditionalCostPrice = product.additionalCosts.reduce(
          (a, b) => a + b.unitPrice,
          0
        )

        price += totalAdditionalCostPrice
      }

      const unitPrice = currencyOperation(price, data.rateIn, data.currencyRate)

      const percentage = product.paymentPercentage ?? data.paymentPercentage

      const total = percentage
        ? unitPrice * (product.quantity || 1) * (0 + percentage / 100)
        : unitPrice * (product.quantity || 1)

      // if (mergeAdditionalCost) {
      //   const totalAdditionalCost = product.additionalCosts.reduce(
      //     (a, b) => a + b.unitPrice * b.quantity,
      //     0
      //   )

      //   total += totalAdditionalCost
      // }
      const totalForCalculation = total

      const temp = [
        {
          no,
          description,
          quantity,
          unitPrice: generateUnitPricePrint({ data, unitPrice }),
          total: generateTotalAmountPrint({ data, total }),
          totalForCalculation,
          projectName: product.projectName ?? "",
          orderNumber: !isDebitNote ? (
            <strong>Order No: {product?.mouldCode}</strong>
          ) : null,
        },
      ]

      no += 1

      if (!mergeAdditionalCost) {
        product.additionalCosts?.forEach((ac) => {
          const additionalPrice = ac.unitPrice
          const additionalTotal = ac.unitPrice * ac.quantity
          temp.push({
            no,
            description: generateDescriptionPrint({
              product: {
                ...product,
                ...ac,
              },
            }),
            quantity: `${ac.quantity} ${ac.unit}`,
            unitPrice: generateUnitPricePrint({
              data,
              unitPrice: additionalPrice,
            }),
            total: generateTotalAmountPrint({ data, total: additionalTotal }),
            totalForCalculation: additionalTotal,
            projectName: product.projectName ?? "",
          })
          no += 1
        })
      }

      return temp
    })
    .flat()
}
