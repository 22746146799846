import React from "react"
import PropTypes from "prop-types"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  confirmButton: {
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  cancelButton: {
    marginLeft: "16px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  dialogTitle: {
    textAlign: "center",
  },
}))

const WODeleteDialog = ({ onClose, open, woNumber }) => {
  const handleClose = (userConfirms) => {
    return onClose({ confirm: userConfirms })
  }

  const classes = useStyles()

  return (
    <Dialog
      onClose={onClose}
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle className={classes.dialogTitle}>
        Delete Work Order Data?
      </DialogTitle>
      <DialogContent>
        <p>Are you sure you want to delete work order data {woNumber}?</p>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          className={classes.confirmButton}
          disableElevation
          variant="contained"
          onClick={() => handleClose(true)}
          color="secondary"
        >
          Delete
        </Button>
        <Button
          className={classes.cancelButton}
          disableElevation
          variant="outlined"
          onClick={() => handleClose(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

WODeleteDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  woNumber: PropTypes.string,
}

WODeleteDialog.defaultProps = {
  onClose: () => {},
  open: false,
  woNumber: "",
}

export default WODeleteDialog
