/* eslint-disable react/prop-types */
import { IconButton, TableCell, TableRow, TextField } from "@material-ui/core"

import React, { useMemo, useContext, useState, useEffect } from "react"
import { numberFormat } from "utils/thousand-separator"
import { Add, Delete } from "@material-ui/icons"
import InvoiceFormContext from "./InvoiceFormContex"
import { currencyOperation } from "./utils"
import InvoiceDescriptionFormContext from "./InvoiceDescriptionFormContext"

function InvoiceDownPaymentRow({
  item,
  index,
  register,
  errors,
  showRemark = false,
  showDelete = false,
  onDeleteButtonClick = () => {},
  showPercentage = false,
  showAdditionalCostButton = false,
}) {
  const {
    selectedCurrency,
    paymentPercentage,
    currencyRate,
    rateIn,
    productDescriptions,
    setProductDescriptions,
  } = useContext(InvoiceFormContext)

  const [rowPercentage, setRowPercentage] = useState(
    item.paymentPercentage ?? ""
  )
  const { onConfirmAddCost } = useContext(InvoiceDescriptionFormContext)

  const totalCostWithCurrency = useMemo(() => {
    return currencyOperation(item.totalCost, rateIn, currencyRate)
  }, [currencyRate, item.totalCost, rateIn])
  const totalCostWithPercentage = useMemo(() => {
    if (rowPercentage) {
      return totalCostWithCurrency * (0 + rowPercentage / 100)
    }
    if (!paymentPercentage) return totalCostWithCurrency
    return totalCostWithCurrency * (0 + paymentPercentage / 100)
  }, [paymentPercentage, totalCostWithCurrency, rowPercentage])

  const getFormAttribute = (name) => {
    const errorObj = (errors[index] || {})[name]
    return {
      name: `productDescriptions[${index}].${name}`,
      defaultValue: item[name],
      inputRef: register,
      error: !!errorObj,
      helperText: errorObj?.message,
      variant: "outlined",
      fullWidth: true,
      margin: "dense",
    }
  }

  useEffect(() => {
    const x = setTimeout(() => {
      const copy = [...productDescriptions]
      copy[index] = {
        ...copy[index],
        paymentPercentage: rowPercentage,
      }
      setProductDescriptions(copy)
    }, 500)

    return () => {
      clearTimeout(x)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowPercentage])
  return (
    <TableRow>
      <TableCell>{item.slsQuot?.slsQuotNumber}</TableCell>
      <TableCell>{item.slsQuot?.poNumber}</TableCell>
      <TableCell>
        <TextField {...getFormAttribute("description")} multiline />
        <input
          type="hidden"
          name={`productDescriptions[${index}].slsEstimateId`}
          value={item.slsEstimateId}
          ref={register}
        />
        <input
          type="hidden"
          name={`productDescriptions[${index}].itemKey`}
          value={item.itemKey}
          ref={register}
        />
      </TableCell>
      <TableCell>
        {new Intl.NumberFormat().format(item.quantity)}
        <input
          type="hidden"
          name={`productDescriptions[${index}].quantity`}
          value={item.quantity}
          ref={register}
        />
      </TableCell>
      <TableCell>
        {item.uom?.name}
        <input
          type="hidden"
          name={`productDescriptions[${index}].unit`}
          value={item.uom?.name}
          ref={register}
        />
      </TableCell>

      <TableCell style={{ textAlign: "right" }}>
        {selectedCurrency?.code}{" "}
        {numberFormat(
          Math.ceil(totalCostWithCurrency / item.quantity),
          0,
          ",",
          "."
        )}
        <input
          type="hidden"
          name={`productDescriptions[${index}].unitPrice`}
          value={item.totalCost}
          ref={register}
        />
      </TableCell>
      {showPercentage ? (
        <TableCell>
          <TextField
            name={`productDescriptions[${index}].paymentPercentage`}
            defaultValue={rowPercentage}
            inputRef={register}
            margin="dense"
            fullWidth
            variant="outlined"
            type="number"
            onChange={(e) => setRowPercentage(e.target.value)}
          />
        </TableCell>
      ) : null}
      <TableCell style={{ textAlign: "right" }}>
        {selectedCurrency?.code}
        {numberFormat(totalCostWithPercentage, 0, ",", ".")}
      </TableCell>
      <TableCell>
        <TextField
          {...getFormAttribute("sequence")}
          defaultValue={item.sequence || index + 1}
          type="number"
        />
      </TableCell>
      {showRemark && (
        <TableCell>
          <TextField
            {...getFormAttribute("remark")}
            multiline
            style={{ width: "15rem" }}
          />
        </TableCell>
      )}
      {showDelete || showAdditionalCostButton ? (
        <TableCell>
          {showDelete ? (
            <IconButton onClick={() => onDeleteButtonClick(index)}>
              <Delete />
            </IconButton>
          ) : null}

          {showAdditionalCostButton ? (
            <IconButton
              onClick={() => {
                onConfirmAddCost(item)
              }}
            >
              <Add />
            </IconButton>
          ) : null}
        </TableCell>
      ) : null}
    </TableRow>
  )
}

export default InvoiceDownPaymentRow
