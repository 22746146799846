/* eslint-disable react/prop-types */
import BaseDialog from "components/common/BaseDialog"
import StandardTextField from "components/common/input/StandardTextField"
import React, { useState } from "react"

function WarrantyCloseDialog({ open, onClose, item }) {
  const [remark, setRemark] = useState("")
  return (
    <BaseDialog
      open={open}
      dialogTitle={`Close ${item?.mouldCode}'s warranty?`}
      onClose={({ confirmed }) => {
        onClose({ confirmed, remark })
      }}
    >
      <StandardTextField
        value={remark}
        onChange={(e) => setRemark(e.target.value)}
        label="Remarks"
        fullWidth
        margin="dense"
        variant="outlined"
      />
    </BaseDialog>
  )
}

export default WarrantyCloseDialog
