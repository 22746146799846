/* eslint-disable react/prop-types */
import { Chip, IconButton, TableCell, TableRow } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import AsyncDropdown from "components/common/input/AsyncDropdown"
import { useTableEstimationStyles } from "components/sales-quotation/constants"

import React, { useContext, useMemo, useState } from "react"
import InvoiceFormContext from "./InvoiceFormContex"

function DeliveryNotePicker({
  selectedDeliveryNotes = [],
  onAddItem,
  onDeleteItem = () => {},
  fetchDeliveryNoteData = () => [],
}) {
  const [selectedDeliveryNote, setSelectedDeliveryNote] = useState(null)
  const classes = useTableEstimationStyles()

  const selectedDnNumber = useMemo(() => {
    return selectedDeliveryNotes.map((item) => item)
  }, [selectedDeliveryNotes])

  const { selectedCustomer, rateIn } = useContext(InvoiceFormContext)

  const fetchDeliveryNotes = React.useCallback(
    async (searchText) => {
      if (!selectedCustomer || !rateIn) return []
      const response = await fetchDeliveryNoteData({
        searchText,
        customerId: selectedCustomer.idCust,
        currencyId: rateIn.idCurr,
      })
      return response
    },
    [selectedCustomer, rateIn, fetchDeliveryNoteData]
  )

  return (
    <TableRow>
      <TableCell colSpan={5} className={classes.addItemCell}>
        <div className={classes.addItemContainer}>
          <IconButton
            size="small"
            variant="contained"
            color="default"
            className={classes.addButton}
            disabled={!selectedDeliveryNote}
            onClick={() => {
              onAddItem(selectedDeliveryNote)
              setSelectedDeliveryNote(null)
            }}
          >
            <Add />
          </IconButton>
          <AsyncDropdown
            getOptionLabel={(opt) => {
              const descriptions = opt.productDetails
                .map((item) => `${item.description} (${item.mouldCode})`)
                .join(", ")
              return `${opt.dnNumber} [${descriptions}]`
            }}
            fetchData={fetchDeliveryNotes}
            style={{ padding: "8px", width: "100%" }}
            // options={optDeliveryNotes}
            getOptionSelected={(opt, val) =>
              opt.idDeliveryNote === val?.idDeliveryNote
            }
            value={selectedDeliveryNote}
            getOptionDisabled={(opt) => {
              const curr = selectedDeliveryNotes.filter(
                (item) => item.dnNumber === opt?.dnNumber
              )[0]
              return !!curr
            }}
            onChange={(val) => {
              setSelectedDeliveryNote(val)
            }}
            inputLabel="DN. Number"
          />
        </div>
      </TableCell>
      <TableCell colSpan={2}>
        {selectedDnNumber.map((item) => {
          const label = typeof item === "string" ? item : item?.dnNumber
          return (
            <Chip
              key={label}
              label={label}
              variant="outlined"
              onDelete={() => onDeleteItem(item)}
            />
          )
        })}
      </TableCell>
    </TableRow>
  )
}

export default DeliveryNotePicker
