import EventDispatcher from "utils/event-dispatcher"
import anyErrorsCausedByInvalidToken from "utils/invalid-token/anyErrorsCausedByInvalidToken"
import ApiUser from "services/api-user"
import getUserDataFromToken from "utils/auth/getUserDataFromToken"

/**
 * Validate user data from token using getMyProfile API
 */

class Validator extends EventDispatcher {
  constructor() {
    super()

    const state = {
      init: false,
      validating: false,
      valid: null,
      token: null,
      userData: null,
      error: null,
    }

    const self = this
    this.getState = () => {
      return { ...state }
    }

    this.validateFromToken = async (token) => {
      if (state.validating) {
        return { valid: null, isValidating: true }
      }
      state.validating = true

      const userDataFromToken = getUserDataFromToken(token)
      if (!userDataFromToken) {
        self.setFailValidationAndDispatchEvent()
        return { valid: false }
      }

      try {
        const response = await ApiUser.getCurrentUser(token)
        const userData = response.data
        self.setSuccessValidationAndDispatchEvent({ token, userData })
        return { valid: true, token, userData }
      } catch (e) {
        if (anyErrorsCausedByInvalidToken(e.errors)) {
          self.setFailValidationAndDispatchEvent()
          return { valid: false }
        }

        // Other error happens such as bad network connection
        self.dispatchUnknownErrorEvent(e)
        return { valid: null, error: e }
      }
    }

    const setSuccessValidationState = ({ token, userData }) => {
      state.validating = false
      state.init = true
      state.valid = true
      state.error = null

      state.token = token
      state.userData = userData
    }

    /**
     * Set success validation state without dispatching event.
     * Only enabled in development to help module hot reloading
     * issue
     */

    this.setSuccessValidationState = ({ token, userData }) => {
      if (process.env.NODE_ENV === "development") {
        setSuccessValidationState({ token, userData })
      }
    }

    this.setSuccessValidationAndDispatchEvent = ({ token, userData }) => {
      setSuccessValidationState({ token, userData })
      self.dispatchEvent("validationfinish", { valid: true, token, userData })
    }

    this.setFailValidationAndDispatchEvent = () => {
      state.validating = false
      state.init = true
      state.valid = false
      state.error = null

      state.token = null
      state.userData = null

      self.dispatchEvent("validationfinish", { valid: false })
    }

    this.dispatchUnknownErrorEvent = (error) => {
      state.validating = false
      state.init = true
      state.valid = null
      state.error = error

      state.token = null
      state.userData = null

      self.dispatchEvent("validationerror", { error })
    }

    this.resetValidation = () => {
      state.validating = false
      state.valid = null
      state.error = null

      state.token = null
      state.userData = null
    }
  }
}

const validatorInstance = new Validator()
export default validatorInstance
