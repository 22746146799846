/**
 * Check whether the errors provided by GraphQL contains any message
 * related to invalid or expired token.
 * @param {Array} errors
 */

const anyErrorsCausedByInvalidToken = (errors) => {
  if (!Array.isArray(errors)) {
    return false
  }

  const loginErrors = [
    "Missing Authorization Header",
    "Bad Authorization header",
    "Not enough segments",
    "Invalid payload padding",
    "Invalid payload string",
    "Signature verification failed",
    "Invalid header string: Expecting",
    "Invalid payload string: Expecting",
    "Invalid crypto padding",
    "Signature has expired",
  ]

  if (errors) {
    for (let i = 0; i < errors.length; i++) {
      const error = errors[i]
      for (let j = 0; j < loginErrors.length; j++) {
        const invalidHeaderMessage = loginErrors[j]
        if (error.message.startsWith(invalidHeaderMessage)) {
          return true
        }
      }
    }
  }
  return false
}

export default anyErrorsCausedByInvalidToken
