import axiosOri from "axios"
import environment from "../utils/environment"

export const ENDPOINT = {
  QUERY: `${environment.rootApi}/graphql-query`,
  DELETE: `${environment.rootApi}/graphql-mutation-delete`,
  CREATE: `${environment.rootApi}/graphql-mutation-create`,
  UPDATE: `${environment.rootApi}/graphql-mutation-update`,
  AUTH: `${environment.rootApi}/graphql-mutation-auth`,
}

export const axios = axiosOri.create()

export const setToken = (accessToken) => {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
}

export const clearToken = () => {
  axios.defaults.headers.common.Authorization = ""
}
