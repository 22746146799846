import { makeStyles } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import ApiBillOfMaterial from "services/api-bill-of-material"

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.secondary.light,
    color: "white",
    fontSize: theme.typography.caption.fontSize,
    borderRadius: "50%",
  },
}))

function ViewBillSidebar() {
  const classes = useStyles()

  const [needPurchaseList, setNeedPurchaseList] = useState([])

  useEffect(() => {
    let active = true

    ;(async () => {
      const response = await ApiBillOfMaterial.getNeedPurchaseBom()
      if (!active) return
      setNeedPurchaseList(response.data)
    })()

    const timer = setInterval(() => {
      ;(async () => {
        const response = await ApiBillOfMaterial.getNeedPurchaseBom()
        if (!active) return
        setNeedPurchaseList(response.data)
      })()
    }, 1000 * 60)

    return () => {
      active = false
      clearInterval(timer)
    }
  }, [])
  return (
    <span style={{ padding: 0, position: "relative" }}>
      View Bills
      {needPurchaseList.length ? (
        <div
          style={{
            position: "absolute",
            top: -4,
            left: 66,
            width: 18,
            height: 18,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          }}
          className={classes.badge}
        >
          {needPurchaseList.length < 9 ? needPurchaseList.length : "9+"}
        </div>
      ) : null}
    </span>
  )
}

export default ViewBillSidebar
