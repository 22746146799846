/* eslint-disable react/prop-types */
import { Typography } from "@material-ui/core"
import BaseDialog from "components/common/BaseDialog"
import StandardTextField from "components/common/input/StandardTextField"
import React, { useState } from "react"

function InvoiceMarkAsPaidDialog({ showDialog, onClose }) {
  const [paidDate, setPaidDate] = useState("")

  return (
    <BaseDialog
      open={showDialog}
      dialogTitle="Mark as Paid?"
      confirmButtonTitle="Yes"
      cancelButtonTitle="No"
      confirmButtonProps={{
        color: "primary",
        variant: "contained",
        disabled: !paidDate,
      }}
      cancelButtonProps={{
        color: "primary",
        variant: "outlined",
      }}
      onClose={({ confirmed }) => {
        onClose({ confirmed, paidDate })
      }}
    >
      <Typography style={{ marginBottom: "8px" }}>
        You can&apos;t revert this action
      </Typography>
      <StandardTextField
        label="Paid Date"
        variant="outlined"
        margin="dense"
        type="date"
        value={paidDate}
        fullWidth
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setPaidDate(e.target.value)}
      />
    </BaseDialog>
  )
}

export default InvoiceMarkAsPaidDialog
