import { Paper } from "@material-ui/core"
import InvoiceForm from "components/invoice/InvoiceForm"
import NotAllowedNotice from "components/NotAllowedNotice"
import PERMISSION_CODES from "constants/permission-codes"
import { ROUTE_INVOICE_ROOT } from "constants/routeConstants"
import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import ApiCustomer from "services/api-customer"
import ApiDeliveryNotes from "services/api-delivery-notes"
import ApiAuthorizedSignature from "services/api-authorized-signature"
import ApiCurrency from "services/api-master-data-currency"
import useCheckPermission from "utils/auth/useCheckPermission"
import {
  sendAndHandleInvalidToken,
  useWrapHandleInvalidToken,
} from "utils/invalid-token"
import useShowSnackbar from "utils/snackbar-hooks"
import ApiInvoice from "services/api-invoice"
import ApiSalesQuotation from "services/api-sales-quotation"

const PAGE_PERMISSIONS = [PERMISSION_CODES.WRITE_INVOICE]

const fetchDeliveryNote = async ({
  searchText = "",
  customerId = "",
  currencyId = "",
}) =>
  (
    (await ApiDeliveryNotes.get({
      searchText,
      customerId,
      currencyId,
      fromColumns: ["dnNumber"],
      limit: 10,
      notPaid: true,
      fixed: true,
    })) || []
  ).data

const fetchCurrency = async () => ((await ApiCurrency.get()) || []).data

const fetchCustomer = async () => ((await ApiCustomer.get()) || []).data

const fetchAuthorizedSignature = async () =>
  ((await ApiAuthorizedSignature.get()) || []).data

const fetchSalesQuotation = async ({
  slsQuotNumber,
  rateInId,
  customerId,
  haveWorkOrder = null,
}) => {
  const params = {
    searchText: slsQuotNumber,
    fromColumns: ["slsQuotNumber"],
    status: "execute",
    limit: 10,
    rateInId,
    customerId,
    paymentStatuses: [1, 2],
  }

  if (haveWorkOrder !== null) {
    params.haveWorkOrder = haveWorkOrder
  }

  const response = await ApiSalesQuotation.getSQForInvoice(params)

  return response.data
}

const fetchPOData = async ({
  poNumber = "",
  rateInId = "",
  customerId = "",
  ...otherParams
}) =>
  (
    (await ApiSalesQuotation.getPOForInvoice({
      poNumber,
      rateInId,
      customerId,
      paymentStatuses: [1, 2],
      ...otherParams,
    })) || []
  ).data

function InvoiceCreatePage() {
  const history = useHistory()
  const [showSnackbar, showSnackbarLoading, hideSnackbar] = useShowSnackbar()

  const { checkWithoutUpdateState } = useCheckPermission()

  const wrappedFetchDeliveryNoteData = useWrapHandleInvalidToken(
    fetchDeliveryNote,
    () => []
  )
  const wrappedFetchPOData = useWrapHandleInvalidToken(fetchPOData, () => [])

  const wrappedFetchCurrency = useWrapHandleInvalidToken(
    fetchCurrency,
    () => []
  )

  const wrappedFetchCustomerData = useWrapHandleInvalidToken(
    fetchCustomer,
    () => []
  )

  const wrappedFetchAuthorizedSignature = useWrapHandleInvalidToken(
    fetchAuthorizedSignature,
    () => []
  )

  const wrappedFetchSalesQuotationData = useWrapHandleInvalidToken(
    fetchSalesQuotation,
    () => []
  )

  const allowed = useMemo(
    () => checkWithoutUpdateState({ permissions: PAGE_PERMISSIONS }),
    [checkWithoutUpdateState]
  )

  const handleCancel = () => {
    history.push(ROUTE_INVOICE_ROOT)
  }

  const handleSubmit = async (formData) => {
    showSnackbarLoading("Creating invoice data...")
    let fetchState = {}

    try {
      fetchState = await sendAndHandleInvalidToken(() =>
        ApiInvoice.create(formData)
      )
    } catch (err) {
      showSnackbar(err?.errors[0]?.message ?? "Cannot create invoice")
      return
    }
    if (fetchState.success) {
      showSnackbar("Invoice data successfully created")
      const { data } = fetchState.response
      history.push(`${ROUTE_INVOICE_ROOT}/${data.idInvoice}`)
    } else {
      hideSnackbar()
    }
  }

  if (!allowed) {
    return (
      <Paper style={{ maxWidth: "800px" }}>
        <NotAllowedNotice />
      </Paper>
    )
  }

  return (
    <InvoiceForm
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      fetchDeliveryNoteData={wrappedFetchDeliveryNoteData}
      handleSubmit={handleSubmit}
      fetchCurrencyData={wrappedFetchCurrency}
      fetchCustomerData={wrappedFetchCustomerData}
      fetchAuthorizedSignatureData={wrappedFetchAuthorizedSignature}
      fetchSalesQuotationData={wrappedFetchSalesQuotationData}
      fetchPOData={wrappedFetchPOData}
    />
  )
}

export default InvoiceCreatePage
