import React from "react"
import * as t from "prop-types"

function InvoiceTypography({ type }) {
  return (
    <div
      style={{
        position: "relative",
        textAlign: "center",
        borderBottom: "2px solid black",
        marginTop: "1em",
      }}
    >
      <div
        style={{
          position: "absolute",
          fontSize: "14pt",
          left: "50%",
          right: "50%",
          top: "0px",
          backgroundColor: "white",
          transform: "translate(-50%, -50%)",
          width: "240px",
        }}
      >
        <p style={{ fontWeight: "bold", letterSpacing: "4px" }}>{type}</p>
      </div>
    </div>
  )
}

InvoiceTypography.propTypes = {
  type: t.string,
}

InvoiceTypography.defaultProps = {
  type: "INVOICE",
}

export default InvoiceTypography
