// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let baseRootApi = ""

export default {
  production: false,
  rootApi: configureRootAPI(),
  baseRootApi: configureBaseRootAPI(),
  loginDisable: false,
}

function configureRootAPI() {
  if (process.env.NODE_ENV === "development" || "production") {
    // eslint-disable-next-line no-restricted-globals
    // baseRootApi = `${location.protocol}//${location.hostname}:5000/`
    baseRootApi = `${process.env.REACT_APP_KKMI_API_URL}/`
  }
  return `${baseRootApi}/apigraph`
}

function configureBaseRootAPI() {
  if (process.env.NODE_ENV === "development" || "production") {
    // eslint-disable-next-line no-restricted-globals
    // baseRootApi = `${location.protocol}//${location.hostname}:5000/`
    baseRootApi = `${process.env.REACT_APP_KKMI_API_URL}/`
  }
  return `${baseRootApi}`
}

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
