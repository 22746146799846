/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableFooter,
} from "@material-ui/core"

import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { transformProperty } from "utils/objects"
import InvoiceFormContext from "./InvoiceFormContex"
import ProductDNRow from "./ProductDNRow"
import ProductDNTableAddForm from "./ProductDNTableAddForm"

const addIdToEstimations = (products) => {
  return products.map((item, index) => ({
    ...item,
    __id: index,
  }))
}

const ProductDNTable = forwardRef(({ initialValue: initialFormData }, ref) => {
  const [initialValue] = useState(() =>
    addIdToEstimations(initialFormData?.productDescriptions ?? [])
  )

  const lastItem = initialValue[initialValue.length - 1]
  const lastIdRef = useRef(lastItem?.__id ?? 0)
  const { register, control, errors, getValues, setValue, watch } = useForm({
    defaultValues: { productDescriptions: initialValue },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "productDescriptions",
    keyName: "__id",
  })

  const { productDescriptions, setProductDescriptions } = useContext(
    InvoiceFormContext
  )

  React.useEffect(() => {
    setProductDescriptions(initialFormData?.productDescriptions ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = (index, item) => {
    const productDescriptionsCopy = [...productDescriptions]
    productDescriptionsCopy[index] = {
      ...productDescriptionsCopy[index],
      ...item,
    }

    setProductDescriptions(productDescriptionsCopy)
  }

  const onAddButtonClick = (newItemData) => {
    append({
      // eslint-disable-next-line no-plusplus
      __id: ++lastIdRef.current,
      ...newItemData,
    })
    setProductDescriptions((val) => [...val, newItemData])
  }

  const handleDeleteItem = (index) => {
    const dataCopy = [...productDescriptions]
    dataCopy.splice(index, 1)
    setProductDescriptions(dataCopy)
    remove(index)
  }

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      const data = getValues()
      const dataCopy = (data?.productDescriptions ?? []).map((product) => {
        transformProperty(product, ["unitPrice", "quantity"], parseFloat)

        // eslint-disable-next-line no-param-reassign
        if (!product.quantity) delete product.quantity
        return product
      })
      return { productDescriptions: dataCopy }
    },
  }))

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>UoM</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Sequence</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <p style={{ textAlign: "center" }}>No Data</p>
                </TableCell>
              </TableRow>
            ) : (
              (fields ?? []).map((product, index) => {
                return (
                  <ProductDNRow
                    key={product.__id}
                    product={product}
                    register={register}
                    errors={errors}
                    index={index}
                    onConfirmDelete={handleDeleteItem}
                    onChange={onChange}
                    setValue={setValue}
                    watch={watch}
                  />
                )
              })
            )}
          </TableBody>
          <TableFooter>
            <ProductDNTableAddForm onAddButtonClick={onAddButtonClick} />
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
})

export default ProductDNTable
