/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useState } from "react"
import * as t from "prop-types"
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { formatDate } from "utils/form-data"
import { Edit, MoreVert, Print } from "@material-ui/icons"
import ItemDisplay from "components/common/ItemDisplay"
import MUIDataTable from "mui-datatables"
import InvoiceItemPrint from "components/invoice/InvoiceItemPrint"
import { useReactToPrint } from "react-to-print"
import { uniqBy } from "lodash"
import useShowSnackbar from "utils/snackbar-hooks"
import ApiInvoice from "services/api-invoice"
import ChangeStatusMenu from "components/common/ChangeStatusMenu"
import { sendAndHandleInvalidToken } from "utils/invalid-token"
import { numberFormat } from "utils/thousand-separator"
import {
  currencyOperation,
  generateProductDescriptions,
  generateTotalAmountPrint,
} from "./utils"
import InvoiceMergeForm from "./InvoiceMergeForm"
import AdditionalCostDisplay from "./AdditionalCostDisplay"
import InvoicePrintSettingDialog from "./InvoicePrintSettingDialog"
import EnableReviseToggle from "./EnableReviseToggle"
import { useStyles } from "./const"

function InvoiceItemDisplay({
  showEdit,
  onConfirmEdit,
  data,
  companyInformation,
  onConfirmChangeStatus,
  id,
  setFlagRefetch,
  canUpdatePaymentStatus,
  onUpdatePaymentStatus,
  canUpdateEnableReviseSQ,
  onEnableReviseSQChange = new Promise((res) => res(true)),
}) {
  const classes = useStyles()
  const [showSnackbar, showSnackbarLoading, hideSnackbar] = useShowSnackbar()
  const [modalOpen, setModalOpen] = useState(false)

  const [printSetting, setPrintSetting] = useState({})

  const [anchorEl] = useState(null)

  const [selections, setSelections] = useState([])

  const descriptions = React.useMemo(() => {
    return data.productDescriptions || []
  }, [data])

  const open = Boolean(anchorEl)

  const generalFields = [
    {
      attr: "invoiceNumber",
      label: "Invoice Number",
      display: (val) => {
        const newVal = val.split("-")
        newVal.splice(-1, 1)
        return newVal.join("-").replace("-DELETED", "")
      },
    },

    {
      attr: "date",
      label: "Invoice Date",
      display: (date) => formatDate(date),
    },
    {
      attr: "payment",
      label: "Payment",
      display: (payment) => payment ?? "-",
    },
    {
      attr: "paymentPercentage",
      label: "%",
      display: (paymentPercentage) => paymentPercentage ?? "-",
    },
    {
      attr: "authorizedSignature",
      label: "Signed By",
      display: (authorizedSignature) => authorizedSignature?.name ?? "-",
    },
    { attr: "remarks", label: "Remarks" },
    {
      attr: "notes",
      label: "Notes",
      display: (val) => <span style={{ whiteSpace: "pre-line" }}>{val}</span>,
    },
    { attr: "paymentTerms", label: "Payment Terms" },
    {
      attr: "paidDate",
      label: "Paid Date",
      display: (val) => (val ? formatDate(val) : "-"),
    },
  ]

  const customerFields = [
    {
      attr: "customer",
      label: "Customer",
      display: (customer) => customer?.name ?? "-",
    },
    { attr: "address", label: "Address" },
    { attr: "attn", label: "Attn" },
    {
      attr: "currency",
      label: "Invoice Currency",
      display: (currency) => `${currency?.description} (${currency?.code})`,
    },
    {
      attr: "currencyRate",
      label: "Currency Rate",
    },
  ]

  const itemDisplayProps = {
    data,
    showEditButton: false,
    showDeleteButton: false,
  }

  const invNumber = data.invoiceNumber.split("-")
  invNumber.splice(-1, 1)

  const title = `Invoice of “${invNumber.join("-").replace("-DELETED", "")}”`

  const tableNumberColumn = {
    name: "no",
    label: "No",
    options: {
      setCellHeaderProps: () => ({
        style: {
          width: "1px",
        },
      }),
      customBodyRenderLite: (index) => index + 1,
    },
  }

  const tableOptions = {
    elevation: 0,
    download: false,
    print: false,
    selectToolbarPlacement: "none",
    responsive: "standard",
    selectableRows: "multiple",
    isRowSelectable: (dataIndex) => {
      const item = data.productDescriptions[dataIndex]
      return !item?.invoiceDetailMergeId
    },
    onTableChange: (...args) => {
      const [action, tableState] = args
      if (action === "rowSelectionChange") {
        const indexes = tableState.selectedRows.data.map(
          (item) => item.dataIndex
        )
        setTimeout(() => {
          setSelections(indexes.map((idx) => descriptions[idx].idInvoiceDetail))
        }, 1000)
      }
    },
  }

  const standardColumn = [tableNumberColumn]

  const columnDebitNoteOptions = useMemo(() => {
    return [
      ...standardColumn,
      {
        name: "description",
        label: "Description",
        options: {
          customBodyRender: (description) => {
            return <span style={{ whiteSpace: "pre-line" }}>{description}</span>
          },
        },
      },
      { name: "quantity", label: "Quantity" },
      { name: "unit", label: "Unit" },
      {
        name: "unitPrice",
        label: "Unit Price",
        options: {
          customBodyRender: (unitPrice) => {
            return `${new Intl.NumberFormat().format(unitPrice)}`
          },
        },
      },
      {
        name: "totalCost",
        label: "Total Cost",
        options: {
          customBodyRenderLite: (index) => {
            const item = data.productDescriptions[index]

            const unitPrice = currencyOperation(
              item.unitPrice,
              data.rateIn,
              data.currencyRate
            )
            const percentage = item.paymentPercentage ?? data.paymentPercentage
            if (!percentage)
              return `${new Intl.NumberFormat().format(
                unitPrice * (item.quantity || 1)
              )}`
            return `${new Intl.NumberFormat().format(
              unitPrice * (0 + percentage / 100)
            )}`
          },
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.currencyRate, data.paymentPercentage, data.productDescriptions])

  const dnNumberUnique = useMemo(() => {
    return Array.from(
      new Set(
        data.productDescriptions.map(
          (item) => item.productDetail?.deliveryNote?.dnNumber
        )
      )
    )
  }, [data.productDescriptions])

  const poNumberUnique = useMemo(() => {
    return uniqBy(
      data.productDescriptions
        .map((item) => {
          const source =
            item.productDetail?.salesEstimate || item?.slsEstimate || {}

          return {
            ...source.slsPurchaseOrder,
            slsQuotNumber: source?.slsQuot?.slsQuotNumber,
          }
        })
        .filter((item) => item),
      "poNumber"
    )
  }, [data.productDescriptions])

  const poDateUnique = useMemo(() => {
    return uniqBy(
      data.productDescriptions
        .map(
          (item) =>
            item.productDetail?.salesEstimate?.slsPurchaseOrder ??
            item?.slsEstimate?.slsPurchaseOrder
        )
        .filter((item) => item),
      "poDate"
    )
  }, [data.productDescriptions])

  const columnInvoiceOptions = useMemo(
    () => {
      const col = [
        ...standardColumn,
        {
          name: "dnNumber",
          label: "DN Number",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.productDescriptions[index]
              return item?.productDetail?.deliveryNote?.dnNumber ?? "-"
            },
          },
        },
        {
          name: "poNumber",
          label: "PO Number",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.productDescriptions[index]
              return (
                item?.productDetail?.salesEstimate?.slsPurchaseOrder
                  ?.poNumber ??
                item?.slsEstimate?.slsPurchaseOrder?.poNumber ??
                "-"
              )
            },
          },
        },
        {
          name: "refQuot",
          label: "Ref Quot",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.productDescriptions[index]
              return (
                item?.productDetail?.salesEstimate?.slsQuot?.slsQuotNumber ??
                item?.slsEstimate?.slsQuot?.slsQuotNumber ??
                "-"
              )
            },
          },
        },
        {
          name: "description",
          label: "Description",
          options: {
            customBodyRender: (val) => (
              <span style={{ whiteSpace: "pre-line" }}>{val}</span>
            ),
          },
        },

        {
          name: "productDetail",
          label: "Mould Code",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.productDescriptions[index]
              const { relatedWorkOrderEstimate } = item?.slsEstimate || {}
              const mouldCode =
                (relatedWorkOrderEstimate?.edges[0] || {}).node?.mouldCode ?? ""
              return item?.productDetail?.mouldCode ?? mouldCode
            },
          },
        },
        { name: "quantity", label: "Quantity" },
        { name: "unit", label: "Unit" },
        {
          name: "unitPrice",
          label: "Unit Price",
          options: {
            customBodyRender: (unitPrice) => {
              return numberFormat(
                currencyOperation(unitPrice, data.rateIn, data.currencyRate),
                0,
                ",",
                "."
              )
            },
          },
        },
        {
          name: "totalCost",
          label: "Total Cost",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.productDescriptions[index]

              const unitPrice = currencyOperation(
                item.unitPrice,
                data.rateIn,
                data.currencyRate
              )

              let result = unitPrice

              const percentage =
                item.paymentPercentage ?? data.paymentPercentage

              if (percentage) {
                result = unitPrice * (0 + percentage / 100)
              }

              return numberFormat(result, 0, ",", ".")
            },
          },
        },
      ]

      if (data.invoiceType === "INVOICE EXPORT") {
        col.push({
          name: "remark",
          label: "Remark",
          options: {
            customBodyRender: (val) => (
              <span style={{ whiteSpace: "pre-line" }}>{val}</span>
            ),
          },
        })
      }

      return col
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  const subTotal = useMemo(() => {
    const { paymentPercentage, productDescriptions } = data
    const amount = productDescriptions.reduce((a, b) => {
      let percentage = b.paymentPercentage
      if (!percentage) {
        percentage = paymentPercentage ?? 100
      }
      return (
        a +
        currencyOperation(+b.unitPrice, data.rateIn, data.currencyRate) *
          (0 + percentage / 100)
      )
    }, 0)

    const additionalCost = productDescriptions.reduce((a, b) => {
      return (
        a + b.additionalCosts.reduce((c, d) => c + d.unitPrice * d.quantity, 0)
      )
    }, 0)
    return Math.ceil(amount + additionalCost)
  }, [data])

  const totalCost = useMemo(() => {
    return Math.floor((subTotal - data.discount) * (1 + (data?.vat / 100 ?? 1)))
  }, [data, subTotal])

  const componentRef = React.useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const isDebitNote = useMemo(() => data.invoiceType === "DEBIT NOTE", [
    data.invoiceType,
  ])

  const projectNameRows = useMemo(() => {
    const arr = []
    data.productDescriptions.forEach((pd) => {
      const relatedWorkOrder =
        pd.productDetail?.salesEstimate?.relatedWorkOrderEstimate?.edges ||
        pd.slsEstimate?.relatedWorkOrderEstimate?.edges ||
        []
      const currentProject = Array.from(
        new Set(
          (relatedWorkOrder || []).map(
            (edge) => edge?.node?.workOrder?.projectName
          )
        )
      ).join(", ")
      arr.push(!arr.includes(currentProject) ? currentProject : "")
    })
    return arr
  }, [data.productDescriptions])

  const productDescriptionList = useMemo(() => {
    if (!data.productDescriptions) return []
    return data.productDescriptions.map((product) => {
      const unitPrice =
        data.invoiceType === "DEBIT NOTE"
          ? product.unitPrice
          : product.unitPrice / product.quantity
      return {
        ...product,
        unitPrice: Math.ceil(unitPrice),
      }
    })
  }, [data.productDescriptions, data.invoiceType])

  const productDescriptions = useMemo(() => {
    if ((data?.mergedDetails || []).length) {
      return data.mergedDetails.map((product) => {
        const obj = generateProductDescriptions({
          data,
          arr: [
            {
              ...product,
              unitPrice: Math.ceil(
                product.details.reduce((a, b) => {
                  return (
                    a +
                    currencyOperation(
                      b.unitPrice,
                      data.rateIn,
                      data.currencyRate
                    )
                  )
                }, 0)
              ),
            },
          ],
        })
        obj[0].totalForCalculation = product.details.reduce((a, b) => {
          const amount = currencyOperation(
            b.unitPrice,
            data.rateIn,
            data.currencyRate
          )
          return (
            a + amount * ((b.paymentPercentage ?? data.paymentPercentage) / 100)
          )
        }, 0)
        obj[0].total = generateTotalAmountPrint({
          data,
          total: obj[0].totalForCalculation,
        })
        return obj[0]
      })
    }
    return generateProductDescriptions({
      data,
      arr: data.productDescriptions.map((product, index) => {
        const { relatedWorkOrderEstimate } = product?.slsEstimate || {}
        const mouldCode =
          (relatedWorkOrderEstimate?.edges[0] || {}).node?.mouldCode ?? "-"

        return {
          ...product,
          mouldCode: product?.productDetail?.mouldCode ?? mouldCode,
          projectName: projectNameRows[index],
        }
      }),
      isDebitNote,
      mergeAdditionalCost: printSetting.mergeAdditionalCost,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    isDebitNote,
    productDescriptionList,
    projectNameRows,
    printSetting.mergeAdditionalCost,
  ])

  const anchorElRef = useRef(null)
  const [openStatusChangeMenu, setOpenStatusChangeMenu] = useState(false)

  const handleMergeInvoice = async (val) => {
    let fetchState = {}
    try {
      showSnackbarLoading("Loading...")

      if (selections.length) {
        fetchState = await sendAndHandleInvalidToken(() =>
          ApiInvoice.mergeInvoiceDetail({
            ...val,
            invoiceDetailId: selections.map((s) => parseInt(s, 10)),
            invoiceId: parseInt(id, 10),
          })
        )
      } else {
        fetchState = await sendAndHandleInvalidToken(() =>
          ApiInvoice.updateMergeInvoiceDetail({
            ...val,
            invoiceDetailId: selections.map((s) => parseInt(s, 10)),
            invoiceId: parseInt(id, 10),
          })
        )
      }
      if (fetchState.success) {
        showSnackbar("Invoice detail merged successfully!")
        setFlagRefetch()
      } else {
        hideSnackbar()
      }
    } catch (error) {
      showSnackbar(error?.errors[0]?.message || "Something went wrong")
    }
  }

  const handleDeleteMergeInvoice = async (invoiceDetailMergeId) => {
    let fetchState = {}
    try {
      showSnackbarLoading("Loading...")

      fetchState = await sendAndHandleInvalidToken(() =>
        ApiInvoice.deleteMergeInvoiceDetail(parseInt(invoiceDetailMergeId, 10))
      )
      if (fetchState.success) {
        showSnackbar("Delete susccess")
        setFlagRefetch()
      } else {
        hideSnackbar()
      }
    } catch (error) {
      showSnackbar("Something went wrong")
    }
  }

  const handlePrintSettingClose = (val) => {
    setPrintSetting(val)
    setModalOpen(false)
    setTimeout(() => {
      handlePrint()
    }, 1000)
  }

  return (
    <>
      <InvoiceItemPrint
        companyInformation={companyInformation}
        ref={componentRef}
        data={data}
        productDescription={productDescriptions}
        dnNumberUnique={dnNumberUnique}
        poDateUnique={poDateUnique}
        poNumberUnique={poNumberUnique}
        {...printSetting}
      />
      <InvoicePrintSettingDialog
        open={modalOpen}
        onClose={handlePrintSettingClose}
      />

      <div className={classes.headerContainer}>
        <div className={classes.headerTitleContainer}>
          <Typography variant="h6" className={classes.headerTitle}>
            {title}
          </Typography>
        </div>

        {showEdit &&
          data.paymentStatus === "unpaid" &&
          data.status !== "cancel" && (
            <Tooltip title="Edit">
              <IconButton
                variant="contained"
                color="primary"
                onClick={onConfirmEdit}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        <Tooltip title="Print">
          <IconButton
            id="print-icon"
            aria-expanded={open ? "true" : undefined}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={() => setModalOpen(true)}
          >
            <Print fontSize="small" />
          </IconButton>
        </Tooltip>

        {data.status !== "cancel" ? (
          <>
            <Tooltip title="Set Status">
              <IconButton
                color="primary"
                ref={anchorElRef}
                onClick={() => setOpenStatusChangeMenu(true)}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
            <ChangeStatusMenu
              open={openStatusChangeMenu}
              itemType="Invoice"
              anchorEl={anchorElRef.current}
              showCancel
              onClose={() => setOpenStatusChangeMenu(false)}
              onConfirmStatusChange={onConfirmChangeStatus}
            />
          </>
        ) : null}
      </div>
      {canUpdatePaymentStatus && data?.paymentStatus !== "paid" ? (
        <Button
          color="primary"
          variant="contained"
          onClick={onUpdatePaymentStatus}
        >
          Mark as Paid
        </Button>
      ) : null}
      <Grid container>
        <Grid item xs={12} sm={6}>
          <ItemDisplay
            {...itemDisplayProps}
            attrsAndLabels={generalFields}
            title="General Data"
          />

          {canUpdateEnableReviseSQ ? (
            <EnableReviseToggle
              defaultValue={data.enableRevise}
              onEnableReviseSQChange={onEnableReviseSQChange}
            />
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6}>
          <ItemDisplay
            {...itemDisplayProps}
            attrsAndLabels={customerFields}
            title="Customer Data"
          />
        </Grid>
      </Grid>

      <div className={classes.tableWrapper}>
        <MUIDataTable
          title={
            <div className={classes.tableTitle}>
              <strong>Description</strong>
            </div>
          }
          data={productDescriptionList}
          columns={
            data.invoiceType !== "DEBIT NOTE"
              ? columnInvoiceOptions
              : columnDebitNoteOptions
          }
          options={tableOptions}
        />
      </div>

      <div className={classes.tableWrapper}>
        <AdditionalCostDisplay productDesctiptions={data.productDescriptions} />
      </div>

      <table className={classes.sectionTotalTable}>
        <tbody>
          <tr>
            <th>Subtotal</th>
            <th>{new Intl.NumberFormat().format(Math.ceil(subTotal))}</th>
          </tr>
          <tr>
            <th>Discount</th>
            <th>{new Intl.NumberFormat().format(data.discount ?? 0)}</th>
          </tr>
          <tr>
            <th>VAT ({data.vat ?? 0}%)</th>
            <th>
              {new Intl.NumberFormat().format(
                Math.floor(
                  (subTotal - data.discount) * (0 + data?.vat / 100 ?? 0)
                )
              )}
            </th>
          </tr>
          <tr>
            <th>Total</th>
            <th>{new Intl.NumberFormat().format(totalCost)}</th>
          </tr>
        </tbody>
      </table>
      {showEdit && (
        <InvoiceMergeForm
          disabled={!selections.length}
          onSubmit={handleMergeInvoice}
          defaultValue={data.mergedDetails}
          handleDeleteMergeInvoice={handleDeleteMergeInvoice}
        />
      )}
    </>
  )
}

InvoiceItemDisplay.propTypes = {
  showEdit: t.bool.isRequired,
  onConfirmEdit: t.func.isRequired,
  data: t.shape({
    invoiceNumber: t.string.isRequired,
    invoiceType: t.string.isRequired,
    paymentPercentage: t.number,
    date: t.string.isRequired,
    customer: t.shape({
      name: t.string.isRequired,
    }),
    deliveryNote: t.shape({
      dnNumber: t.string,
      poNumber: t.string,
      poDate: t.string,
      salesQuotationNumber: t.shape({
        slsQuotNumber: t.string,
      }),
    }),
    currency: t.shape({
      code: t.string,
      description: t.string,
    }),
    currencyRate: t.number,
    productDescriptions: t.arrayOf(
      t.shape({
        description: t.string,
        unitPrice: t.number,
        productDetail: t.shape({
          mouldCode: t.string,
        }),
      })
    ),
    discount: t.number,
    vat: t.number,
  }).isRequired,
  companyInformation: t.shape({
    companyName: t.string,
    phone: t.string,
    email: t.string,
    address: t.string,
  }).isRequired,
}

export default InvoiceItemDisplay
