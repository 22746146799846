/* eslint-disable react/prop-types */
import React, { forwardRef } from "react"
import * as t from "prop-types"
import "../../assets/print/work-order-report.css"
import { formatDate } from "utils/form-data"

const WOReportPrint = forwardRef(({ workOrderType, workOrders = [] }, ref) => {
  const arr = []
  let no = 1
  workOrders.forEach((wo) => {
    wo.estimateList.forEach((estList, i) => {
      const obj = {}
      obj.no = i === 0 ? no : ""
      obj.customerName = i !== 0 ? "" : wo.customer.name
      obj.mouldCode = estList.mouldCode
      obj.productName = estList.salesEstimate?.estQuot?.rfq?.productName ?? "-"
      obj.description = estList?.remark
      obj.status = wo.workOrderType?.workOrderTypeName
      obj.woNumber = wo.woNumber
      obj.woDate = formatDate(wo.woDate)
      obj.poNumber = estList.salesEstimate?.slsQuot?.poNumber
      obj.slsQuot = estList?.salesEstimate?.slsQuot?.slsQuotNumber
      obj.estQuot = estList.salesEstimate?.estQuot?.estimationNumber
      arr.push(obj)
    })
    no += 1
  })

  return (
    <>
      <style type="text/css" media="print">
        {"\
            @page { size: landscape; }\
        "}
      </style>
      <div ref={ref} className="print-container">
        <table className="table table-bordered" id="table-list">
          <thead>
            <tr>
              <th style={{ backgroundColor: "yellow" }}>No</th>
              <th style={{ backgroundColor: "yellow" }}>Customer</th>
              <th style={{ backgroundColor: "yellow" }}>Est Quot</th>
              <th style={{ backgroundColor: "yellow" }}>Sls Quot</th>
              <th style={{ backgroundColor: "yellow" }}>PO Number</th>
              <th style={{ backgroundColor: "yellow" }}>Code Product</th>
              <th style={{ backgroundColor: "yellow" }}>Name Product</th>
              <th style={{ backgroundColor: "yellow" }}>Description</th>
              <th style={{ backgroundColor: "yellow" }}>Status</th>
              <th style={{ backgroundColor: "yellow" }}>No. WO</th>
              <th style={{ backgroundColor: "yellow" }}>Year</th>
            </tr>
            <tr>
              <th
                style={{ backgroundColor: "#CBF1F5", textAlign: "left" }}
                colSpan={11}
              >
                {workOrderType?.workOrderTypeName ?? "All"}
              </th>
            </tr>
          </thead>
          <tbody>
            {arr.map((wo) => (
              <tr key={wo.idWorkOrder}>
                <td>{wo.no}</td>
                <td>{wo.customerName}</td>
                <td>{wo.estQuot}</td>
                <td>{wo.slsQuot}</td>
                <td>{wo.poNumber}</td>
                <td>{wo.mouldCode}</td>
                <td>{wo.productName}</td>
                <td>{wo.description}</td>
                <td>{wo.status}</td>
                <td>{wo.woNumber}</td>
                <td>{formatDate(wo.woDate, true)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
})
WOReportPrint.propTypes = {
  workOrderType: t.shape({
    workOrderTypeName: t.string,
  }),
}

WOReportPrint.defaultProps = {
  workOrderType: {
    workOrderTypeName: "All",
  },
}
export default WOReportPrint
