import React, { useEffect, useMemo, useState } from "react"
import PERMISSION_CODES from "constants/permission-codes"
import { Route, Switch, useHistory } from "react-router-dom"
import { useBasePageStyles } from "constants/basePageStyles"
import useCheckPermission from "utils/auth/useCheckPermission"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Typography,
} from "@material-ui/core"
import NotAllowedNotice from "components/NotAllowedNotice"
import { ROUTE_INVOICE_ROOT } from "constants/routeConstants"
import RouteBreadcrumb from "components/page-layout/breadcrumbs/RouteBreadcrumb"
import { useWrapHandleInvalidToken } from "utils/invalid-token"
import ApiInvoice from "services/api-invoice"
import { ExpandMore } from "@material-ui/icons"
import InvoiceListAccordionItem from "components/invoice/InvoiceListAccordionItem"
import InvoiceCreatePage from "./InvoiceCreatePage"
import InvoiceItemPage from "./InvoiceItemPage"

const PAGE_PERMISSIONS = [PERMISSION_CODES.READ_INVOICE]

function InvoiceListPage() {
  const history = useHistory()
  const classes = useBasePageStyles()

  const [years, setYears] = useState([])
  const [loading, setLoading] = useState(true)
  const [expanded, setExpanded] = useState(0)

  const { checkWithoutUpdateState } = useCheckPermission()

  const canCreateItem = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.WRITE_INVOICE],
      }),
    [checkWithoutUpdateState]
  )

  const allowed = useMemo(
    () => checkWithoutUpdateState({ permissions: PAGE_PERMISSIONS }),
    [checkWithoutUpdateState]
  )

  const initiateCreateItem = () => {
    history.push(`${ROUTE_INVOICE_ROOT}/create`)
  }

  const wrappedFetchItems = useWrapHandleInvalidToken(ApiInvoice.get, () => [])

  useEffect(() => {
    let active = true

    ;(async () => {
      const res = await ApiInvoice.getInvoiceYear()
      if (!active) return
      setYears(res.data)
      setLoading(false)
    })()

    return () => {
      active = false
    }
  }, [])

  if (loading) return <p>Loading...</p>

  if (!allowed) {
    return (
      <Paper style={{ maxWidth: "800px" }}>
        <NotAllowedNotice />
      </Paper>
    )
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Paper>
      <Box p={2}>
        <Switch>
          <Route exact path={ROUTE_INVOICE_ROOT}>
            <div style={{ marginTop: 12 }}>
              {years.map((y, i) => {
                return (
                  <Accordion
                    key={y}
                    expanded={expanded === i}
                    onChange={handleChange(i)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`panel${i}-content`}
                      id={`panel${i}-header`}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {y}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InvoiceListAccordionItem
                        year={y}
                        fetchInvoiceData={wrappedFetchItems}
                      />
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </div>
            {canCreateItem && (
              <div>
                <Button
                  className={classes.createButton}
                  variant="contained"
                  color="primary"
                  onClick={initiateCreateItem}
                >
                  Create Invoice
                </Button>
              </div>
            )}
          </Route>
          <RouteBreadcrumb
            name="Create Invoice"
            path={`${ROUTE_INVOICE_ROOT}/create`}
            component={InvoiceCreatePage}
          />
          <RouteBreadcrumb
            name="Invoice Detail"
            path={`${ROUTE_INVOICE_ROOT}/:id`}
            component={InvoiceItemPage}
          />
        </Switch>
      </Box>
    </Paper>
  )
}

export default InvoiceListPage
