import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import Modal from "@material-ui/core/Modal"
import Fade from "@material-ui/core/Fade"

const useStyles = makeStyles({
  obstructor: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
    background: [
      "rgb(109,165,208); background: linear-gradient(339deg, rgba(167,211,228,1) 0%, rgba(193,231,232,1) 42%, rgba(245,245,245,1) 100%)",
    ],
    "&:focus": {
      outline: "none",
    },
  },
  h6: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  loading: {
    display: "inline-block",
    position: "relative",
    width: "80px",
    height: "60px",
    "& div": {
      position: "absolute",
      top: "33px",
      width: "0",
      height: "0",
      borderRadius: "50%",
      background: "#033278",
      border: "8px solid #033278",
      animationTimingFunction: "cubic-bezier(0, 1, 1, 0)",
      "&:nth-child(1)": {
        left: "0px",
        animation: "$lds-ellipsis1 0.6s infinite",
      },
      "&:nth-child(2)": {
        left: "0px",
        animation: "$lds-ellipsis2 0.6s infinite",
      },
      "&:nth-child(3)": {
        left: "30px",
        animation: "$lds-ellipsis2 0.6s infinite",
      },
      "&:nth-child(4)": {
        left: "60px",
        animation: "$lds-ellipsis3 0.6s infinite",
      },
    },
  },
  "@keyframes lds-ellipsis1": {
    "0%": { transform: "scale(0)" },
    "100%": { transform: "scale(1)" },
  },
  "@keyframes lds-ellipsis3": {
    "0%": { transform: "scale(1)" },
    "100%": { transform: "scale(0)" },
  },
  "@keyframes lds-ellipsis2": {
    "0%": { transform: "translate(0, 0)" },
    "100%": { transform: "translate(30px, 0)" },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
})

/* Useful to hide page when app is checking token */

const AppContentObstructor = ({ open, fadeOut, fadeIn, loadingText }) => {
  const classes = useStyles()

  const loadingElement =
    typeof loadingText === "string" ? (
      <Typography id="loading-text" className={classes.h6} variant="h6">
        {loadingText}
      </Typography>
    ) : (
      loadingText
    )

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent="div"
    >
      <Fade
        in={open}
        timeout={{
          enter: 300 * fadeIn,
          exit: 300 * fadeOut,
        }}
      >
        <div className={classes.obstructor}>
          <div className={classes.loading}>
            <div />
            <div />
            <div />
            <div />
          </div>
          {loadingElement}
        </div>
      </Fade>
    </Modal>
  )
}

AppContentObstructor.propTypes = {
  open: PropTypes.bool,
  fadeOut: PropTypes.bool,
  fadeIn: PropTypes.bool,
  loadingText: PropTypes.node,
}

AppContentObstructor.defaultProps = {
  open: true,
  fadeOut: true,
  fadeIn: false,
  loadingText: "Loading...",
}

export default AppContentObstructor
