import { useReducer } from "react"

export const ADD_INVOICE_DETAIL = "ADD_INVOICE_DETAIL"
export const REMOVE_INVOICE_DETAIL = "REMOVE_INVOICE_DETAIL"
export const ADD_INVOICE_ADDITIONAL_COST = "ADD_INVOICE_ADDITIONAL_COST"
export const REMOVE_INVOICE_ADDITIONAL_COST = "REMOVE_INVOICE_ADDITIONAL_COST"
export const EMPTY_DATA = "EMPTY_DATA"

// eslint-disable-next-line consistent-return
function reducer(state, action) {
  switch (action.type) {
    case ADD_INVOICE_DETAIL: {
      const { key } = action.payload
      const newState = [...state]
      const isExists = newState.filter((ns) => ns.key === key).length

      if (isExists) return newState
      newState.push({ key, additionalCosts: [] })
      return newState
    }
    case REMOVE_INVOICE_DETAIL: {
      const { key } = action.payload
      const newState = [...state]
      const index = newState.findIndex((ns) => ns.key === key)
      newState.splice(index, 1)
      return newState
    }
    case ADD_INVOICE_ADDITIONAL_COST: {
      const {
        key,
        poNumber,
        description,
        quantity,
        unit,
        unitPrice,
        totalAmount,
        remark,
      } = action.payload

      const index = state.findIndex((item) => item.key === key)

      const newState = [...state]
      newState[index].additionalCosts = [
        ...newState[index].additionalCosts,
        {
          poNumber,
          description,
          quantity,
          unit,
          unitPrice,
          totalAmount,
          remark,
        },
      ]
      return newState
    }
    case REMOVE_INVOICE_ADDITIONAL_COST: {
      const { key, index } = action.payload
      const detailIndex = state.findIndex((item) => item.key === key)
      const newState = [...state]
      newState[detailIndex].additionalCosts.splice(index, 1)
      return newState
    }
    case EMPTY_DATA: {
      const newState = [...state]
      newState.splice(0, newState.length)
      return newState
    }

    default:
      break
  }
}

export function useInvoiceAdditionalCostReducer(defaultValue = []) {
  const [state, dispatch] = useReducer(reducer, defaultValue)

  return { state, dispatch }
}
